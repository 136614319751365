import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth';
import organizationsReducer from './organizations';
import personalAreaReducer from './personalArea';
import statisticsReducer from './statistics';
import { Api } from '../../services/api-client';

const combinedReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    organizations: organizationsReducer,
    personalArea: personalAreaReducer,
    statistics: statisticsReducer,
    [Api.reducerPath]: Api.reducer
  });

export default combinedReducer;
