import React from 'react';
import { useSelector } from 'react-redux';
import { useOrganizationLimits } from '../../../hooks/useOrganizationLimits';
import { LinearProgress } from '@mui/material';

function WelcomeBanner() {
  const user = useSelector((state) => state?.auth?.user);
  const selectedOrganization = useSelector((state) => state?.organizations?.selected);
  const limits = useOrganizationLimits(user);
  const progressValue = limits?.maxLimit ? (limits?.reached / limits?.maxLimit) * 100 : 0;

  return (
    <div
      className="tw-relative tw-p-4 sm:tw-p-6 tw-overflow-hidden tw-text-white tw-h-full"
      style={{ borderRadius: '12px', zIndex: 1 }}
    >
      <div className="tw-absolute tw-inset-0">
        <img
          src="/images/background/welcome-01.png"
          alt=""
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <div className="tw-relative tw-flex tw-flex-col tw-z-20 ">
        <div className="tw-col-span-full md:tw-col-span-4 tw-mb-6">
          <span className="tw-text-lg md:tw-text-2xl lg:tw-text-[2rem] tw-font-bold"> Добро пожаловать! </span>{' '}
        </div>
        <p
          className="tw-col-span-full md:tw-col-span-6 tw-text-base tw-mb-4 text-decoration-none tw-leading-5"
        >
          {user?.full_name}
        </p>
        <p className="text-decoration-none tw-mb-4 tw-leading-5">
          {selectedOrganization?.name
            ||
          <p
            className="text-decoration-none">
            Организация не указана
          </p>}
        </p>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[8px] tw-flex-wrap">
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.45 11.5C8.85 11.9 9.36667 12.0958 10 12.0875C10.6333 12.0792 11.1 11.85 11.4 11.4L17 3L8.6 8.6C8.15 8.9 7.9125 9.35833 7.8875 9.975C7.8625 10.5917 8.05 11.1 8.45 11.5ZM10 0C10.9833 0 11.9292 0.1375 12.8375 0.4125C13.7458 0.6875 14.6 1.1 15.4 1.65L13.5 2.85C12.95 2.56667 12.3792 2.35417 11.7875 2.2125C11.1958 2.07083 10.6 2 10 2C7.78333 2 5.89583 2.77917 4.3375 4.3375C2.77917 5.89583 2 7.78333 2 10C2 10.7 2.09583 11.3917 2.2875 12.075C2.47917 12.7583 2.75 13.4 3.1 14H16.9C17.2833 13.3667 17.5625 12.7083 17.7375 12.025C17.9125 11.3417 18 10.6333 18 9.9C18 9.3 17.9292 8.71667 17.7875 8.15C17.6458 7.58333 17.4333 7.03333 17.15 6.5L18.35 4.6C18.85 5.38333 19.2458 6.21667 19.5375 7.1C19.8292 7.98333 19.9833 8.9 20 9.85C20.0167 10.8 19.9083 11.7083 19.675 12.575C19.4417 13.4417 19.1 14.2667 18.65 15.05C18.4667 15.35 18.2167 15.5833 17.9 15.75C17.5833 15.9167 17.25 16 16.9 16H3.1C2.75 16 2.41667 15.9167 2.1 15.75C1.78333 15.5833 1.53333 15.35 1.35 15.05C0.916667 14.3 0.583333 13.5042 0.35 12.6625C0.116667 11.8208 0 10.9333 0 10C0 8.61667 0.2625 7.32083 0.7875 6.1125C1.3125 4.90417 2.02917 3.84583 2.9375 2.9375C3.84583 2.02917 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0Z"
              fill="white" />
          </svg>

          Лимит запросов {limits?.reached} из {limits?.maxLimit}
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              width: '100%',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#0BD90B',
              },
              '&.MuiLinearProgress-root': {
                backgroundColor: '#DDDDDD',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WelcomeBanner;
