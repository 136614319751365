import GeneralLayout from '../../../components/general-layout';
import moment from 'moment/moment';
import { useEffect } from 'react';
import {
  useGetNotificationsForUsersQuery,
  useMarkNotificationsAsViewedMutation
} from '../../../services/adminAPIs';
import { Badge } from '@mui/material';
import { Pagination } from '../../../components/table/pagination';
import { usePaginationParams } from '../../../hooks/usePaginationParams';
import { Footer } from '../../../components/footer';


function ListNotificationsForUsers(){
  const { page_size, page_num, query, handleLimitChange, goToPage } = usePaginationParams();
  const [markAllAsViewed] = useMarkNotificationsAsViewedMutation();
  const { data } = useGetNotificationsForUsersQuery(
    {
      page_size,
      page_num,
      query,
    }
  );

  useEffect(() => {
    if (data?.data.length > 0) {
      markAllAsViewed();
    }
  }, [data, markAllAsViewed]);

  return(
    <GeneralLayout bg='white'>
      <div className="list-notifications-users">
        <p className="tw-font-semibold tw-text-[32px] tw-leading-[38px] tw-mb-8">Уведомления</p>
        <div className="tw-divide-y tw-bg-[#fff] tw-p-6 tw-rounded-lg tw-max-w-[741px]">
          {
            data?.data.length > 0 ? (
              data.data.map((notification) => (
                <div key={notification.id}>
                  <p className='tw-text-sm tw-text-[#191919] tw-font-normal tw-leading-[18px] tw-mb-1 tw-mt-4 '>
                    <Badge
                      variant='dot' sx={{
                      '& .MuiBadge-dot': {
                        backgroundColor: notification.viewed ? '#999999' : '#C12026'
                      },
                      margin: '8px'
                    }} />
                    {notification.text}</p>
                  <p className='tw-text-xs tw-text-[#666] tw-font-normal tw-leading-[16px] tw-mb-3'>
                    {moment(notification.created_at).format('DD.MM.YYYY HH:mm')}
                  </p>
                </div>
              ))
            ) : (<p className='tw-text-base tw-text-[#191919] tw-mt-2'>Уведомления отсутствуют</p>)
          }
        </div>
        <div className="tw-max-w-[741px]">
          <Pagination
            rowsPerPage={page_size}
            count={data?.count}
            page={data?.page_num}
            onPageChange={goToPage}
            onLimitChange={handleLimitChange}
          />
        </div>
      </div>
      <div className="tw-mt-16">
        <Footer />
      </div>
    </GeneralLayout>
  )
}

export default ListNotificationsForUsers;