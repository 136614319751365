import { useParams, useHistory } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import { usePagination } from '../../components/table/usePagination';
import { feedbackAPIs } from '../../services/feedbackAPIs';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import Button from '../../components/button';
import Table from '../../components/table';
import { Pagination } from '../../components/table/pagination';
import { Checkbox } from '@mui/material';

const STATUSES = {
  PASSED: (
    <>
      <div className="tw-bg-[#EEFCEE] tw-rounded-[9px] tw-px-[10px] tw-py-[5px] tw-w-[93px]">
        <p className="tw-text-[#49CA4E] tw-text-xs tw-leading-4 tw-font-medium tw-text-center">Пройден</p>
      </div>
    </>
  ),
  NOT_PASSED: (
    <>
      <div className="tw-bg-[#FFEAEA] tw-rounded-[9px] tw-px-[10px] tw-py-[5px] tw-w-[93px]">
        <p className="tw-text-[#FF4343] tw-text-xs tw-leading-4 tw-font-medium tw-text-center ">Не пройден</p>
      </div>
    </>
  ),
};

export const DetailedPeriod = () => {
  const { id } = useParams();
  const history = useHistory();
  const [feedbacks, setFeedbacks] = useState([]);
  const [period, setPeriod] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isOnlyPassed, setIsOnlyPassed] = useState(false);

  const getDetailedPeriod = useCallback(
    async (limit, page_num, query) => {
      return await feedbackAPIs.getFeedbacksFromPeriod(id, query, limit, page_num);
    },
    [id]
  );

  const { data, handleChangeQuery, total, pageSize, currentPage, goToPage, setPageSize } =
    usePagination(getDetailedPeriod);

  const columns = {
    full_name: {
      label: 'Пользователь',
    },
    org: {
      label: 'Организация',
      renderBodyCell: (value) => {
        const organization = value.org.split(', ')[0];
        return (
          <>
            <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-truncate tw-max-w-[209px] tw-min-w-0 tw-block">
              {organization}
            </p>
          </>
        );
      },
    },
    email: {
      label: 'Email',
    },
    is_form_completed: {
      label: 'Статус',
      renderBodyCell: (value) => {
        return <>{value.feedback !== 'No feedback available' ? STATUSES.PASSED : STATUSES.NOT_PASSED}</>;
      },
    },
    feedback: {
      label: 'Обратная связь',
      renderBodyCell: (value) => {
        return (
          <>
            <Button
              variant="text"
              className="tw-w-fit  tw-text-sm tw-leading-[18px] tw-font-medium hover:tw-bg-transparent disabled:tw-text-[#83A1D9]"
              disabled={value.feedback === 'No feedback available'}
              onClick={() => history.push(`/feedback/${id}/${value.feedback.id}`)}
            >
              Открыть
            </Button>
          </>
        );
      },
    },
  };

  useEffect(() => {
    setFeedbacks(data);
  }, [data]);

  useEffect(() => {
    handleChangeQuery(`${searchText}${isOnlyPassed ? '&passed=true' : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyPassed, searchText]);

  useEffect(() => {
    setPeriod(sessionStorage.getItem('feedbackPeriod'));
  }, []);

  const handleChangeText = (value) => {
    setSearchText(value)
  }
  
  const handleChangeCheckbox = (e) => {
    setIsOnlyPassed(e.target.checked)
  }
  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-leading-[28px] sm:tw-leading-[38px] tw-text-2xl sm:tw-text-[32px] tw-font-semibold tw-mb-6 sm:tw-mb-8">
        {period}
      </h2>
      <div className="tw-bg-white tw-flex tw-flex-col tw-rounded-lg tw-pt-4 md:tw-pt-[25px] tw-px-2">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-mb-2 sm:tw-mb-4">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                className="tw-w-full tw-max-w-[312px] tw-pl-[14px] xs:tw-pl-5 md:tw-pl-6 "
                type="text"
                name="search"
                placeholder="Найти пользователя"
                onSearchChange={handleChangeText}
              />
            )}
          </Form>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
            <Checkbox disableRipple onChange={handleChangeCheckbox} checked={isOnlyPassed}/>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm">Только пройденные</p>
          </div>
        </div>
        <Table rows={feedbacks} columns={columns} />
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={setPageSize}
      />
    </GeneralLayout>
  );
};
