
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/auth-layout';
import Button from '../../components/button';

export const SuccessReset = () => {
  return (
    <AuthLayout>
      <div className="tw-flex tw-flex-col tw-items-start tw-h-full sm:tw-h-fit tw-w-full sm:tw-w-[370px]">
        <h1 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-mb-2 sm:tw-mb-3">
          Пароль изменен
        </h1>
        <p className="tw-text-[#666666] tw-leading-5 tw-font-medium">Войдите в систему с новым паролем</p>
        <Link to="/" className='tw-w-full'>
          <Button className="tw-rounded-lg tw-py-[14px] tw-px-[18px] tw-mt-auto sm:tw-mt-[246px] md:tw-mt-8">
            Войти
          </Button>
        </Link>
      </div>
    </AuthLayout>
  );
};
