import { useCallback } from 'react';
import Button from '../../../components/button';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { CrudTable } from '../../../components/table/CrudTable';
import { useGetStorageOrganizationsQuery } from '../../../services/adminAPIs';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { Pagination } from '../../../components/table/pagination';
import { useSortableData } from '../../../hooks/useSortableData';
import classNames from 'classnames';
import classes from '../users/usersStorage.module.sass';
import { useClearStorageData } from '../../../hooks/useClearStorageData';
import { Link } from 'react-router-dom';
import { CardOrganizationModal } from '../../personalArea/organizations/modals/CardOrganizationModal';
import { useDialog } from '../../../providers/dialog.provider';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { useRemoveProjectOrganizationDataMutation } from '../../../services';
import Loader from '../../../components/loader';
import { usePaginationParams } from '../../../hooks/usePaginationParams';

export default function StorageOrganizations() {
  const dialog = useDialog();
  const [open, handleCleanData] = useClearStorageData();
  const { page_size, page_num, query, handleLimitChange, handleQueryChange, goToPage } = usePaginationParams();
  const { requestSort, sortingLabel, sortConfig } = useSortableData([], null, true);
  const [removeProjectOrganizationData, {isLoading}] = useRemoveProjectOrganizationDataMutation();

  const { data: organizations, isLoading: loadingOrganizations} = useGetStorageOrganizationsQuery({
    page_size,
    page_num,
    query,
    sort_by_project_storage: sortConfig?.direction,
    sort_by_total_storage: sortConfig?.direction
  })

  const clearProjectScreenshots = async (entity, deleteAll = false) => {
      try {
        await removeProjectOrganizationData({
          organization_id: entity.organization.id,
          body: {
            delete_all: deleteAll,
            project_id: deleteAll? "" : entity.project_id,
          }
        });

        const message = deleteAll
          ? `Данные по всем проектам организации ${entity?.organization?.name} очищены`
          : `Данные скриншотов в проекте ${entity?.project_name} очищены`;
        createNotification(message);
      } catch (error) {
        createErrorNotification('Произошла ошибка при удалении данных');
      }
  }

  const handleCardOrganization = useCallback(
    (value) => {
      dialog.open(<CardOrganizationModal organizations={value} />);
    },[dialog]);

  const clearProjectData = useCallback(
    (entity, deleteAll) => {
      dialog.open(
        <ConfirmModal
          value={entity}
          title={deleteAll ? `Вы уверенны, что хотите очистить данные по всем проектам у организации ${entity?.organization?.name}?`  : `Вы уверенны, что хотите очистить данные по проекту ${entity?.project_name}?`}
          btnActionProp="Очистить"
          onAgree={() => clearProjectScreenshots(entity, deleteAll)}
        />
      );
    },
    [dialog]);


  const columns = {
    name: {
      label: 'Организация',
      renderBodyCell: (value) => {
        return (
          <>
            <Button
              variant="text"
              className="tw-w-fit tw-text-left tw-z-0 tw-text-sm tw-font-semibold tw-p-0 hover:tw-bg-inherit"
              onClick={() => handleCardOrganization(value?.organization)}
            >
              {value?.organization?.name}
            </Button>
          </>
        )
      },
    },
    project_name: {
      label: 'Проект',
      renderBodyCell: (value) => {
        return (
          <>
            {value?.project_name ? (
              <Link className="tw-text-[#0844B4] hover:tw-text-[#0A50D4]" to={`/projects/${value?.project_id}`}>
                {value?.project_name}
              </Link>
            ) : (
              <span className="tw-font-normal">
                Отсутствует
              </span>
            )}
          </>
        )
      },
    },
    project_storage: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('project_storage')}>
            {sortingLabel('Объем изображений', 'project_storage')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return (
          <>
            {value?.project_storage ? (
              <div
                className="tw-w-fit tw-whitespace-nowrap tw-z-0 tw-text-sm tw-font-medium tw-p-0 hover:tw-bg-inherit">
                {value?.project_storage} Мб
              </div>
            ) : (
              <span className="tw-font-normal">
                Отсутствует
              </span>
            )}
          </>
        )
      },
    },
    total_storage : {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('total_storage')}>
            {sortingLabel('Объем по всем проектам', 'total_storage')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return (
          <>
            {value?.total_storage ? (
              <div
                className="tw-w-fit tw-whitespace-nowrap tw-z-0 tw-text-sm tw-font-medium tw-p-0 hover:tw-bg-inherit">
                {value?.total_storage} Мб
              </div>
            ) : (
              <span className="tw-font-normal">
                Отсутствует
              </span>
            )}
          </>
        )
      },
    }
  };
  const crudButtons = (row) => {
    const isOpen = open[row.project_id];
    return (
      <div className="tw-flex tw-flex-row tw-relative tw-gap-6">
        {isLoading ? (
          <div>
            <Loader/>
          </div>)
          :
          (<>
            <Button variant="text" className="tw-w-fit tw-whitespace-nowrap tw-z-0 tw-text-sm tw-font-semibold tw-p-0 hover:tw-bg-inherit" onClick={() => handleCleanData(row.project_id)}>
              Очистить данные
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                  'tw-will-change-transform tw-transition-transform tw-duration-300 tw-ml-[3px]',
                  { 'tw-rotate-180': isOpen, 'tw-rotate-0': !isOpen }
                )}
              >
                <path d="M5.99883 6.57344C5.86549 6.57344 5.73616 6.54844 5.61083 6.49844C5.48549 6.44844 5.38149 6.38177 5.29883 6.29844L0.698827 1.69844C0.515494 1.5151 0.423828 1.28177 0.423828 0.998437C0.423828 0.715104 0.515494 0.48177 0.698828 0.298437C0.882161 0.115104 1.11549 0.0234376 1.39883 0.0234377C1.68216 0.0234377 1.91549 0.115104 2.09883 0.298437L5.99883 4.19844L9.89883 0.298439C10.0822 0.115105 10.3155 0.0234392 10.5988 0.0234393C10.8822 0.0234393 11.1155 0.115106 11.2988 0.298439C11.4822 0.481772 11.5738 0.715106 11.5738 0.998439C11.5738 1.28177 11.4822 1.51511 11.2988 1.69844L6.69883 6.29844C6.59883 6.39844 6.49049 6.46944 6.37383 6.51144C6.25716 6.55344 6.13216 6.57411 5.99883 6.57344Z" fill="#0844B4"/>
              </svg>
            </Button>
            <div
              className={classNames(
                classes.modalStorage,
                'tw-absolute tw-bg-white tw-rounded-[9px] tw-z-10 tw-flex-col tw-gap-[10px]',
                { 'tw-flex': isOpen, 'tw-hidden': !isOpen }
              )}>
              <Button
                variant="text"
                className="tw-w-fit tw-whitespace-nowrap tw-z-0 tw-text-sm tw-font-normal tw-p-0 tw-text-[#191919] hover:tw-bg-transparent hover:tw-text-[#0844B4]"
                onClick={() => clearProjectData(row, false)}
              >
                Очистить данные по проекту
              </Button>
              <Button
                variant="text"
                className="tw-w-fit tw-whitespace-nowrap tw-z-0 tw-text-sm tw-font-normal tw-p-0 tw-text-[#191919] hover:tw-bg-transparent hover:tw-text-[#0844B4]"
                onClick={() => clearProjectData(row, true)}
              >
                Очистить данные по всем проектам
              </Button>
            </div>
          </>)}

      </div>
    );
  };

  return (
    <>
      <div className="tw-flex tw-flex-col">
        <div className="tw-bg-white tw-py-[25px] tw-rounded-lg">
          <div className={`tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-flex-wrap tw-gap-[20px] `}>
            <Form onSubmit={() => {}}>
              {() => (
                <>
                  <SearchInput
                    name="search"
                    className="tw-max-w-[312px]"
                    onSearchChange={handleQueryChange}
                    placeholder="Найти организацию"
                    type="text"
                    required
                  />
                </>
              )}
            </Form>
          </div>
          {!loadingOrganizations ? (<CrudTable rows={organizations?.data} entityColumns={columns} crudButtons={crudButtons} />)
            : (
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
                <div
                  className="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                />
              </div>
            )}

          <div>
            <Pagination
              rowsPerPage={page_size}
              count={organizations?.count}
              page={organizations?.page_num}
              onPageChange={goToPage}
              onLimitChange={handleLimitChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
