import { useSelector } from 'react-redux';
import { organizationsAPIs } from '../../../services';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ReactComponent as RestoreIcon } from '../../../icons/restoreIcon.svg';
import Button from '../../button';
import { useCallback } from 'react';
// import { useDialog } from '../../../providers/dialog.provider';
// import { ConfirmModal } from '../../modal/ConfirmModal';

export const BlockedHostsItem = ({ item, onSuccess }) => {
  const organization = useSelector((state) => state.organizations.selected);
  // const dialog = useDialog()

  const handleDeleteSourceFromBlocked = useCallback(async () => {
    await organizationsAPIs
      .deleteSourceFromBlocked(organization?.id, {
        blocked_host: item,
      })
      .then(() => {
        createNotification('Источник удалён из списка заблокированных');
        onSuccess();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при удалении источника');
      });
  }, [item, onSuccess, organization?.id]);

  // const showConfirmationModal = useCallback(() => {
  //   dialog.open(<ConfirmModal title={`Вы уверены, что хотите восстановить источник ${item}?`} onAgree={handleDeleteSourceFromBlocked}/>)
  // }, [dialog, item, handleDeleteSourceFromBlocked])
  let url;
  try {
    url = new URL(item);
  } catch (err) {
    console.err(err);
  }
  return (
    <>
      <div className="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-py-4">
        <a
          href={url?.href ?? '#'}
          className="tw-text-[#0844B4] tw-font-medium tw-text-sm tw-leading-[18px]"
          target="_blank"
          rel="noreferrer"
        >
          {url?.host}
        </a>
        <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-start">
          <Button
            variant="text"
            onClick={handleDeleteSourceFromBlocked}
            className="tw-flex tw-w-fit tw-p-0 hover:tw-bg-transparent hover:tw-text-[#0A50D4] tw-transition-colors tw-gap-[2px] tw-flex-row tw-items-center tw-text-sm tw-leading-[18px] tw-font-medium"
          >
            <RestoreIcon />
            Восстановить источник
          </Button>
        </div>
      </div>
    </>
  );
};
