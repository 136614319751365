import * as constants from '../constants/personalArea';
import { AUTH__LOGOUT } from '../constants/auth';

const initialState = {
  providers: [],
  providersFilter: [],
  users: [],
  accesses: [],
  projectsListShow: false,
};

export default function userReducer(state = initialState, action) {

  console.log('personalArea', state);
  console.log('personalArea action', action);

  switch (action.type) {
    case constants.PERSONAL_AREA__GET_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
      };

    case constants.PERSONALAREA__FILTER_LIST:
      return {
        ...state,
        providersFilter: action.providers,
      };
    case constants.PERSONALAREA__FILTER_PROVIDERS:
      let providersNews = state.providersFilter;
      if (action.text === undefined) {
        return {
          ...state,
          providers: providersNews,
        };
      }
      providersNews = providersNews.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(action.text.toLowerCase()) > -1 ||
          item.email.toLowerCase().indexOf(action.text.toLowerCase()) > -1
        );
      });
      return {
        ...state,
        providers: providersNews,
      };

    case constants.PERSONAL_AREA__ADD_PROVIDER:
//      if(state.providers === undefined || state.providers === null)
//        state.providers = [];
      return {
        ...state,
        providers: [action.provider, ...state.providers],
      };
    case constants.PERSONAL_AREA__DELETE_PROVIDER:
      return {
        ...state,
        providers: state.providers.filter((u) => u.id !== action.id),
      };
    case constants.PERSONAL_AREA__GET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case constants.PERSONAL_AREA__ADD_USER:
      return {
        ...state,
        users: [action.user, ...state.users],
      };
    case constants.PERSONAL_AREA__UPDATE_USER:
      return {
        ...state,
        users: state.users.map((u) => {
          if (u.id === action.user.id) {
            return action.user;
          }
          return u;
        }),
      };
    case constants.PERSONAL_AREA__DELETE_USER:
      return {
        ...state,
        users: state.users.filter((u) => u.id !== action.id),
      };
    case constants.PERSONAL_AREA__GET_ACCESSES:
      return {
        ...state,
        accesses: action.accesses,
      };
    case constants.PERSONAL_AREA__ADD_ACCESS:
      return {
        ...state,
        accesses: [action.access, ...state.accesses],
      };
    case constants.PERSONAL_AREA__UPDATE_ACCESS:
      return {
        ...state,
        accesses: state.accesses.map((u) => {
          if (u.id === action.access.id) {
            return action.access;
          }
          return u;
        }),
      };
    case constants.PERSONAL_AREA__DELETE_ACCESS:
      return {
        ...state,
        accesses: state.accesses.filter((u) => u.id !== action.id),
      };
    case constants.PERSONAL_AREA__CLEAN_ACCESS:
      return {
        ...state,
        accesses: [],
      };
    case constants.PERSONAL_AREA__SHOW_PROVIDERS:
      return {
        ...state,
        projectsListShow: !state.projectsListShow,
      };
    case AUTH__LOGOUT:
      return initialState;
    default:
      return state;
  }
}
