import { Checkbox, Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Field, Form } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg';
import { ReactComponent as ExcelIcon } from '../../../icons/excel.svg';
import { EXCEL_MIME_TYPES } from '../../../utils/constant';
import classNames from 'classnames';
import { AddressSuggestions } from 'react-dadata';

export const ProjectsFormModal = ({ title, onSubmit, onReject, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  const [error, setError] = useState(false);

  const organizationId = useSelector((store) => store?.organizations?.selected?.id);

  useEffect(() => {
    if (JSON.stringify(formValue) !== JSON.stringify(value)) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name, address, file, use_gpt, postal_code } = payload;
      const formBody = new FormData();
      const data = {
        name: name,
        organization_id: organizationId,
        address: address ?? '',
        postal_code: postal_code ?? ''
      };
      if (file?.length > 0) {
        data.file = file[0];
        data.usegpt = use_gpt;
      }
      Object.entries(data).forEach(([key, value]) => {
        formBody.append(key, value);
      });
      setError(false);
      if (onSubmit) {
        try {
          if (!id) {
            await onSubmit(formBody);
          } else {
            await onSubmit(id, data);
          }
        } catch (err) {
          console.error(err);
          if (err?.data?.conflict_message) {
            const symbol_limit = err?.data?.conflict_message?.exceeded_products?.[0]?.symbols_limit
            createErrorNotification(`Введено более ${symbol_limit} символов. Необходимо сократить текст наименования товара и повторно загрузить файл`);
            return;
          }
          if (err.status === 409 || err?.statusCode === 409) {
            setError(true);
          } else {
            createErrorNotification('Ошибка при ' + (id ? 'редактировании' : 'создании') + ' проекта');
          }
        }
      }
    },
    [onSubmit, organizationId]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    setError(false);
    dialog.close();
  }, [onReject, dialog]);

  const validate = (values) => {
    if (values?.name?.length === 0) return;
    const errors = {};
    if (values?.name?.length < 5) {
      errors.name = 'Название должно содержать не менее 5 символов';
    }
    if (values?.file) {
      if (values.file?.length > 1) {
        errors.file = 'Можно выбрать только один файл';
        createErrorNotification('Можно выбрать только один файл');
      } else {
        const file = values.file[0];

        if (!EXCEL_MIME_TYPES.includes(file.type)) {
          errors.file = 'Допустимы только файлы .xls или .xlsx';
          createErrorNotification('Допустимы только файлы .xls или .xlsx');
        }
      }
    }
    return errors;
  };

  return (
    <Dialog open={dialog.visibility}>
      {value?.id ? (
        <Form
          validate={validate}
          validateOnBlur
          initialValues={formValue}
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, submitError, submitting, form, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-p-6 tw-rounded-xl">
                <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
                <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <Input form={form} placeholder="Название проекта" name="name" type="text" required />
                    {error && (
                      <p className="tw-text-[#FF4343] tw-leading-4 tw-text-xs tw-font-medium tw-pl-4">
                        Такое название проекта уже используется
                      </p>
                    )}
                  </div>
                </div>

                <DialogActions className="tw-gap-[18px]">
                  <Button
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
                    variant="text"
                    disabled={submitting}
                    onClick={handleFormReject}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={submitting && invalid && pristine}
                    type="submit"
                  >
                    Изменить
                  </Button>
                </DialogActions>
                {submitError && createErrorNotification(submitError)}
              </div>
            </form>
          )}
        ></Form>
      ) : (
        <Form
          validate={validate}
          initialValues={formValue}
          validateOnBlur
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-p-6 tw-rounded-xl">
                <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
                <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                  <div className="tw-flex tw-flex-col tw-gap-6">
                    <div className="tw-flex tw-flex-col tw-gap-1">
                      <Input form={form} placeholder="Название проекта*" name="name" type="text" required />
                      {error && (
                        <p className="tw-text-[#FF4343] tw-leading-4 tw-text-xs tw-font-medium tw-pl-4">
                          Такое название проекта уже используется
                        </p>
                      )}
                    </div>
                    <div className="tw-w-full">
                      <AddressSuggestions
                        token={process.env.REACT_APP_DADATA_API_KEY}
                        delay={1000}
                        defaultQuery={formValue?.address || ''}
                        onChange={(address) => {
                          form.change('address', address?.value);
                          form.change('postal_code', address?.data?.postal_code);
                        }}
                        count={5}
                        inputProps={{
                          className:
                            'tw-p-[14px] tw-w-full tw-rounded-lg tw-border-[#CCC] focus:tw-border-[#CCC] tw-border disabled:tw-text-[#CCCCCC] tw-text-sm',
                          placeholder: 'Введите адрес доставки',
                        }}
                      />
                    </div>
                    <Input form={form} name="postal_code" className="tw-hidden" />
                    <Field name="file">
                      {({ input }) => (
                        <Button
                          variant="text"
                          className={classNames(
                            'tw-w-fit tw-flex-row tw-items-center tw-gap-[2px] tw-p-0 hover:tw-bg-transparent',
                            {
                              'tw-flex': !values?.file,
                              'tw-hidden': values?.file,
                            }
                          )}
                        >
                          <input
                            type="file"
                            name="files"
                            id="uploadBtn"
                            hidden
                            accept=".xlsx"
                            onChange={(e) => {
                              input.onChange(e.target.files);
                            }}
                          />
                          <label for="uploadBtn" className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer">
                            <ExcelIcon className="tw-mr-1" />
                            <p className="tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px]">
                              Загрузить по шаблону
                            </p>
                          </label>
                        </Button>
                      )}
                    </Field>
                    {values?.file && (
                      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                        <p className="tw-text-sm tw-text-[#191919]">{values?.file[0]?.name}</p>{' '}
                        <CloseIcon
                          className="tw-w-4 tw-h-4 tw-cursor-pointer"
                          onClick={() => form.change('file', null)}
                        />
                      </div>
                    )}
                    <Field name="use_gpt" type="checkbox">
                      {({ input }) => (
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                          <Checkbox className="tw-p-0" {...input} />
                          <p className="tw-text-sm">Использовать оптимизатор названий</p>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <DialogActions className="tw-gap-[18px]">
                  <Button
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
                    variant="text"
                    disabled={submitting}
                    onClick={handleFormReject}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={submitting && invalid && pristine}
                    type="submit"
                  >
                    Добавить
                  </Button>
                </DialogActions>
                {submitError && createErrorNotification(submitError)}
              </div>
            </form>
          )}
        ></Form>
      )}
    </Dialog>
  );
};
