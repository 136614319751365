import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button';
import { useDialog } from '../../../../providers/dialog.provider';
import { HardReloadModal } from './modal';

export const HardReloadProject = () => {
  const [isAllowedAdmin, setIsAllowedAdmin] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dialog = useDialog();

  useEffect(() => {
    if (user) {
      setIsAllowedAdmin(user?.super_admin);
    }
  }, [user]);

  const showModal = useCallback(() => {
    dialog.open(<HardReloadModal />);
  }, [dialog]);

  if (!isAllowedAdmin) {
    return <></>;
  }

  return (
    <>
      <div className="tw-flex tw-flex-col tw-items-start tw-bg-white tw-w-full tw-rounded-lg tw-p-6 tw-gap-6">
        <p className="tw-text-[#191919] tw-leading-[22px] tw-font-semibold">Запустить полную перезагрузку проекта</p>
        <Button className="tw-w-fit tw-rounded-lg tw-text-sm" onClick={() => showModal()}>Указать проект</Button>
      </div>
    </>
  );
};
