import { useEffect } from "react"
import { useRef } from "react"

export const useOutsideClickListener = (callback) => {
 const ref = useRef()
 useEffect(() => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback()
        }
    }
    document.addEventListener('click', handleClick, true)
    return () => {
        document.removeEventListener('click', handleClick, true)
    }
 }, [ref])
 return ref
}