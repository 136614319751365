import Loader from "../../components/loader";
import { Container } from 'react-bootstrap';
import moment from 'moment';
import GeneralLayout from '../../components/general-layout';
import { useEffect, useState } from 'react';
import { deleteSavedSource, getSavedSources } from "../../store/actions/organizations";
import classes from './saved.sources.module.sass';
import DeleteIcon from '../../icons/delete';
import Alert from "../../components/alert";

export default function SavedSourcesPage() {
    const [savedSources, setSavedSources] = useState([]);
    const [activeDeleteId, setActiveDeleteId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getSavedSources().then((savedSources) => {
            setSavedSources(savedSources);
            setIsLoading(false);
        });
    }, []);
    const onDelete = (id) => {
        setSavedSources(savedSources.filter((s) => s.id !== id));
        deleteSavedSource(id);
    };

    return (
        <GeneralLayout>
            <Container className={classes.container}>
                <div>
                    {isLoading && <Loader />}
                    {!isLoading && savedSources.length === 0 && (
                        <div>На текущий момент сохраненных товаров нет :)</div>
                    )}
                    {savedSources.length > 0 && (
                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>Название</th>
                                    <th>Дата</th>
                                    <th>Источник</th>
                                    <th>Цена(₽)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {savedSources.map((savedSource) => {
                                    const savedSourceDate = moment(savedSource.created_at).format('DD-MM-YYYY');
                                    return (
                                        <tr className={classes.sourceItem}>
                                            <td title={savedSource.name}>{savedSource.name}</td>
                                            <td>{savedSourceDate}</td>
                                            <td>
                                                <a href={savedSource.source} target="_blank" rel="noopener noreferrer">
                                                    {savedSource.source}
                                                </a>
                                            </td>
                                            <td>{savedSource.price}</td>
                                            <td>
                                                <div onClick={() => setActiveDeleteId(savedSource.id)}>
                                                    <DeleteIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                    <Alert
                        show={activeDeleteId}
                        onHide={() => setActiveDeleteId(null)}
                        onSubmit={() => onDelete(activeDeleteId)}
                        title="Вы уверены, что хотите удалить этот источник?"
                    />
                </div>
            </Container>
        </GeneralLayout>
    );
}