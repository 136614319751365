import { Dialog } from '@mui/material';
import { useGetSourceImageQuery } from '../../../../services';
import { useDialog } from '../../../../providers/dialog.provider';
import { ImageWithFallback } from '../../../../components/imageWithFallback';
import Loader from '../../../../components/loader';
import classNames from 'classnames';

export const SourceImageViewer = ({ project_id, product_id, url_source }) => {
  const dialog = useDialog();
  const { data: img, isLoading } = useGetSourceImageQuery({
    project_id,
    product_id,
    url_source,
  }, {
    refetchOnMountOrArgChange: true
  });

  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => dialog.close()}
      sx={{
        '& .MuiPaper-root': {
          width: 'fit-content',
          height: 'fit-content',
          maxWidth: 'fit-content',
          margin: '0 16px',
        },
      }}
    >
      <div className={classNames("tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-h-fit tw-w-fit", {
        'tw-w-[400px] tw-h-[400px] tw-items-center tw-justify-center': isLoading
      })}>
        {isLoading ? (
          <Loader />
        ) : (
          <ImageWithFallback
            alt="img"
            className="tw-scale-[1]"
            srcImg={img?.link}
            fallbackSrc={'/images/placeholders/other.png'}
          />
        )}
      </div>
    </Dialog>
  );
};
