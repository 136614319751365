/* eslint-disable prefer-destructuring */
// Import Chart.js
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from 'chart.js';
import moment from 'moment';
// Import TailwindCSS variables
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../configs/tailwind.config';
// Import utilities
import { formatThousands, hexToRGB } from '../../utils/common';

// Tailwind config
const fullConfig = resolveConfig(tailwindConfig);

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

const hoverLine = {
  id: 'hoverLine',
  afterDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      tooltip,
      chartArea: { bottom,},
      scales: { x, y },
    } = chart;
    if (tooltip && tooltip._active.length > 0) {
      const xCoor = x.getPixelForValue(tooltip.dataPoints[0].parsed.x);
      const yCoor = y.getPixelForValue(tooltip.dataPoints[0].parsed.y);

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(8, 68, 180, 1)';
      ctx.moveTo(xCoor, yCoor);
      ctx.lineTo(xCoor, bottom);
      ctx.stroke();
      ctx.closePath();
    }
  },
};
Chart.register(hoverLine)
// A chart built with Chart.js 3
// https://www.chartjs.org/
export const LineChart = ({ id, data = [], labels = [] }) => {
  const ctx = document.getElementById(id);
  if (!ctx) return;
  // eslint-disable-next-line no-unused-vars
  const chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      datasets: [
        // Indigo line
        {
          label: 'Current',
          data,
          fill: true,
          backgroundColor: (context) => {
            const bgColor = ['rgba(0, 89, 255, 0.10)', 'rgba(0, 89, 255, 0.00)'];
            if (!context.chart.chartArea) return;
            const {
              ctx,
              chartArea: { top, bottom },
            } = context.chart;
            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradientBg.addColorStop(0, bgColor[0]);
            gradientBg.addColorStop(1, bgColor[1]);
            return gradientBg;
          },
          borderColor: '#0844B4',
          borderWidth: 2,
          tension: 0.2,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointBackgroundColor: '#0844B4',
          pointBorderColor: '#fff',
          pointBorderWidth: 2
        },
      ],
    },
    options: {
      layout: {
        padding: 20,
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            callback: (value) => formatThousands(value),
          },
        },
        x: {
          type: 'time',
          time: {
            parser: 'MM-DD-YYYY',
            unit: 'days',
            displayFormats: {
              month: 'MMM YY',
              days: 'DD-MM-YYYY',
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            autoSkipPadding: 48,
            maxRotation: 0,
          },
        },
      },
      plugins: {
        hoverLine,
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              if (context?.[0]?.parsed?.x) return moment(context?.[0]?.parsed?.x).format('DD-MM-YYYY');
            },
            label: (context) => formatThousands(context.parsed.y),
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
      },
      maintainAspectRatio: false,
    },
  });
  return { chart };
};

export default LineChart;
