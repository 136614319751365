import { useState } from 'react';


export const useClearStorageData = () => {
  const [open, setOpen] = useState({});

  const handleCleanData = (rowId) => {
    setOpen((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });
      newState[rowId] = !prevState[rowId];
      return newState;
    });
  };


  return [open, handleCleanData];
}