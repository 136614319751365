import React, { useEffect, useState } from 'react';
import './timePicker.module.scss'

export const TimePicker = ({value, onChange}) => {
  const [time, setTime] = useState(value || '');

  const handleTimeChange = (event) => {
    setTime(event.target.value);
    onChange(event.target.value);
  };

  const clearTime = () => {
    setTime('');
    onChange('');
  };

  useEffect(() => {
    setTime(value || '');
  }, [value]);

  return (
    <>
      <div className="time-picker-container tw-ml-2 tw-relative tw-border input-group-text tw-bg-transparent tw-rounded-lg"
           style={{maxWidth: '91px'}}>
        <input
          type="time"
          value={time}
          onChange={handleTimeChange}
        />
        {time && (
          <button onClick={clearTime} className="btn btn-sm tw-ml-1 tw-absolute tw-right-0 tw-pb-1">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.2248 13.8219L0.174805 12.7719L5.9498 6.99688L0.174805 1.22188L1.2248 0.171875L6.9998 5.94688L12.7748 0.171875L13.8248 1.22188L8.0498 6.99688L13.8248 12.7719L12.7748 13.8219L6.9998 8.04688L1.2248 13.8219Z"
                fill="#7F7F7F" />
            </svg>
          </button>
        )}
      </div>
    </>
  )
}