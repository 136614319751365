export const OverallInfo = ({ totalUsers, activeUsersAmount, totalRequests, totalProjects, totalProducts }) => {
  return (
    <>
      <div className="tw-flex tw-flex-col tw-border-t-[#999999] tw-border-t tw-pt-[17px] tw-text-[#191919]">
        <table className="tw-table-fixed tw-w-full ">
          <tbody>
            <tr>
              <td>
                <p className=" tw-leading-[18px] tw-font-medium tw-text-sm">
                  Всего пользователей: <strong>{totalUsers}</strong>
                </p>
              </td>
              <td className="tw-px-4">
                <p className="tw-leading-[18px] tw-font-semibold">{totalRequests ?? 0}</p>
              </td>
              <td className="tw-px-4">
                <p className="tw-leading-[18px] tw-font-semibold">{totalProjects ?? 0}</p>
              </td>
              <td className="tw-px-4">
                <p className="tw-leading-[18px] tw-font-semibold">{totalProducts ?? 0}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill="#0BD90B" />
          </svg>
          <p>
            Активные: <strong>{activeUsersAmount}</strong>
          </p>
        </div>
      </div>
    </>
  );
};
