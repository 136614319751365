import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { useCallback } from 'react';
import Button from '../button';
import { Form } from 'react-final-form';
import { createErrorNotification } from '../../utils/notifications';

export const ConfirmModal = ({ title, onAgree, onReject, value, successMessage = 'Удалено', btnActionProp = 'Удалить' }) => {
  const dialog = useDialog();
  const handleAgree = useCallback(
    ({ id }) => {
      try {
        onAgree && onAgree(id);
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так', { type: 'error' });
      }
      dialog.close();
    },
    [onAgree, dialog]
  );

  const handleReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);

  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={value}
        onSubmit={handleAgree}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-lg">
              <p className="tw-text-[#191919] tw-font-semibold tw-mb-14 tw-text-base">{title}</p>
              <DialogActions className="tw-gap-[18px]">
                <Button className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm" variant="text" onClick={handleReject}>
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={submitting}
                  type="submit"
                >
                  {btnActionProp}
                </Button>
              </DialogActions>
            </div>
            {submitError && createErrorNotification(submitError)}
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
