import { useCallback } from 'react';
import { useUrlState } from './useUrlState';

export const usePaginationParams = () => {
  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const query = getQueryParam('query') || '';

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );


  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  return {
    page_size,
    page_num,
    query,
    handleLimitChange,
    handleQueryChange,
    goToPage,
  };
};
