import { useCallback, useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { newsAPIs, useAddArticleMutation, useEditArticleMutation } from '../../services';
import Button from '../../components/button';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { useDialog } from '../../providers/dialog.provider';
import { AddNews } from './modals/AddNews';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import { usePagination } from '../../components/table/usePagination';
import { CrudTable } from '../../components/table/CrudTable';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/table/pagination';
import { useSortableData } from '../../hooks/useSortableData';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import styles from './news.module.sass'

export const NewsHandbook = () => {
  const [news, setNews] = useState([]);
  const [searchText, setSearchText] = useState('');
  const dialog = useDialog();
  const { requestSort, sortingLabel, sortConfig } = useSortableData([], null, true);

  const [addArticle] = useAddArticleMutation()
  const [editArticle] = useEditArticleMutation()
  
// TODO: Change this to RTK! I didn't have time a.t.m...
  const getAllNews = async (limit = 1, page_num, query = '') => {
    // const sort_query = sortConfig?.key ? `&sort_by=${sortConfig?.key}&sort_order=${sortConfig?.direction}` : '';
    // const full_query = query + sort_query;
    const res = await newsAPIs.getNews(limit, page_num, query);
    return res;
  };

  const COLUMNS = {
    date: {
      renderHeaderCell: (value) => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {sortingLabel('Дата', 'created_at')}
          </div>
        );
      },
    },
    title: {
      label: 'Заголовок',
      renderBodyCell: (value) => {
        return (
          <>
            <Link className="tw-text-[#0A50D4]" to={`/handbook/news/${value.id}`}>
              {value.title}
            </Link>
          </>
        );
      },
    },
    description: {
      label: 'Текст',
      renderBodyCell: (value) => {
        return <p className={styles.newsTable_description} dangerouslySetInnerHTML={{ __html: value?.description }}></p>;
      },
    },
  };

  const { data, currentPage, goToPage, total, setPageSize, pageSize, handleChangeQuery, refetch } =
    usePagination(getAllNews);

    const handleAddArticle = useCallback(async(article) => {
      await addArticle(article).unwrap().then(() => {
        // TODO: Change this to RTK! I didn't have time a.t.m...
        refetch()
        createNotification('Новость добавлена')
      })
    }, [addArticle, refetch])

    const handleEditArticle = useCallback(async (id, body) => {
      await editArticle({id, body}).unwrap().then(() => {
        // TODO: Change this to RTK! I didn't have time a.t.m...
        refetch()
        createNotification('Новость изменена')
      })
    }, [editArticle, refetch])

  const showEditModal = useCallback(
    (entity) => {
      dialog.open(<AddNews article={entity} onSuccess={(body) => handleEditArticle(entity.id, body)} />);
    },
    [dialog, handleEditArticle]
  );

  const handleDeleteNew = useCallback(
    (id) =>
      newsAPIs
        .deleteNew(id)
        .then(() => {
          refetch();
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при попытке удаления новости');
        }),
    [refetch]
  );

  const askConfirmationDelete = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal value={entity} title={`Вы уверены, что хотите удалить новость?`} onAgree={handleDeleteNew} />
      );
    },
    [dialog, handleDeleteNew]
  );

  const crudButtons = useCallback(
    (entity) => {
      return (
        <div className="tw-flex tw-flex-row tw-border-b-0 tw-justify-center tw-gap-[18px]">
          <Button
            variant="text"
            className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit tw-w-fit"
            onClick={() => showEditModal(entity)}
          >
            <EditIcon />
          </Button>
          <Button
            variant="text"
            className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit tw-w-fit"
            onClick={() => askConfirmationDelete(entity)}
          >
            <DeleteIcon />
          </Button>
        </div>
      );
    },
    [showEditModal, askConfirmationDelete]
  );

  useEffect(() => {
    setNews(data);
  }, [data]);

  const showCreateNewModal = useCallback(() => {
    dialog.open(<AddNews onSuccess={handleAddArticle} />);
  }, [dialog, handleAddArticle]);

  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };

  const handleChangeSearch = (value) => {
    setSearchText(value);
    handleChangeQuery(value);
  };

  useEffect(() => {
    const handleChangeSort = () => {
      const sort_query = sortConfig?.key ? `&sort_by=${sortConfig?.key}&sort_order=${sortConfig?.direction}` : '';
      const full_query = searchText + sort_query;
      handleChangeQuery(full_query);
    };
    handleChangeSort();
  }, [sortConfig]);

  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[38px] tw-text-[32px] md:tw-mb-8 tw-mb-5 sm:tw-mb-7">
        Справочник новостей
      </h2>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg">
        <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center tw-px-6 tw-pt-[25px]">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                placeholder="Найти новость"
                type="text"
                name="search"
                className="tw-max-w-[312px]"
                onSearchChange={handleChangeSearch}
              />
            )}
          </Form>
          <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateNewModal()}>
            <AddIcon className="tw-mr-[4px]" />
            Создать новость
          </Button>
        </div>
        <div className="tw-flex tw-flex-col tw-px-[12px]">
          <CrudTable rows={news} entityColumns={COLUMNS} crudButtons={crudButtons} emptyRowsLabel={'Новостей нет'} />
        </div>
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </GeneralLayout>
  );
};
