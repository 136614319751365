import Button from '../../../components/button';

export const YandexSearcherReport = () => {
  return (
    <div className="tw-flex tw-flex-col tw-py-5">
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-[22px]">
          График использованных лимитов Yandex Searcher
        </p>
        <a
          className="tw-text-[#0844B4] hover:tw-text-[#0A50D4] "
          href="https://predictiveanalytics.yanao.ru/api/reports/report_requests"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="text" className="tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent tw-text-[#134B98]">
            Перейти
          </Button>
        </a>
      </div>
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-[22px]">
          Отчёт об использованных лимитах Yandex Searcher
        </p>
        <a
          className="tw-text-[#0844B4] hover:tw-text-[#0A50D4] "
          href="https://dev-predictiveanalytics.yanao.ru/api/reports/searcher_limits"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="text" className="tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent tw-text-[#134B98]">
            Перейти
          </Button>
        </a>
      </div>
    </div>
  );
};
