import { useMediaQuery } from '@mui/material';

export function withAdaptive(adaptives){
  return (props) => {
    const tablet = useMediaQuery('(max-width: 768px)');
    const mobile = useMediaQuery('(max-width: 500px)');
    const desktop = useMediaQuery('(min-width: 769px)');

    if (mobile && adaptives.mobile) return <adaptives.mobile {...props} key="Mobile" />;

    if (tablet && adaptives.tablet) return <adaptives.tablet {...props} key="Tablet" />;

    if (desktop && adaptives.desktop) return <adaptives.desktop {...props} key="Desktop" />;

    return <adaptives.default {...props} key="Default" />;
  };
}


