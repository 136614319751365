import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

const DialogContext = createContext({
  visibility: false,
  open: () => {},
  close: () => {}
})

export function useDialog() {
  const {open, close, visibility} = useContext(DialogContext);

  return {
   open,
   close,
    visibility
  }
}

export function DialogContextProvider({children}) {
  const [dialogElement, setDialogElement] = useState(<></>);
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const openDialog = useCallback((element) => {
    setDialogElement(element)
    setDialogVisibility(true)
  }, [])

  const closeDialog = useCallback(() => {
    setDialogVisibility(false)
  }, [])

  const dialogContext = useMemo(() => {
    return {
      visibility: dialogVisibility,
      open: openDialog,
      close: closeDialog
    }
  }, [dialogVisibility, openDialog, closeDialog])

  return <DialogContext.Provider value={dialogContext}>
    {children}
    {createPortal(dialogElement, document.body, 'dialog')}
  </DialogContext.Provider>
}
