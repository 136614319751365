import { useContext } from 'react';
import { createContext, useMemo } from 'react';

export const FeatureFlags = createContext({
    projectStatus: ''
});

export const useFeature = () => {
  const { projectStatus } = useContext(FeatureFlags);

  return {
    projectStatus,
  };
};

export const FeatureFlagsProvider = ({ children }) => {
  const featureFlagsContext = useMemo(() => {
    return {
        projectStatus: process.env.REACT_APP_CURRENT_STAGE
    }
  }, [])
  return <FeatureFlags.Provider value={featureFlagsContext}>{children}</FeatureFlags.Provider>;
};
