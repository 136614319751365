import { useCallback, useMemo } from 'react';
import GeneralLayout from '../../components/general-layout';
import { useUrlState } from '../../hooks/useUrlState';
import { useDialog } from '../../providers/dialog.provider';
import {
  useGetGloballyBlockedSourcesQuery,
  useGloballyBlockSourceMutation,
  useGloballyUnblockSourceMutation,
  useUpdateGloballyBlockedSourceMutation,
} from '../../services/adminAPIs';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as RestoreIcon } from '../../icons/restore.svg';
import Button from '../../components/button';
import { Pagination } from '../../components/table/pagination';
import Table from '../../components/table';
import moment from 'moment';
import { AddSource } from './modals/AddSource';
import { createNotification } from '../../utils/notifications';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { ConfirmModal } from '../../components/modal/ConfirmModal';

export const GlobalBlocks = () => {
  const dialog = useDialog();
  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const query = getQueryParam('query') || '';

  const { data: blockedSources, isLoading } = useGetGloballyBlockedSourcesQuery({
    page_size,
    page_num,
    query,
  });

  const [blockHost] = useGloballyBlockSourceMutation();
  const [editBlockedHost] = useUpdateGloballyBlockedSourceMutation();
  const [deleteHost] = useGloballyUnblockSourceMutation();

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const handleAddSourceToBlocked = useCallback(
    async (values) => {
      await blockHost(values)
        .unwrap()
        .then(() => {
          createNotification('Источник добавлен');
        });
    },
    [blockHost]
  );

  const handleDeleteBlockedSources = useCallback(
    async (id) => {
      await deleteHost(id)
        .unwrap()
        .then(() => {
          createNotification('Источник разблокирован');
        });
    },
    [deleteHost]
  );

  const handleEditBlockedSource = useCallback(
    async (id, values) => {
      await editBlockedHost({
        source_id: id,
        body: values,
      })
        .unwrap()
        .then(() => {
          createNotification('Источник изменён');
        });
    },
    [editBlockedHost]
  );

  const openAddSourceToBlockedModal = useCallback(() => {
    dialog.open(<AddSource onSubmit={handleAddSourceToBlocked} />);
  }, [dialog, handleAddSourceToBlocked]);

  const openEditBlockedSourceModal = useCallback(
    (entity) => {
      dialog.open(<AddSource initialValues={entity} onSubmit={handleEditBlockedSource} />);
    },
    [dialog, handleEditBlockedSource]
  );

  const askConfirmationDelete = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          value={entity}
          onAgree={handleDeleteBlockedSources}
          title={`Вы уверены, что хотите восстановить источник ${entity?.name}?`}
        />
      );
    },
    [dialog, handleDeleteBlockedSources]
  );

  const columns = useMemo(() => {
    return {
      name: {
        label: 'Источник',
        renderBodyCell: (values) => {
          return <p>{values?.name === '' ? values?.domain : values?.name}</p>;
        },
      },
      original_link: {
        label: 'Ссылка',
        renderBodyCell: (values) => {
          let link;
          try {
            link = new URL(values?.original_link);
          } catch (err) {
            link = null;
          }
          return (
            <div className="tw-overflow-x-hidden tw-text-ellipsis">

            <a
              href={link?.origin ?? '#'}
              className="tw-text-[#134B98] tw-text-sm tw-leading-5 tw-font-medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              {link?.origin}
            </a>
            </div>
          );
        },
      },
      author: {
        label: 'Заблокировал',
      },
      created_at: {
        label: 'Дата добавления',
        renderBodyCell: (value) => {
          return (
            <p className="tw-text-[#191919] tw-font-medium">
              {value?.created_at ? moment(value.created_at).format('DD.MM.YYYY HH:mm') : '-'}
            </p>
          );
        },
      },
      reason: {
        label: 'Причина',
        renderBodyCell: (values) => {
          return (
            <Tooltip title={values?.reason}>
              <p className="tw-block tw-truncate tw-max-w-[285px]">{values?.reason}</p>
            </Tooltip>
          );
        },
      },
      id: {
        label: '',
        renderBodyCell: (values) => {
          return (
            <div className="tw-flex tw-flex-row tw-gap-5 tw-items-center">
              <Button
                variant="text"
                className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
                onClick={() => openEditBlockedSourceModal(values)}
              >
                <EditIcon />
              </Button>
              <Button
                variant="text"
                className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
                onClick={() => askConfirmationDelete(values)}
              >
                <RestoreIcon />
              </Button>
            </div>
          );
        },
      },
    };
  }, [askConfirmationDelete, openEditBlockedSourceModal]);

  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-leading-7 sm:tw-leading-[38px] tw-mb-6 md:tw-mb-8">
        Управление глобальными блокировками
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-px-6 tw-pt-[25px] tw-gap-4">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <Form
              onSubmit={() => {}}
              initialValues={{
                search: query,
              }}
            >
              {() => (
                <form>
                  <SearchInput
                    name="search"
                    placeholder="Найти источник"
                    type="text"
                    onSearchChange={handleQueryChange}
                  />
                </form>
              )}
            </Form>
            <Button
              variant="text"
              onClick={openAddSourceToBlockedModal}
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-flex tw-flex-row tw-items-center tw-gap-1"
            >
              <AddIcon />
              Добавить источник для блокировки
            </Button>
          </div>
          {!isLoading ? (
            <Table rows={blockedSources?.data} columns={columns} tableClassName={'tw-table-fixed'} />
          ) : (
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
              <div
                class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              />
            </div>
          )}
        </div>
        <Pagination
          rowsPerPage={page_size}
          count={blockedSources?.count}
          page={blockedSources?.page_num}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </GeneralLayout>
  );
};
