import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralLayout from '../../components/general-layout';
import { checkIsAdmin } from '../../utils/common';
import Organizations from './organizations';
import Users from './users';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { Tab } from '@mui/material';

export default function PersonalAreaPage() {
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const [activeTab, setActiveTab] = useState('users');

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };
  if (!isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-6">
        <h2 className="tw-text-[#191919] tw-leading-[28px] sm:tw-leading-[38px] tw-text-2xl sm:tw-text-[32px] tw-font-semibold">
          {user && user.full_name}
        </h2>
        <div className="tw-w-full tw-bg-white tw-rounded-lg tw-px-6">
          <TabContext value={activeTab}>
            <div className="tw-w-full tw-border-b tw-border-b-[#DDDDDD] tw-pt-[10px] ">
              <TabList
                onChange={handleTabChange}
                sx={{
                  '& .MuiTabs-flexContainer': {
                    gap: '24px',
                  },
                }}
              >
                <Tab label="Пользователи" value="users" disableRipple className="tw-normal-case tw-px-0" />
                <Tab label="Организации" value="organizations" disableRipple className="tw-normal-case tw-px-0" />
              </TabList>
            </div>
            <TabPanel value="users" className="tw-p-0">
              <div className="tw-pt-4">
                <Users />
              </div>
            </TabPanel>
            <TabPanel value="organizations" className="tw-p-0">
              <div className="tw-pt-4">
                <Organizations />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </GeneralLayout>
  );
}
