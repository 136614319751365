import { useEffect, useState } from 'react';
import Button from '../../components/button';
import { createErrorNotification } from '../../utils/notifications';
import moment from 'moment';
import { useCallback } from 'react';
import { LinearProgress } from '@mui/material';
import { gisAPIs } from '../../services';
import { usePooling } from '../../hooks/usePooling';
import GeneralLayout from '../../components/general-layout';

export const Import2gis = () => {
  const [date, setDate] = useState('');

  const stopCondition = (data) => {
    return data.status === 'finished';
  };
  const { data, startUpdate, isUpdating } = usePooling(gisAPIs.getLastUpdate, 3000, stopCondition);

  const getLastUpdate = useCallback(async () => {
    try {
      const { data } = await gisAPIs.getLastUpdate();
      setDate(moment(data.download_data).format('DD.MM.YYYY'));
      if (data?.status !== 'finished') {
        startUpdate();
      }
    } catch (err) {
      console.error(err);
      createErrorNotification('Ошибка получения последнего обновления');
    }
  }, []);
  useEffect(() => {
    getLastUpdate();
  }, [getLastUpdate]);
  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-leading-7 sm:tw-leading-[38px] tw-mb-6 md:tw-mb-8">
        Обновление 2gis
      </h2>
      <div className="tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-bg-white tw-w-full tw-p-6 tw-h-[80vh]">
        <div className="tw-flex tw-flex-col tw-gap-1 tw-mb-6">
          <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Дата последнего обновления</p>
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">{date && date}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-mb-[38px]">
          <p className="tw-text-sm tw-text-[#191919] tw-leading-[18px] tw-max-w-[535px]">
            Господа, современная методология разработки является качественно новой ступенью направлений прогрессивного
            развития. Задача организации, в особенности же граница обучения кадров, а также свежий взгляд на привычные
            вещи — безусловно открывает новые горизонты для инновационных методов управления процессами.
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-[20px] tw-items-start tw-w-full">
          <Button
            disabled={isUpdating}
            onClick={() => startUpdate()}
            className="tw-w-fit tw-py-3 tw-px-4 tw-leading-[18px] tw-text-sm tw-font-semibold tw-text-white"
          >
            {!isUpdating ? 'Запуск обновления' : 'Обновление запущено'}
          </Button>
          {isUpdating && (
            <div className="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-[10px]">
              <LinearProgress
                variant="determinate"
                value={data?.load_percentage ?? 0}
                sx={{
                  width: '100%',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#0BD90B',
                  },
                  '&.MuiLinearProgress-root': {
                    backgroundColor: '#DDDDDD',
                  },
                }}
              />
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px] tw-text-[#666666] tw-leading-[18px] tw-font-medium tw-text-sm">
                {/* <p className="">{'Обработано 22 из 100'}</p> */}
                <span className="tw-text-center">·</span>
                {/* 4 days, 6:46:36 */}
                {/* <p className="">Осталось {data && moment(data?.download_completion_time).format('HH:MM:ss')}</p> */}
                <p className="">Осталось {data?.download_completion_time}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </GeneralLayout>
  );
};
