import { useState, useEffect, useCallback } from 'react';

export const usePagination = (fetchFunction, initialPage = 1, limit = 10, query = '') => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchQuery, setSearchQuery] = useState(query);
  const [pageSize, setPageSize] = useState(limit);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [allResults, setAllResults] = useState(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetchFunction(pageSize, currentPage, searchQuery);
      setAllResults(res);
      setData(res.data);
      setTotal(res.count);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, searchQuery]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const goToPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeQuery = useCallback(
    (value) => {
      setSearchQuery(value);
      goToPage(1);
    },
    [goToPage]
  );

  return {
    data,
    currentPage,
    setCurrentPage,
    isLoading,
    goToPage,
    error,
    total,
    setPageSize,
    pageSize,
    setSearchQuery,
    allResults,
    handleChangeQuery,
    searchQuery,
    refetch,
  };
};

/*
! fetchFunction example
    fetchFunction = async(skip, limit, query) => {
       
            await handbookAPIs.getHandbooks('categories', limit, skip, query).then((res) => return res)
    }
*/
