import { useEffect, useState } from 'react';
import { SwitchMUI } from '../../../components/switch';
import { adminAPIs } from '../../../services/adminAPIs';

export const ServerProblemsMessage = () => {
  const [isServerProblems, setIsServerProblems] = useState(false);

  useEffect(() => {
    const fetchInitialServerStatus = async () => {
      await adminAPIs
        .getTechStatus()
        .then((res) => setIsServerProblems(res.status))
        .catch((err) => console.error(err));
    };
    fetchInitialServerStatus();
  }, []);

  const handleChangeServerStatus = async (status) => {
    setIsServerProblems((prev) => !prev);
    await adminAPIs
      .setTechStatus(status)
      .then((res) => setIsServerProblems(res.status))
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-items-start tw-w-full tw-p-6 tw-gap-6">
        <p className="tw-text-[#191919] tw-leading-[22px] tw-font-semibold">Сообщение о работах по обновлению</p>
        <div className="tw-flex tw-flex-row tw-gap-[10px] tw-items-center">
          <SwitchMUI initialValue={isServerProblems} handleChange={handleChangeServerStatus} />
          <p className="tw-text-[#191919] tw-leading-[20px]">Сообщение {isServerProblems ? 'включено' : 'выключено'}</p>
        </div>
      </div>
    </>
  );
};
