import { request } from '../utils/request';

export const statisticsAPIs = {
  getGeneralStatistics: (params) =>
    request(`/api/statistics/general_statistics${params ? '?' + params : ''}`, {
      method: 'GET',
    }),
  getCategoriesStatistics: () =>
    request(`/api/statistics/categories`, {
      method: 'GET',
    }),
  getCategoryStatistics: (id, params) =>
    request(`/api/statistics/category_statistics/${id}${params ? '?' + params : ''}`, {
      method: 'GET',
    }),
  getProductCategoryStatistics: (id, params) =>
    request(`/api/statistics/category_products/${id}${params ? '?' + params : ''}`, {
      method: 'GET',
    }),
  getHostCategoryStatistics: (id, params) =>
    request(`/api/statistics/category_host/${id}${params ? '?' + params : ''}`, {
      method: 'GET',
    }),
  getHostsStatistics: (params) =>
    request(`/api/statistics/general_statistics/hosts${params ? '?' + params : ''}`, {
      method: 'GET',
    }),
  getHostsList: (organizationId, time) =>
    request(
      `/api/statistics/hosts_with_points?organization_id=${organizationId}&since=${time.since}&till=${time.till}`,
      {
        method: 'GET',
      }
    ),
};
