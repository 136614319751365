import React, { useCallback } from 'react';
import Button from '../../../../components/button';
import { ReactComponent as AddIcon } from '../../../../icons/addIcon.svg';
import { ReactComponent as LockIcon } from '../../../../icons/lockIcon.svg';
import { Source } from './Source';
import { useDialog } from '../../../../providers/dialog.provider';
import { ProductBlockedHosts } from '../modals/ProductBlockedHosts';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  useAddSourceToProductMutation,
  useBlockSourceMutation,
  useDeleteProductSourceMutation,
  useIncorrectDimensionsMutation,
  useSaveSourceMutation,
  useSelectSourceMutation,
} from '../../../../services';
import { useSelector } from 'react-redux';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import { ProductAddSupplier } from '../modals/ProductAddSupplier';
import { ProductInfo } from '../modals/ProductInfo';
import { useParams } from 'react-router-dom';
import { CorrectingPrice } from '../../../../components/product-card/components/modals/CorrectingPrice';
import { Form } from 'react-final-form';
import { isScreenshotsInProgress } from '../../../../store/selectors/organization';

export const SourcesList = React.memo(({ sources, asTable, product_id, product_name, selectedSources }) => {
  const dialog = useDialog();
  const { id } = useParams();

  const report = useSelector((state) => state?.organizations?.selectedReport);

  const [addSource] = useAddSourceToProductMutation();
  const [selectSource] = useSelectSourceMutation();
  const [saveSource] = useSaveSourceMutation();
  const [changeDimensions] = useIncorrectDimensionsMutation();
  const [blockSource] = useBlockSourceMutation();
  const [deleteProductSource] = useDeleteProductSourceMutation();

  const handleAddSource = useCallback(
    async (price, url) => {
      const body = {
        source_price: price,
        source_url: url,
        report_id: report?.id,
      };

      await addSource({ product_id, body })
        .unwrap()
        .then(() => {
          createNotification('Источник добавлен');
        });
    },
    [addSource, product_id, report?.id]
  );

  const handleChangeDimensions = useCallback(
    async (body) => {
      await changeDimensions(body)
        .unwrap()
        .then(() => {
          createNotification('Жалоба сохранена');
        });
    },
    [changeDimensions]
  );

  const handleDeleteSource = useCallback(
    async (id) => {
      const sourcesToSend = sources.filter((source) => source.id !== id);

      await deleteProductSource({ report_id: report?.id, product_id, sources: sourcesToSend })
        .unwrap()
        .then(() => {
          createNotification('Источник удалён');
        });
    },
    [deleteProductSource, product_id, report?.id, sources]
  );
  const handlePriceCorrection = useCallback((res) => {
    if (res.status === 'ERROR') {
      createErrorNotification('Поиск не обнаружил указанную цену');
    } else {
      createNotification('Цена изменена');
    }
  }, []);

  const handleBlockAndDeleteSource = useCallback(
    async (id, url) => {
      await blockSource({ report_id: report?.id, product_id, url })
        .unwrap()
        .then(async () => {
          const sourcesWithoutDeleted = sources.filter((item) => item?.id !== id);

          await deleteProductSource({ report_id: report?.id, product_id, sources: sourcesWithoutDeleted }).then(() => {
            createNotification('Источник удалён и заблокирован');
          });
        });
    },
    [blockSource, deleteProductSource, product_id, report?.id, sources]
  );

  const showCorrectingPriceModal = useCallback(
    (source) => {
      dialog.open(
        <CorrectingPrice
          item={source}
          productName={product_name}
          product_id={product_id}
          onSubmit={handlePriceCorrection}
        />
      );
    },
    [dialog, handlePriceCorrection, product_id, product_name]
  );

  const handleSelectSource = useCallback(
    async (selectedSources) => {
      const body = {
        selected_indices_per_product: {
          [product_id]: selectedSources ?? [],
        },
      };
      await selectSource({ report_id: report?.id, body });
    },
    [product_id, report?.id, selectSource]
  );

  const handleSaveSource = useCallback(
    async (source) => {
      const body = {
        source: source?.id,
      };
      await saveSource({ report_id: report?.id, product_id, body })
        .unwrap()
        .then(() => {
          createNotification('Источник сохранён');
        });
    },
    [product_id, report?.id, saveSource]
  );

  const showDetailedProductInfo = useCallback(
    (entity) => {
      dialog.open(
        <ProductInfo
          onEdit={handleChangeDimensions}
          delivery_info={entity?.delivery_prices}
          source={entity}
          productId={product_id}
          projectId={id}
        />
      );
    },
    [dialog, handleChangeDimensions, id, product_id]
  );

  const showBlockedHostsModal = useCallback(() => {
    dialog.open(<ProductBlockedHosts product_id={product_id} />);
  }, [dialog, product_id]);

  const showAddProductSource = useCallback(() => {
    dialog.open(<ProductAddSupplier onSubmit={handleAddSource} />);
  }, [dialog, handleAddSource]);

  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress)

  return (
    <div className={`tw-flex tw-flex-col tw-w-full ${asTable && 'tw-bg-[#F7F7F7]'}`}>
      <div className={`tw-flex tw-flex-row tw-gap-4 tw-px-4 tw-pt-3 tw-mb-3 `}>
        <Button
          variant="text"
          onClick={showAddProductSource}
          disabled={screenshotsIsUpdating}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-inline-flex tw-gap-1 tw-items-center tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px]"
        >
          <AddIcon className="tw-w-[22px] tw-h-[22px]" /> Добавить источник
        </Button>
        <Button
          variant="text"
          onClick={showBlockedHostsModal}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-inline-flex tw-gap-1 tw-items-center tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px]"
        >
          <LockIcon className="tw-w-[22px] tw-h-[22px]" /> Заблокированные источники
        </Button>
      </div>
      <Form
        onSubmit={(values) => handleSelectSource(values.suppliers)}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          suppliers: selectedSources,
        }}
        render={({ handleSubmit }) => (
          <form onChange={handleSubmit}>
            <FieldArray name="suppliers">
              {({ fields }) => (
                <>
                  {sources.map((source) => (
                    <Source
                      source={source}
                      key={source?.id}
                      asTable={asTable}
                      fields={fields}
                      product_id={product_id}
                      onDeleteSource={handleDeleteSource}
                      onSaveSource={handleSaveSource}
                      viewDetailedInfo={showDetailedProductInfo}
                      onCorrectingPrice={showCorrectingPriceModal}
                      onBlockSource={handleBlockAndDeleteSource}
                    />
                  ))}
                </>
              )}
            </FieldArray>
          </form>
        )}
      />
    </div>
  );
});
