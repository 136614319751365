import { Dialog } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { useState } from 'react';
import moment from 'moment';
import { downloadFile } from '../../../utils/request';
import { createErrorNotification } from '../../../utils/notifications';
import { DatePicker } from '../../../components/date-picker';
import Button from '../../../components/button';

export const ExportRequests = () => {
  const dialog = useDialog();
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month'),
    till: moment(),
  });
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    const start_date = moment(queryParams.since).format('DD-MM-YYYY');
    const end_date = moment(queryParams.till).format('DD-MM-YYYY');
    const period = `c ${start_date} по ${end_date}`;
    setIsLoading(true)
    await downloadFile(
      `/api/admin/tech_supports/technical_support/export_file?start_date=${start_date}&end_date=${end_date}`,
      `Отчёт по периоду ${period}.xlsx`
    )
      .then(() => {
        setIsLoading(false)
        dialog.close();

      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err);
        createErrorNotification('Ошибка при получении отчёта о периоде');
      });
  };

  const handleClose = () => {
    dialog.close();
  };

  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-gap-12 tw-h-[340px]">
        <div className="tw-flex tw-flex-col tw-gap-2">
          <p className="tw-text-[#191919] tw-font-semibold tw-mb-4">Экспорт результатов</p>
          <p className='tw-text-sm tw-text-[#191919] tw-mb-1'>Укажите нужный период для отчёта</p>
          <div className='tw-flex tw-flex-row tw-justify-start'>
            <DatePicker queryParams={queryParams} setQueryParams={setQueryParams} withPortal />
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end tw-mt-auto">
          <Button
            variant="text"
            className="tw-w-fit hover:tw-bg-transparent tw-p-0 tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
            onClick={() => handleClose()}
          >
            Отменить
          </Button>
          <Button className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg" onClick={() => handleSubmit()} disabled={isLoading}>
            Выгрузить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
