import { Dialog } from "@mui/material"
import { useDialog } from "../../../providers/dialog.provider"

export const ImageViewer = ({src}) => {
    const dialog = useDialog()

    return (
        <Dialog open={dialog.visibility} onClick={() => dialog.close()}
        sx={{
            '& .MuiPaper-root': {
                width: 'fit-content',
                height: 'fit-content',
                maxWidth: 'fit-content',
                margin: '0 16px'
            }
        }}>
            <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-h-fit tw-w-fit">
                <img src={src} alt="img" className="tw-scale-[1]"/>
            </div>
        </Dialog>
    )
}