import classNames from 'classnames';
import { Tooltip } from '../../../../../components/tooltip/Tooltip';
import { priceFormatter } from '../../../../../utils/priceFormatter';
import { ReactComponent as TooltipIcon } from '../../../../../icons/tooltipIcon.svg';
import Button from '../../../../../components/button';
import { ReactComponent as DeleteIcon } from '../../../../../icons/deleteIcon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../icons/arrowRightIcon.svg';
import { REPORT_STATUS } from '../../../../../utils/constant';
import { Skeleton } from '@mui/material';
import { isScreenshotsInProgress } from '../../../../../store/selectors/organization';
import { useSelector } from 'react-redux';

export const ProductLogistic = ({
  showDeliveryInfo,
  avgDeliveryPrice,
  hasIncorrectSources,
  hasSources,
  onDeleteProduct,
  avgProductPrice,
  handleShowSourcesList,
  sourcesAmount,
  isSourceListVisible,
  productLogisticStatus,
}) => {
  const isLogisticLoading = productLogisticStatus === REPORT_STATUS.PROCESSING;
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-pt-4 tw-pl-4 tw-pr-[14px] tw-pb-[14px] tw-relative">
        <Button
          variant="text"
          onClick={onDeleteProduct}
          disabled={screenshotsIsUpdating}
          className="tw-w-fit hover:tw-bg-transparent tw-p-0 tw-absolute tw-top-[13px] tw-right-[18px]"
        >
          <DeleteIcon className="tw-fill-[#C12026]" />
        </Button>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px] tw-mb-[17px]">
          <p className="tw-flex tw-flex-row tw-items-start tw-gap-1 tw-text-sm tw-font-medium tw-leading-[18px] tw-text-[#666666]">
            Средняя цена за товар
            <Tooltip title="Средняя цена за товар по ценам, найденным в источниках.">
              <TooltipIcon />
            </Tooltip>
          </p>
          {hasSources ? (
            <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold">
              {Number(avgProductPrice) >= 0 ? priceFormatter(avgProductPrice, 2) : 'Не найдено '}
              <span className="tw-text-sm tw-leading-[16px]">₽</span>
            </p>
          ) : (
            <div className="tw-pb-[68px]">
              <p className="tw-text-[#191919] tw-font-semibold">Источники не найдены</p>
            </div>
          )}
        </div>
        {hasSources && (
          <>
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-[5px] tw-mb-4">
              <p className="tw-inline-flex tw-gap-1 tw-w-full tw-text-sm tw-font-medium tw-leading-[18px] tw-text-[#666666]">
                Средняя стоимость доставки
                <Tooltip title="Список найденных транспортных компаний с ценами за услуги по доставке товара.">
                  <TooltipIcon />
                </Tooltip>
              </p>
              {isLogisticLoading ? (
                <Skeleton width={144} height={24} variant="rounded" />
              ) : (
                <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center">
                  <p
                    className={classNames(
                      'tw-leading-5 tw-font-semibold',
                      { 'tw-text-[#C12026]': hasIncorrectSources },
                      { 'tw-text-[#191919]': !hasIncorrectSources }
                    )}
                  >
                    {Number(avgDeliveryPrice) >= 0 ? priceFormatter(avgDeliveryPrice, 2) : 'Не найдено '}
                    <span className="tw-text-sm tw-leading-[16px]">₽</span>
                  </p>
                  <span data-tour="detailInfoStep">
                    <Button
                      variant="text"
                      onClick={showDeliveryInfo}
                      className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-medium"
                    >
                      Детально
                    </Button>
                  </span>
                </div>
              )}
            </div>
            <span data-tour="sourceStep">
              <Button
                variant="outlined"
                className="tw-flex tw-flex-row tw-items-center tw-w-fit tw-rounded-lg tw-pl-3 tw-pr-[9px] tw-py-[11px] tw-text-sm tw-leading-[18px]"
                onClick={handleShowSourcesList}
              >
                <p className="tw-text-[#134B98]">Источники {sourcesAmount}</p>
                <ArrowIcon
                  className={classNames('tw-fill-[#0844B4] tw-w-[20px] tw-h-[20px] tw-transition-transform', {
                    'tw-rotate-[-90deg]': isSourceListVisible,
                    'tw-rotate-90': !isSourceListVisible,
                  })}
                />
              </Button>
            </span>
          </>
        )}
      </div>
    </>
  );
};
