import { useCallback, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { generatePassword } from '../../utils/common';

export default function Price({ value }) {
  const [maxWidth, setMaxWidth] = useState(null);
  const id = useMemo(() => generatePassword(), []);

  const valueRef = useCallback(
    (node) => {
      if (node !== null) {
        const parentWidth = node.parentElement.offsetWidth;
        setMaxWidth(parentWidth);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );
  if (!value) {
    return '- ₽';
  }
  // const [parsedValue, append] = parseBigNumber(value);
  return (
    <NumberFormat
      value={value}
      suffix={` ₽`}
      decimalScale={2}
      displayType="text"
      thousandSeparator=" "
      decimalSeparator=","
      renderText={(renderValue) => (
        <>
          <span
            data-tip
            data-for={id}
            ref={valueRef}
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: maxWidth ? 'hidden' : undefined,
              textOverflow: 'ellipsis',
              maxWidth: Math.max(maxWidth, 140),
            }}
          >
            {renderValue}
          </span>
          <ReactTooltip id={id} effect="solid" place="bottom">
            <NumberFormat
              value={value}
              suffix=" ₽"
              decimalScale={2}
              displayType="text"
              thousandSeparator=" "
              decimalSeparator=","
            />
          </ReactTooltip>
        </>
      )}
    />
  );
}
