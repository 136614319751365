import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const productsAPIs = {
  addProduct: (reportId, body) =>
    request(`/api/reports/${reportId}/products`, {
      method: 'POST',
      body,
    }),
  deleteProduct: (reportId, productId) =>
    request(`/api/reports/${reportId}/products/${productId}`, {
      method: 'DELETE',
    }),
  addCorrectPriceRequest: (body) =>
    request(`/api/admin/product_requests`, {
      method: 'POST',
      body,
    }),
  reloadProduct: (report_id, product_id) =>
    request(`/api/reports/restart/${report_id}/products/${product_id}`, {
      method: 'PATCH',
    }),
  addSourceToProduct: (product_id, body) =>
    request(`/api/products/${product_id}/sources`, {
      method: 'POST',
      body: body,
    }),
};

export const newProductsAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    updateProduct: builder.mutation({
      query: ({ report_id, product_id, body }) => ({
        url: `/api/reports/${report_id}/products/${product_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при обновлении товара');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    addProduct: builder.mutation({
      query: ({ report_id, body }) => ({
        url: `/api/reports/${report_id}/products`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при добавлении товара');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    deleteProduct: builder.mutation({
      query: ({ report_id, product_id }) => ({
        url: `/api/reports/${report_id}/products/${product_id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении товара');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    deleteProductSource: builder.mutation({
      query: ({ report_id, product_id, sources }) => ({
        url: `/api/reports/${report_id}/products/${product_id}`,
        method: 'PATCH',
        body: {
          sources,
        },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении источника');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    reloadProduct: builder.mutation({
      query: ({ report_id, product_id }) => ({
        url: `/api/reports/restart/${report_id}/products/${product_id}`,
        method: 'PATCH',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при попытке обновить поиск источника');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    addSourceToProduct: builder.mutation({
      query: ({ product_id, body }) => ({
        url: `/api/products/${product_id}/sources`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при добавлении источника');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    cancelProductSearch: builder.mutation({
      query: (product_id) => ({
        url: `/api/communications/cancel_product/${product_id}`,
        method: 'POST',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при остановить поиск источника');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    getProductBlockedHosts: builder.query({
      query: ({ report_id, product_id }) => ({
        url: `/api/report_locks/${report_id}/deleted_host/${product_id}`,
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении заблокированных источников');
      },
      providesTags: [{ type: 'BlockedHosts' }],
    }),
    restoreDeletedProductBlockedHost: builder.mutation({
      query: ({ report_id, product_id, host }) => ({
        url: `/api/report_locks/${report_id}/deleted_host/${product_id}`,
        method: 'PATCH',
        body: {
          host,
        },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при попытке восстановления источника');
      },
      invalidatesTags: [{ type: 'BlockedHosts' }],
    }),
    selectSource: builder.mutation({
      query: ({ report_id, body }) => ({
        url: `/api/reports/${report_id}/save_selected_sources`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при выборе источника');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    saveSource: builder.mutation({
      query: ({ report_id, product_id, body }) => ({
        url: `/api/reports/${report_id}/products/${product_id}`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при сохранении источника');
      },
    }),
    blockSource: builder.mutation({
      query: ({ report_id, product_id, url }) => ({
        url: `/api/report_locks/${report_id}/deleted_host/${product_id}`,
        method: 'POST',
        body: {
          url,
        },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при блокировке источника');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }, { type: 'BlockedHosts' }],
    }),
    addCorrectPrice: builder.mutation({
      query: (body) => ({
        url: '/api/admin/product_requests',
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при корректировке цены источника');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    getSourceImage: builder.query({
      query: ({ project_id, product_id, url_source }) => ({
        url: `/api/screenshots/get_link/${project_id}/${product_id}?url=${url_source}`,
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении изображения');
      },
    }),
  }),
});

export const {
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useReloadProductMutation,
  useCancelProductSearchMutation,
  useGetProductBlockedHostsQuery,
  useRestoreDeletedProductBlockedHostMutation,
  useAddSourceToProductMutation,
  useSelectSourceMutation,
  useSaveSourceMutation,
  useAddCorrectPriceMutation,
  useBlockSourceMutation,
  useDeleteProductSourceMutation,
  useGetSourceImageQuery
} = newProductsAPIs;
