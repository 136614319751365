import { useState, useMemo } from 'react';

export const useSortableData = (items, config = null, isReversed = false) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = items ? [...items] : [];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const getSortIconColor = (key, order) => {
    // if (sortConfig === null || sortConfig.key !== key) {
    //     return '#666666'
    // }
    if (sortConfig?.key === key) {
        return order === sortConfig?.direction ? '#666666' : '#999999'
    }
    return '#666666'
  }

  const sortingLabel = (label, key) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px] hover:tw-text-[#999999] tw-text-[#666666]">
        <p className='hover:tw-text-[#999999] tw-transition-colors'>{label}</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M0.708334 6.70003L3.375 6.70003L3.375 12.6467L4.715 12.6667L4.715 6.70003L7.375 6.70003L4.04167 3.3667L0.708334 6.70003Z"
            fill={getSortIconColor(key, 'asc')}
          />
          <path
            d="M15.2917 9.29997L12.625 9.29997L12.625 3.3533L11.285 3.3333L11.285 9.29997L8.625 9.29997L11.9583 12.6333L15.2917 9.29997Z"
            fill={getSortIconColor(key, 'desc')}
          />
        </svg>
      </div>
    );
  };

  const requestSort = (key) => {
    let direction = isReversed ? 'desc' : 'asc';
    if (sortConfig?.key === key && sortConfig?.direction === (isReversed ? 'desc' : 'asc')) {
      direction = isReversed ? 'asc' : 'desc';   
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortingLabel, sortConfig };
};
