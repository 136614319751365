import { Container } from 'react-bootstrap';
import { ReactComponent as PhoneIcon } from '../../icons/phoneIcon.svg';
import { ReactComponent as MailIcon } from '../../icons/mailIcon.svg';
import { ReactComponent as WhiteLogo } from '../../icons/whiteLogo.svg';
import { ReactComponent as DepartmentLogo } from '../../icons/departmentLogo.svg';
import { ReactComponent as MobileWhiteLogo } from '../../icons/mobileLogo.svg';
import { ReactComponent as MobileDepartmentLogo } from '../../icons/mobileDepartament.svg';
import classes from './footer.module.sass';
import React, { useCallback } from 'react';
import { ShowQR } from '../../pages/landing/components/modal/ShowQR';
import { useDialog } from '../../providers/dialog.provider';
import classNames from 'classnames';
import { withAdaptive } from '../../utils/hocs/withAdaptive';

const MobileLogos = () => (
  <>
    <MobileWhiteLogo />
    <div className="separator"></div>
    <MobileDepartmentLogo />
  </>
);

const DesktopLogos = () => (
  <>
    <WhiteLogo />
    <div className="separator"></div>
    <DepartmentLogo />
  </>
);

function DesktopFooter() {
  const dialog = useDialog();

  const showLargerQR = useCallback(() => {
    dialog.open(<ShowQR />)
  }, [dialog]);

  const currentYear = new Date().getFullYear();

  return (
      <footer className={classes.footer} style={{
        backgroundImage: 'url(/images/background/footerBg.png)',
        backgroundRepeat: 'no-repeat'
      }}>
        <Container className={classes.container}>
          <div className="tw-flex tw-w-full tw-justify-between">
            <div className="tw-flex tw-gap-[10px] tw-mr-3">
              <DesktopLogos />
            </div>
            <div className="tw-flex tw-gap-[20px]">
              <div>
                <a href='tel:8(843)236-60-01' className="tw-flex tw-items-center">
                  <PhoneIcon className="tw-mr-1" />
                  <p className="tw-text-[#FFF] tw-text-sm tw-font-normal tw-whitespace-nowrap md:tw-text-xs">8 (843)
                    236-60-01</p>
                </a>
                <a href='mailto:predictiveanalytics@yanao.ru' className="tw-flex tw-items-center">
                  <MailIcon className="tw-mr-1" />
                  <p className="tw-text-[#FFF] tw-text-sm tw-font-normal md:tw-text-xs">predictiveanalytics@yanao.ru</p>
                </a>
              </div>
              <img src='/images/telegram.png' width={43} height={43} className="tw-cursor-pointer"
                   onClick={showLargerQR} alt='telegramQR' />
              <p className="tw-text-[#FFF] tw-text-xs tw-font-normal tw-flex-col tw-flex tw-flex-wrap tw-gap-[3px]">
                <span className="tw-whitespace-nowrap md:tw-text-xs">© 2020-{currentYear} </span>
                <span className="tw-whitespace-nowrap md:tw-text-xs">Сервис Предиктивной Аналитики</span>
              </p>
            </div>
          </div>
        </Container>
      </footer>
  )
}

function MobileFooter() {
  const dialog = useDialog();

  const showLargerQR = useCallback(() => {
    dialog.open(<ShowQR />);
  }, [dialog]);

  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer} style={{
      backgroundImage: 'url(/images/background/footerBg.png)',
      backgroundRepeat: 'no-repeat'
    }}>
      <Container className={classes.container}>
        <div className={classes.contentFooter}>
          <div className="tw-flex tw-gap-[10px] tw-mr-3">
            <MobileLogos />
          </div>
          <div className={classes.contentFooter}>
            <div>
              <a href='tel:8(843)236-60-01' className="tw-flex tw-items-center">
                <PhoneIcon className="tw-mr-1" />
                <p className="tw-text-[#FFF] tw-text-sm tw-font-normal tw-whitespace-nowrap md:tw-text-xs">8 (843)
                  236-60-01</p>
              </a>
              <a href='mailto:predictiveanalytics@yanao.ru' className="tw-flex tw-items-center">
                <MailIcon className="tw-mr-1" />
                <p className="tw-text-[#FFF] tw-text-sm tw-font-normal md:tw-text-xs">predictiveanalytics@yanao.ru</p>
              </a>
            </div>
            <img src='/images/telegram.png' width={43} height={43} className="tw-cursor-pointer" onClick={showLargerQR}
                 alt='telegramQR' />
            <p className={classNames(
              "tw-text-[#FFF] tw-text-xs tw-font-normal tw-gap-0 tw-flex-col",
              classes.contentFooter)}>
              <span className="tw-whitespace-nowrap md:tw-text-xs">© 2020-{currentYear} </span>
              <span className="tw-whitespace-nowrap md:tw-text-xs">Сервис Предиктивной Аналитики</span>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}


export const Footer = withAdaptive({
  mobile: MobileFooter,
  tablet: MobileFooter,
  desktop: DesktopFooter,
  default: DesktopFooter,
});