import { Dialog, Tab } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { SearchInput } from '../../input/search';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { organizationsAPIs } from '../../../services';
import { createErrorNotification } from '../../../utils/notifications';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { ProjectResults } from './ProjectResults';
import { ArchivedProductsResult } from './ArchivedProductsResult';

export const SearchResults = ({ data, searchText, organizationId }) => {
  const dialog = useDialog();
  const [rows, setRows] = useState(data);
  const [searchQuery, setSearchQuery] = useState(searchText);
  const [currentTab, setCurrentTab] = useState('1');
  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };
  const fetchResults = useCallback(
    async (searchText) => {
      await organizationsAPIs
        .searchInOrganization(organizationId, searchText)
        .then((res) => {
          setRows(res);
        })
        .catch((e) => {
          console.error(e);
          createErrorNotification('Что-то пошло не так');
        });
    },
    [organizationId]
  );
  useEffect(() => {
    if (searchQuery !== '' && organizationId) {
      fetchResults(searchQuery);
    }
    return () => {
      setRows([]);
    };
  }, [searchQuery, fetchResults, organizationId]);

  const handleChangeSearchQuery = (value) => {
    setSearchQuery(value)
  }
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '70%',
          height: '90%',
        },
        '& .MuiInputBase-root': {
          backgroundColor: '#F7F7F7',
        },
        fieldset: {
          borderColor: 'transparent !important',
        },
      }}
    >
      <div className="tw-bg-white tw-rounded-lg tw-pt-6 tw-relative tw-px-8 tw-flex tw-flex-col tw-pb-1 tw-overflow-y-hidden">
        <CloseIcon
          className="tw-absolute tw-top-[14px] tw-right-[14px] tw-w-6 tw-h-6 tw-cursor-pointer"
          onClick={() => dialog.close()}
        />
        <h2 className="tw-text-[#191919 tw-font-medium tw-leading-normal tw-text-lg tw-mb-6">Результаты поиска</h2>
        <Form onSubmit={() => {}} initialValues={{ header_search: searchText }}>
          {() => (
            <SearchInput
              className={`tw-mb-8`}
              onSearchChange={handleChangeSearchQuery}
              name="header_search"
              placeholder="Поиск"
              type="text"
            />
          )}
        </Form>
        <TabContext value={currentTab}>
          <div className="tw-border-b-2 tw-border-[#DDD] ">
            <TabList onChange={handleTabChange}>
              <Tab
                value={'1'}
                disableRipple
                label="Проекты"
                className="tw-normal-case tw-font-medium tw-pl-[20px] tw-pt-[8px] tw-pr-[19px] tw-pb-[7px] tw-bg-white"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#fff !important',
                  },
                }}
              />
              {/* <Tab
                value={'2'}
                disableRipple
                label="Архивные продукты"
                className="tw-normal-case tw-font-medium tw-pl-[20px] tw-pt-[8px] tw-pr-[19px] tw-pb-[7px] tw-bg-white"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#fff !important',
                  },
                }}
              /> */}
            </TabList>
          </div>
          <TabPanel className="tw-p-0 tw-overflow-y-auto" value="1">
            {rows?.projects?.length !== 0 ? <ProjectResults data={rows.projects} /> : <p>Нет результатов</p>}
          </TabPanel>
          <TabPanel className="tw-p-0 tw-overflow-y-auto" value="2">
            {rows?.archived_products?.length !== 0 ? (
              <ArchivedProductsResult data={rows.archived_products} />
            ) : (
              <p>Нет результатов</p>
            )}
          </TabPanel>
        </TabContext>
      </div>
    </Dialog>
  );
};
