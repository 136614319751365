import { Skeleton } from '@mui/material';
import Loader from '../../../../components/loader';
import styles from './productSkeleton.module.sass';
import Button from '../../../../components/button';

export const ProductTableSkeleton = ({ number, handleStopSearch }) => {
  return (
    <tr className="tw-bg-white tw-border-b tw-border-t tw-border-[#EEEEEE]">
      <td className="tw-pl-3">
        {number ? (
          <>
            <p className="tw-min-w-[30px] tw-text-[#7F7F7F] tw-text-xs tw-leading-[16px]">{number}</p>
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" width={15} height={16} className="tw-rounded-[4px]" />
          </>
        )}
      </td>
      <td className="tw-pr-[10px] tw-py-7 tw-max-w-[343px]">
        <Skeleton variant="rectangular" width={330} height={14} className="tw-rounded-lg" />
      </td>
      <td className="tw-relative">
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-absolute tw-top-[13px] tw-w-[160px] tw-left-[-83px]">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
            <Loader className={`${styles.loaderTable} tw-w-fit`} />
            <p className="tw-text-[#191919] tw-text-xs tw-font-medium tw-leading-4">Ведется поиск товара</p>
          </div>
          <Button
            variant="text"
            onClick={handleStopSearch}
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-semibold"
          >
            Остановить поиск
          </Button>
        </div>
      </td>
      <td>
        <div className="tw-flex tw-flex-row tw-justify-center tw-pl-[22px]">
          <Skeleton variant="rectangular" width={63} height={14} className="tw-rounded-lg " />
        </div>
      </td>
      <td>
        <div className="tw-flex tw-flex-row tw-justify-center">
          <Skeleton variant="rectangular" width={63} height={14} className="tw-rounded-lg " />
        </div>
      </td>
      <td className="tw-pl-[14px]">
        <div className="tw-flex tw-flex-row tw-gap-4 tw-items-baseline">
          <Skeleton variant="rectangular" width={32} height={28} className="tw-rounded-lg " />
          <Skeleton variant="rectangular" width={24} height={24} className="tw-rounded-lg " />
        </div>
      </td>
    </tr>
  );
};
