import { useState } from 'react';
import { createErrorNotification, createNotification } from '../utils/notifications';
import { useEffect } from 'react';
import { useRef } from 'react';

export const usePooling = (fetchFn, delay, predicate) => {
  const [data, setData] = useState(null);
  const abortController = useRef(new AbortController());
  const [isUpdating, setIsUpdating] = useState(false);
  
  const startUpdate = () => {
    setIsUpdating(true);
  };

  useEffect(() => {
    if (isUpdating) {
      const fetchData = async () => {
        try {
          abortController.current.abort();

          abortController.current = new AbortController();

          const res = await fetchFn(abortController.current.signal);

          if (predicate && predicate(res)) {
            clearInterval(intervalId);
            createNotification('Успешно завершено');
            setIsUpdating(false);
            return;
          }

          setData(res);
        } catch (error) {
          if (!abortController.current.signal.aborted) {
            clearInterval(intervalId);
            console.error('Error fetching data:', error.message);
            setIsUpdating(false);
            createErrorNotification('Что-то пошло не так');
          }
        }
      };

      const intervalId = setInterval(fetchData, delay);

      return () => {
        clearInterval(intervalId);
        abortController.current.abort();
      };
    }
  }, [fetchFn, delay, predicate, isUpdating]);

  return { data, startUpdate, isUpdating };
};
