import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const adminAPIs = {
  addUser: (body) =>
    request('/api/admin/users', {
      method: 'POST',
      body: body,
    }),
  editUser: (body) =>
    request('/api/admin/users', {
      method: 'PATCH',
      body: body,
    }),
  deleteUser: (id) =>
    request(`/api/admin/users/${id}`, {
      method: 'DELETE',
    }),
  getUsers: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/users?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getOrganization: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/organizations?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  addOrganization: (body) =>
    request('/api/admin/organizations', {
      method: 'POST',
      body: body,
    }),
  deleteOrganization: (id) =>
    request(`/api/admin/organizations/${id}`, {
      method: 'DELETE',
    }),
  getOrganizationAccesses: (id) =>
    request(`/api/admin/accesses?organization_id=${id}`, {
      method: 'GET',
    }),
  deleteAccess: (id) =>
    request(`/api/admin/accesses/${id}`, {
      method: 'DELETE',
    }),
  createAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'POST',
      body: body,
    }),
  updateAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'PATCH',
      body: body,
    }),
  getPriceRequests: (page_size, page_num, query) =>
    request(`/api/admin/product_requests?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getPriceRequest: (id) =>
    request(`/api/admin/product_requests/${id}`, {
      method: 'GET',
    }),
  getSupportRequests: (page_size, page_num, query) =>
    request(`/api/admin/tech_supports/technical_support?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getSupportRequest: (id) =>
    request(`/api/admin/tech_supports/technical_support/${id}`, {
      method: 'GET',
    }),
  addSupportRequest: (body) =>
    request('/api/admin/tech_supports/technical_support', {
      method: 'POST',
      body: body,
    }),
  loginAs: (id) =>
    request('/api/login/login_as', {
      method: 'POST',
      body: {
        user_id: id,
      },
    }),
  getTechStatus: () => request('/api/admin/tech_supports/technical_support/get_status_problem', { method: 'GET' }),
  setTechStatus: (status) =>
    request('/api/admin/tech_supports/technical_support/set_status_problem', {
      method: 'POST',
      body: {
        status: status,
      },
    }),
  sendUserData: (body) =>
    request('/api/admin/users/send_login', {
      method: 'POST',
      body: body,
    }),
  adminProjectReload: (id) =>
    request(`/api/communications/refind/admin/${id}`, {
      method: 'GET',
    }),
};

//TODO: переписать запросы AdminAPI на RTK
export const newAdminAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: ({ page_size, page_num, query, sort_by, sort_order }) => ({
        url: `/api/admin/organizations/get_info_messages`,
        params: { page_size, page_num, query, sort_by, sort_order },
        method: 'GET',
      }),
      providesTags: [{type: 'ListNotifications'}]
    }),
    createNotifications: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/api/admin/organizations/info_message',
        body: body,
      }),
      invalidatesTags: [{type: 'ListNotifications'}, {type: 'NotificationsForUser'}]
    }),
    removeNotification: builder.mutation({
      query: (message_id) => ({
        method: 'DELETE',
        url: `/api/admin/organizations/delete_info_message/${message_id}`,
      }),
      invalidatesTags: [{type: 'ListNotifications'}, {type: 'NotificationsForUser'}]
    }),
    editNotification: builder.mutation({
      query: ({ message_id, body }) => ({
        method: 'PUT',
        url: `/api/admin/organizations/edit_info_message/${message_id}`,
        body: body,
      }),
      invalidatesTags: [{type: 'ListNotifications'}, {type: 'DetailNotification'}, {type: 'NotificationsForUser'}]
    }),
    getDetailNotification: builder.query({
      query: (message_id) => ({
        method: 'GET',
        url: `/api/admin/organizations/get_info_message/${message_id}`,
      }),
      providesTags: [{type: 'DetailNotification'}]
    }),
    getAutoScreenshotCleanupStatus: builder.query({
      query: () => ({
        method: 'GET',
        url: '/api/admin/storage/get_cleanup_status',
      }),
    }),
    toggleAutoScreenshotCleanupStatus: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/api/admin/storage/toggle-cleanup',
        body: body,
      }),
    }),
    getGloballyBlockedSources: builder.query({
      query: ({ page_size, page_num, query }) => ({
        method: 'GET',
        url: '/api/admin/blocked_domains',
        params: { page_size, page_num, query },
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении заблокированных источников');
      },
      providesTags: [{ type: 'GloballyBlockedSources' }],
    }),
    globallyBlockSource: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/api/admin/blocked_domains',
        body,
      }),
      invalidatesTags: ['GloballyBlockedSources'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при добавлении источника');
      },
    }),
    globallyUnblockSource: builder.mutation({
      query: (source_id) => ({
        method: 'DELETE',
        url: `/api/admin/blocked_domains/${source_id}`,
      }),
      invalidatesTags: ['GloballyBlockedSources'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении заблокированного источника');
      },
    }),
    updateGloballyBlockedSource: builder.mutation({
      query: ({ source_id, body }) => ({
        url: `/api/admin/blocked_domains/${source_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GloballyBlockedSources'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при редактировании заблокированного источника');
      },
    }),
    markNotificationsAsViewed: builder.mutation({
      query: () => ({
        method: 'POST',
        url: '/api/admin/organizations/mark_all_info_messages',
      }),
      invalidatesTags: [{type: 'NotificationsForUser'}],
    }),
    getNotificationsForUsers: builder.query({
      query: ({ page_size, page_num, query }) => ({
        url: '/api/admin/organizations/get_user_info_messages',
        method: 'GET',
        params: { page_size, page_num, query },
      }),
      providesTags: [{type: 'NotificationsForUser'}]
    }),
    getStorageUsers: builder.query({
      query: ({page_size, page_num, query, sort_by_project_storage, sort_by_total_storage}) => ({
        url: '/api/admin/storage/users',
        method: 'GET',
        params: { page_size, page_num, query, sort_by_project_storage, sort_by_total_storage },
      }),
      providesTags: [{type: 'UsersStorageScreenshots'}]
    }),
    getStorageOrganizations: builder.query({
      query: ({page_size, page_num, query, sort_by_project_storage, sort_by_total_storage}) => ({
        url: '/api/admin/storage/organizations',
        method: 'GET',
        params: { page_size, page_num, query, sort_by_project_storage, sort_by_total_storage },
      }),
      providesTags: [{type: 'OrganizationsStorageScreenshots'}]
    })
  }),
});

export const {
  useGetAllNotificationsQuery,
  useCreateNotificationsMutation,
  useRemoveNotificationMutation,
  useEditNotificationMutation,
  useGetDetailNotificationQuery,
  useGetAutoScreenshotCleanupStatusQuery,
  useGetGloballyBlockedSourcesQuery,
  useGloballyBlockSourceMutation,
  useGloballyUnblockSourceMutation,
  useUpdateGloballyBlockedSourceMutation,
  useToggleAutoScreenshotCleanupStatusMutation,
  useMarkNotificationsAsViewedMutation,
  useGetNotificationsForUsersQuery,
  useGetStorageUsersQuery,
  useGetStorageOrganizationsQuery,
} = newAdminAPIs;