import { request } from '../utils/request';

export const suppliersAPIs = {
  getSuppliers: (organizationId, limit = 10, page_num = 1, query = '') =>
    request(`/api/organizations/${organizationId}/suppliers?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getCategories: (isGoodSupplier) =>
    request(`/api/organizations/suppliers_categories${isGoodSupplier ? '?is_conscientious=true' : ''}`, {
      method: 'GET',
    }),
  addSupplier: (organizationId, body) =>
    request(`/api/organizations/${organizationId}/suppliers`, {
      method: 'POST',
      body,
    }),
};
