import GeneralLayout from '../../components/general-layout';
import Button from '../../components/button';
import { CrudTable } from '../../components/table/CrudTable';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import moment from 'moment/moment';
import { useDialog } from '../../providers/dialog.provider';
import { ModalNotification } from './ModalNotification';
import {
  useCreateNotificationsMutation, useEditNotificationMutation,
  useGetAllNotificationsQuery,
  useRemoveNotificationMutation
} from '../../services/adminAPIs';
import { MenuItem } from '@mui/material';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { ReactComponent as CopyIcon } from '../../icons/copy.svg';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import Loader from '../../components/loader';
import { ModalCopyConfirm } from './modalCopyConfirm/ModalCopyConfirm';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/table/pagination';
import { usePaginationParams } from '../../hooks/usePaginationParams';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import { useSortableData } from '../../hooks/useSortableData';

export const Notifications = () => {
  const dialog = useDialog();
  const { requestSort, sortingLabel, sortConfig } = useSortableData([], null, true);
  const { page_size, page_num, query, handleLimitChange, handleQueryChange, goToPage } = usePaginationParams();
  const { data, isLoading} = useGetAllNotificationsQuery(
    {
    page_size,
    page_num,
    query,
    sort_by: sortConfig?.key,
    sort_order: sortConfig?.direction
    });
  const [createNotificationMutation] = useCreateNotificationsMutation();
  const [editNotificationMutation] = useEditNotificationMutation();
  const [removeNotificationMutation, {isLoading: loaderRemovingNotification}] = useRemoveNotificationMutation();


  const handleCreateNotification = async (formData) => {
    const body = {
      end_date: formData.end_date,
      end_time: formData.end_time,
      start_date: formData.start_date,
      start_time: formData.start_time,
      text: formData.text,
    };

    try {
      await createNotificationMutation(body).unwrap();
      createNotification('Оповещение создано');
      dialog.close();
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };
  const handleEditNotification = async (message_id, formData) => {
    const body = {
      end_date: formData.end_date,
      end_time: formData.end_time,
      start_date: formData.start_date,
      start_time: formData.start_time,
      text: formData.text,
    };

    try {
      await editNotificationMutation({ message_id, body }).unwrap();
      createNotification('Оповещение отредактировано');
      dialog.close();
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };
  const handleDeleteNotification = async (message_id) => {
    try {
      await removeNotificationMutation(message_id).unwrap();
      createNotification('Оповещение удалено')
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };

  const openCreateNotification = () => {
    dialog.open(
      <ModalNotification
        title="Новое оповещение"
        handleSubmit={handleCreateNotification}
      />
    )
  }
  const openEditNotification = (entity) => {
    dialog.open(
      <ModalNotification
        initialValues={entity}
        btnSubmit="Редактировать"
        title="Редактирование оповещения"
        handleSubmit={(formData) => handleEditNotification(entity?.id, formData)}
      />
    )
  }

  const openCopyNotification = (entity) => {
    dialog.open(
      <ModalNotification
        initialValues={entity}
        btnSubmit="Создать"
        title="Копирование оповещения"
        handleSubmit={handleCreateNotification}
      />
    )
  }

  const modalCopyNotification = (entity) => {
    dialog.open(
      <ModalCopyConfirm
        title="Вы действительно хотите копировать оповещение?"
        btnProp="Копировать"
        handleAgree={() => openCopyNotification(entity)}
      />
    )
  }

  const deleteNotification = (message_id) => {
    dialog.open(
      <ConfirmModal
        title="Вы действительно хотите удалить оповещение?"
        onAgree={() => handleDeleteNotification(message_id)}
      />
    )
  }

  const columns = {
    created_at: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {sortingLabel('Создано', 'created_at')}
          </div>
        );
      },
      renderBodyCell: (row) => {
        return (
          <span className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {moment(row?.created_at).format('DD.MM.YYYY HH:mm')}
          </span>
        );
      },
    },
    text: {
      label: 'Текст',
      renderBodyCell: (row) => {
        return (
          <Link to={`/notifications/${row.id}`} className='tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-truncate tw-max-w-[209px] tw-min-w-0 tw-block tw-overflow-hidden tw-whitespace-nowrap tw-cursor-pointer'>
            {row?.text}
          </Link>
        );
      },
    },
    start_date: {
      label: 'Начало',
      renderBodyCell: (row) => {
        return (
          <span>
            {row?.start_date && row?.start_time
              ? moment(`${row.start_date} ${row.start_time}`).format('DD.MM.YYYY HH:mm')
              : 'Не указано'}
          </span>
        )
      },
    },
    end_date: {
      label: 'Завершение',
      renderBodyCell: (row) => {
        return (
          <span>
            {row?.end_date && row?.end_time
              ? moment(`${row.end_date} ${row.end_time}`).format('DD.MM.YYYY HH:mm')
              : 'Не указано'}
          </span>);
      },
    },
    author: {
      label: 'Автор',
      renderBodyCell: (row) => {
        return <>{row?.author}</>;
      },
    },
  };

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex">
        <MenuItem
          className="hover:tw-bg-transparent hover:tw-text-[#0844B4]"
          onClick={() => modalCopyNotification(entity)}
          disableRipple
        >
          <CopyIcon className="tw-mr-2" />
        </MenuItem>
          <MenuItem
            className="hover:tw-bg-transparent hover:tw-text-[#0844B4]"
            onClick={() => openEditNotification(entity)}
            disableRipple
          >
            <EditIcon className="tw-mr-2" />
          </MenuItem>
        {loaderRemovingNotification ? (<><Loader/></>) : (
          <MenuItem
            className="hover:tw-bg-transparent hover:tw-text-[#0844B4]"
            onClick={() => deleteNotification(entity?.id)}
            disableRipple
          >
            <DeleteIcon className="tw-mr-1" />
          </MenuItem>
        )}

      </div>
    );
  };

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col">
        <h2
          className="tw-text-[#191919] tw-text-[24px] sm:tw-text-[32px] tw-leading-[28px] sm:tw-leading-[38px] tw-mb-6 md:tw-mb-8">
          Оповещения
        </h2>
        <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
          <header className="tw-flex tw-items-center tw-justify-between tw-mb-5">
            <div className="tw-flex tw-flex-row tw-gap-6">
              <Form onSubmit={() => {
              }}>
                {() => (
                  <form>
                    <SearchInput
                      name="search"
                      placeholder="Найти оповещение"
                      type="text"
                      onSearchChange={handleQueryChange}
                    />
                  </form>
                )}
              </Form>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
              <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={openCreateNotification}  >
                <AddIcon className="tw-mr-[4px]" />
                Создать оповещение
              </Button>
            </div>
          </header>
          {!isLoading ? (
            <CrudTable rows={data?.data || []} entityColumns={columns} crudButtons={crudButtons}  />
          ) : (
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
              <div
                className="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              />
            </div>
          )}
        </div>
        <Pagination
          rowsPerPage={page_size}
          count={data?.count}
          page={data?.page_num}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </GeneralLayout>
  );
};