import { Form } from 'react-final-form';
import GeneralLayout from '../../components/general-layout';
import { SearchInput } from '../../components/input/search';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import Table from '../../components/table';
import { adminAPIs } from '../../services/adminAPIs';
import { createErrorNotification } from '../../utils/notifications';
import { usePagination } from '../../components/table/usePagination';
import { Pagination } from '../../components/table/pagination';
import { ReactComponent as CheckIcon } from '../../icons/greenCheckIcon.svg';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { ExportRequests } from './modals/ExportRequests';
import Button from '../../components/button';
import { ReactComponent as DownloadIcon } from '../../icons/downloadIcon.svg';
import { useSortableData } from '../../hooks/useSortableData';

export const reportStatus = {
  completed: (
    <>
      <Tooltip title="Заявка выполнена">
        <CheckIcon />
      </Tooltip>
    </>
  ),
};

const correctPriority = {
  low: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#EEFCEE] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#49CA4E] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>Низкий</p>
      </div>
    </>
  ),
  medium: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFF8EC] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#DD9F00] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>Средний</p>
      </div>
    </>
  ),
  high: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFEAEA] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#FF4343] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>Высокий</p>
      </div>
    </>
  ),
};

const correctStatus = {
  completed: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#EEFCEE] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#49CA4E] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>Завершено</p>
      </div>
    </>
  ),
  in_queue: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFF8EC] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#DD9F00] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>В работе</p>
      </div>
    </>
  ),
  accepted: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#FFEAEA] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#FF4343] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
        <p>Принято</p>
      </div>
    </>
  ),
};
export const SupportRequests = () => {
  const [data, setData] = useState([]);
  const dialog = useDialog();
  const [searchText, setSearchText] = useState('');
  const { requestSort, sortingLabel, sortConfig } = useSortableData([], null, true);

  const getCorrectDate = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY');
    } else return '-';
  };

  const columns = {
    id: {
      label: 'ID',
      renderBodyCell: (value) => {
        return (
          <>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
              <Link to={`/support-requests/${value?.id}`} className="tw-text-[#0A50D4]">
                {value?.id}
              </Link>
              {reportStatus[value?.status] ?? <></>}
            </div>
          </>
        );
      },
    },
    organization: {
      label: 'Компания',
    },
    problem: {
      label: 'Проблема',
      renderBodyCell: (value) => {
        return (
          <p className="tw-truncate tw-max-w-[261px] tw-text-[#191919] tw-text-sm tw-leading-[18px]">
            {value?.problem}
          </p>
        );
      },
    },
    created_at: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {sortingLabel('Дата создания', 'created_at')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <>{getCorrectDate(value?.created_at)}</>;
      },
    },
    status: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('status')}>
            {sortingLabel('Статус', 'status')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <>{correctStatus[value?.status] ?? <p>Нет</p>}</>;
      },
    },
    criticality: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('criticality')}>
            {sortingLabel('Приоритет', 'criticality')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return (
          <>
            {correctPriority[value?.criticality] ?? (
              <div className="tw-flex tw-flex-row tw-items-center tw-bg-[#EEEEEE] tw-rounded-[9px] tw-py-[5px] tw-px-[10px] tw-text-[#7F7F7F] tw-text-xs tw-leading-4 tw-font-medium tw-text-center tw-w-fit">
                <p>Не указан</p>
              </div>
            )}
          </>
        );
      },
    },
  };

  const fetchData = useCallback(
    async (page_size, page_num, query) => {
      const sort_query = sortConfig?.key ? `&sort_by=${sortConfig?.key}&order_by=${sortConfig?.direction}` : '';
      const full_query = query + sort_query;
      const res = await adminAPIs.getSupportRequests(page_size, page_num, query).catch((err) => {
        console.error(err);
        createErrorNotification('Ошибка получения записей');
      });
      return res;
    },
    [sortConfig]
  );

  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    setPageSize,
    pageSize,
    handleChangeQuery,
  } = usePagination(fetchData);

  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);

  useEffect(() => {
    const handleQuery = () => {
      const sort_query = sortConfig?.key ? `&sort_by=${sortConfig?.key}&sort_order=${sortConfig?.direction}` : '';
      const full_query = searchText + sort_query;
      handleChangeQuery(full_query);
    };
    handleQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig]);

  const handleSearch = (value) => {
    setSearchText(value);
    handleChangeQuery(value);
  };

  const showExportModal = useCallback(() => {
    dialog.open(<ExportRequests />);
  }, [dialog]);
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col">
        <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px] tw-mb-8">
          Запрос в тех. поддержку
        </h2>
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg">
          <div className="tw-flex tw-flex-row tw-justify-between tw-pt-[25px] tw-px-6 tw-mb-4">
            <Form onSubmit={() => {}}>
              {() => (
                <form>
                  <SearchInput name="search" placeholder="Найти" type="text" onSearchChange={handleSearch} />
                </form>
              )}
            </Form>
            <Button
              className="tw-w-fit tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0A50D4] tw-p-0"
              variant="text"
              onClick={() => showExportModal()}
            >
              <DownloadIcon className="tw-mr-2" />
              Выгрузить отчёт
            </Button>
          </div>
          <div className="tw-px-2">
            <Table rows={data} columns={columns} />
          </div>
        </div>
        <Pagination
          rowsPerPage={pageSize}
          count={total}
          page={currentPage}
          onPageChange={goToPage}
          onLimitChange={setPageSize}
        />
      </div>
    </GeneralLayout>
  );
};
