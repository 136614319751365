export const placeholderImages = Object.freeze({
    'Автомобили': '/images/placeholders/automobile.png',
    'Автотовары': '/images/placeholders/autoparts.png',
    'Аксессуары': '/images/placeholders/accessories.png',
    'Антиквариат и коллекционирование':'/images/placeholders/antiq.png',
    'Аптека': '/images/placeholders/drag.png',
    'Аудио и видео  ': '/images/placeholders/audio.png',
    'Бытовая техника': '/images/placeholders/houseTech.png',
    'Бытовая химия и гигиена': '/images/placeholders/gigiena.png',
    'Детские товары': '/images/placeholders/toys.png',
    'Дом и сад': '/images/placeholders/domSad.png',
    'Игры и консоли': '/images/placeholders/games.png',
    'Канцелярские товары': '/images/placeholders/koncelyariya.png',
    'Книги': '/images/placeholders/books.png',
    'Компьютеры': '/images/placeholders/comp.png',
    'Красота и здоровье': '/images/placeholders/beauty.png',
    'Мебель':'/images/placeholders/mebel.png',
    'Оборудование': '/images/placeholders/oborudovanie.png',
    'Обувь':'/images/placeholders/shoes.png',
    'Одежда': '/images/placeholders/odejda.png',
    'Оптика': '/images/placeholders/optica.png',
    'Продукты питания': '/images/placeholders/food.png',
    'Спорт и отдых': '/images/placeholders/sport.png',
    'Строительство и ремонт': '/images/placeholders/remont.png',
    'Товары для животных':'/images/placeholders/forAnimal.png',
    'Туризм, охота, рыбалка': '/images/placeholders/turism.png',
    'Хобби и творчество': '/images/placeholders/hobby.png',
    'Хозяйственный инвентарь': '/images/hozInventar.png',
    'Цифровые товары': '/images/placeholders/digitalProducts.png',
    'Электроника': '/images/placeholders/electronica.png',
    'Ювелирные украшения': '/images/placeholders/uvelirka.png',
})