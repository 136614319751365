import { Form } from 'react-final-form';
import AuthLayout from '../../components/auth-layout';
import { useCallback, useEffect, useState } from 'react';
import Input from '../../components/input';
import Button from '../../components/button';
import { ReactComponent as ArrowLeft } from '../../icons/arrowLeftIcon.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { userAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';

export const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [send, setSend] = useState(false);

  const handleResetPassword = useCallback(async (values) => {
    const { email } = values;
    const url = window.location.origin;
    await userAPIs
      .resetPassword({
        email: email,
        url: url,
      })
      .then(() => {
        localStorage.setItem('email', email);
        setSend((prev) => !prev);
        createNotification('Письмо отправляется')
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при отправке письма');
      });
  }, []);

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email !== null) {
      setStep(2);
    }
  }, [send]);
  return (
    <AuthLayout bh="white">
      {step === 1 && (
        <div className="tw-flex tw-flex-col tw-items-start">
          <h1 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-mb-2 sm:tw-mb-3">
            Восстановление пароля
          </h1>
          <p className="tw-text-[#666666] tw-leading-5 tw-font-medium tw-max-w-[285px] tw-mb-4 sm:tw-mb-5 md:tw-mb-10">
            На почту будет отправлена ссылка для восстановления пароля
          </p>
          <Form
            onSubmit={(values, form) => {
              handleResetPassword(values).then(() => form.reset());
            }}
            initialValues={{
              email: '',
            }}
          >
            {({ handleSubmit }) => (
              <>
                <div className="tw-flex tw-flex-col tw-w-full tw-gap-8 tw-mb-6 sm:tw-mb-8 md:tw-mb-6">
                  <Input name="email" placeholder="Электронная почта" />

                  <Button type="submit" className="tw-rounded-lg tw-py-[14px] tw-px-[18px]" onClick={handleSubmit}>
                    Отправить
                  </Button>
                </div>
              </>
            )}
          </Form>
          <Link to="/">
            <Button
              variant="text"
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-flex tw-flex-row tw-items-center tw-text-[#666666] tw-font-semibold tw-text-sm tw-leading-[18px]"
            >
              <ArrowLeft className="tw-rotate-180" />
              Назад
            </Button>
          </Link>
        </div>
      )}
      {step === 2 && <CheckEmail handleSendAgain={handleResetPassword} />}
    </AuthLayout>
  );
};

const CheckEmail = ({ handleSendAgain }) => {
  const email = localStorage.getItem('email');
  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handleResend = async () => {
    await handleSendAgain({email: email}).then(() => {
        setTimeLeft(120);
        
    }).catch((err) => {
        createErrorNotification('Произошла ошибка при повторной отправке письма')
        console.error(err)
    });
  };

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return (
    <>
      <div className="tw-flex tw-flex-col tw-items-start">
        <h1 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-mb-2 sm:tw-mb-3">
          Проверьте почту
        </h1>
        <p className="tw-text-[#666666] tw-leading-5 tw-font-medium tw-max-w-[415px] tw-mb-[14px]">
          На почту {email} отправлено письмо, перейдите по ссылке, чтобы создать новый пароль.
        </p>
        <p className="tw-text-[#666666] tw-leading-5 tw-font-medium tw-max-w-[415px] tw-mb-8">
          Если письмо не пришло, проверьте папку спам или нажмите «Отправить повторно» через {minutes}:{formattedSeconds}
        </p>
        <Button className="tw-rounded-lg tw-py-[14px] tw-px-[18px]" disabled={timeLeft > 0} onClick={handleResend}>
          Отправить повторно
        </Button>
      </div>
    </>
  );
};
