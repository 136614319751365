import { MenuItem, Select } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrowDownIcon.svg';
import { useCallback } from 'react';
import { useUrlState } from '../../../../hooks/useUrlState';
import { FILTER_PRESET_LABELS, FILTER_PRESETS } from '../../../../utils/constant';

const sx = {
  fieldset: {
    borderColor: 'transparent',
  },
  svg: {
    top: 'calc(50% - 0.9em)',
    transition: 'all 0.2s linear',
  },
  '&.MuiInputBase-root': {
    '& .MuiSelect-select': {
      padding: '13px 36px 13px 14px',
    },
  },
};

export const ProductFilter = () => {
  const { getQueryParam, setQueryParam } = useUrlState();

  const handleSelectFilterChange = (option) => {
    setQueryParam('filter_preset', option);
  };

  const getFilerPresetName = useCallback(() => {
    const presetName = getQueryParam('filter_preset');

    if (!presetName) {
      return FILTER_PRESET_LABELS.CREATED_DESC;
    }

    return presetName;
  }, [getQueryParam]);

  return (
    <div className="tw-flex tw-flex-row tw-gap-[25px] tw-items-center">
      <Select
        className="tw-bg-white tw-rounded-lg tw-text-sm tw-leading-[18px] tw-h-[46px]"
        IconComponent={ArrowIcon}
        sx={sx}
        defaultValue={getFilerPresetName}
        onChange={(e) => handleSelectFilterChange(e.target.value)}
      >
        {Object.entries(FILTER_PRESETS).map(([label], index) => (
          <MenuItem key={index} value={label}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
