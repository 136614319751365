import { useParams } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import { useEffect, useState } from 'react';
import { feedbackAPIs } from '../../services/feedbackAPIs';
import { createErrorNotification } from '../../utils/notifications';

export const DetailedFeedback = () => {
  const { feedback_id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchFeedback = async () => {
      await feedbackAPIs
        .getFeedback(feedback_id)
        .then((res) => setData(res))
        .catch((err) => {
          createErrorNotification('Произошла ошибка при получении данных');
          console.error(err);
        });
    };
    if (feedback_id) fetchFeedback();
  }, [feedback_id]);
  return (
    <GeneralLayout>
      <h2 className="tw-text-[32px] tw-leading-[38px] tw-text-[#191919] tw-mb-8 tw-font-semibold">Обратная связь</h2>
      <div className="tw-w-full tw-flex tw-flex-col tw-gap-5 tw-items-start tw-rounded-lg tw-bg-white tw-p-6 tw-h-[80dvh]">
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Имя</p>
          <p className="tw-text-[#0844B4] tw-font-medium">{data?.full_name}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Организация</p>
          <p className="tw-text-[#0844B4] tw-font-medium">{data?.org?.split(', ')[0]}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Email</p>
          <p className="tw-text-[#191919] tw-font-medium">{data?.email}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Оценка удобства работы с интерфейсом сервиса (юзабилити)</p>
          <p className="tw-text-[#191919] tw-font-medium">
            {data?.usability ?? 1}/10
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Оценка качества работы механизма поиска сервиса</p>
          <p className="tw-text-[#191919] tw-font-medium">
            {data?.search_quality ?? 1}/10
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Оценка качества работы модуля расчета стоимости доставки товара</p>
          <p className="tw-text-[#191919] tw-font-medium">
            {data?.calculation_quality ?? 1}/10
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Оценка скорости поиска товаров сервисом</p>
          <p className="tw-text-[#191919] tw-font-medium">
            {data?.search_speed ?? 1}/10
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-leading-[18px] tw-text-sm">
          <p className="tw-text-[#666666]">Предложения по улучшению</p>
          <p className="tw-text-[#191919] tw-max-w-[741px]">
            {data?.suggestions ?? '-'}
          </p>
        </div>
      </div>
    </GeneralLayout>
  );
};
