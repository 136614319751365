import GeneralLayout from '../../components/general-layout';
import { HardReloadProject } from './components/HardReloadProject';

import { ServerProblemsMessage } from './components/ServerProblemsMessage';

export const Settings = () => {
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col">
        <h2 className="tw-text-[#191919] tw-text-[24px] sm:tw-text-[32px] tw-leading-[28px] sm:tw-leading-[38px] tw-mb-6 md:tw-mb-8">
          Настройки
        </h2>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <ServerProblemsMessage />
          <HardReloadProject/>
        </div>
      </div>
    </GeneralLayout>
  );
};
