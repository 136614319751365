import { InputGroup } from 'react-bootstrap';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import cx from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classes from './calendar-range.module.sass';

registerLocale('ru', ru);

const getStringValue = (date) => {
  if (!date) {
    return 'дд.мм.гггг';
  }
  return format(date, 'dd MMMM yyyy', { locale: ru });
};

export default function CalendarRange({ onChange, startDate, endDate, className, placeholder }) {
  const hasValue = startDate || endDate;
  const inputValue = `${getStringValue(startDate)} - ${getStringValue(endDate)}`;
  return (
    <div className={cx(classes.root, className)}>
      <DatePicker
        locale="ru"
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        maxDate={new Date()}
        selectsRange
        customInput={
          <InputGroup className={classes.group}>
            <InputGroup.Text className={classes.value}>
              {hasValue ? inputValue : <span className={classes.placeholder}>{placeholder}</span>}
            </InputGroup.Text>
            <InputGroup.Text className={classes.append}>
              <i className="bi bi-calendar"></i>
            </InputGroup.Text>
          </InputGroup>
        }
      />
    </div>
  );
}

CalendarRange.defaultProps = {
  validate: () => false,
};
