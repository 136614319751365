import { Field, Form } from 'react-final-form';
import { ReactComponent as SendIcon } from '../../../icons/sendIcon.svg';
import Button from '../../../components/button';
import { TextField, useMediaQuery } from '@mui/material';

export const MessageBox = ({ handleSubmit, isSubmitting }) => {
  const handleSubmitMessage = async ({message}) => {
    handleSubmit(`<p>${message}</p>`)
  };
  const mobile = useMediaQuery('(max-width: 500px)');
  return (
    <>
      <div className="tw-flex tw-flex-row tw-items-center  ">
        <Form
          onSubmit={(value) => {
            handleSubmitMessage(value);
          }}
          initialValues={{
            message: '',
          }}
        >
          {({ handleSubmit, reset }) => (
            <>
              <form className={`tw-flex tw-flex-row tw-items-center tw-w-full tw-gap-4 tw-bg-white border-radius-bottom-right`} onSubmit={(e) => {
                handleSubmit(e)
                }}>
                <Field
                  name="message"
                  render={({ input: { onChange, value } }) => (
                    <div className="tw-w-full tw-h-full tw-flex " id="message-box">
                      <TextField
                        placeholder="Написать сообщение"
                        multiline
                        maxRows={mobile ? 6 : 12}
                        onChange={onChange}
                        value={value}
                        className="tw-self-end hover:tw-border-0"
                        fullWidth
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' ) {
                            e.preventDefault()
                            handleSubmit(value)
                            onChange('')
                          }
                        }}
                        sx={{
                          '& fieldset': {
                            border: 'none',
                          },
                          '.MuiInputBase-root': {
                            paddingRight: '12px',
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              variant="text"
                              className="tw-w-fit tw-p-0 tw-ml-4"
                              type="submit"
                              onClick={(e) => {
                                handleSubmit(e);
                                onChange('');
                              }}
                              disabled={value === ''}
                            >
                              <SendIcon
                                className={`tw-w-8 tw-h-8 tw-transition-colors ${
                                  value === '' ? 'tw-fill-[#83A1D9]' : ' tw-fill-[#0844B4]'
                                }`}
                              />
                            </Button>
                          ),
                          sx: {
                            alignItems: mobile ? 'flex-end' : 'flex-start',
                          },
                        }}
                      />
                    </div>
                  )}
                />
              </form>
            </>
          )}
        </Form>
      </div>
    </>
  );
};
