import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../components/loader';
import { chatsAPIs } from '../../../services';
import classes from '../chatbot.module.sass';

import { CompositeDecorator, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { Message } from './Message';
import { MessageBox } from './MessageBox';
import { MessageBodyHeader } from './MessageBodyHeader';

export function MessageBody({ chat, msgSidebarOpen, setMsgSidebarOpen, onSendMessage }) {
  const [messages, setMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lastElementRef = useRef(null);
  const testRef = useRef();
  const user = useSelector((state) => state.auth.user);
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));

  const handleSendMessage = async (message) => {
    try {
      setIsSubmitting(true);
      const response = await chatsAPIs.sendMessage(chat.id, { text: message });
      setMessages(messages.concat([response.message]));
      // onSendMessage();
    } catch (e) {
      console.error(e);
      createErrorNotification('Ошибка при отправке сообщения');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddPrice = async () => {
    try {
      setIsSubmitting(true);
      await chatsAPIs.addPrice(chat.id);
      createNotification('Цена была добавлена');
    } catch (e) {
      console.error(e);
      createErrorNotification('Нет ответов от поставщика или не найдена цена');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCurrentTextSelection = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);

    return selectedText;
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const response = await chatsAPIs.getMessages(chat.id);
        setMessages(response.data || []);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    if (chat) {
      fetchMessages();
    }
  }, [chat]);

  const checkProfileImage = (isSupplier, ref) => {
    if (!isSupplier) {
      return (
        <span
          ref={ref}
          className={`tw-flex tw-justify-center tw-items-center tw-w-[42px] tw-h-[42px] tw-rounded-full tw-bg-[#0844B4] tw-uppercase tw-text-white`}
        >
          {(user?.full_name?.[0] || '') + (user?.full_name?.[1] || '')}
        </span>
      );
    }
    if (chat.photos.length === 0)
      return (
        <span
          ref={ref}
          className={`tw-flex tw-justify-center tw-items-center tw-w-[42px] tw-h-[42px] tw-rounded-full tw-bg-[#0844B4] tw-uppercase tw-text-white`}
        >
          {(chat?.host?.[0] || '') + (chat?.host?.[1] || '')}
        </span>
      );
    return <img className="tw-w-[42px] tw-h-[42px] tw-rounded-full" src={chat.photos[0] || 'images/defaultLogo.png'} onError={(e) => {
      e.target.src = 'images/defaultLogo.png';
    }} alt="Логотип поставщика" />;
  };

  const messagesByDate = useMemo(() => {
    return groupBy(messages, (message) => moment(message.created_at).startOf('day').format('DD MMMM YYYY'));
  }, [messages]);

  const toggleSidebar = () => {
    setMsgSidebarOpen((prev) => !prev);
  };

  const scrollToElemets = (ref) => {
    setTimeout(() => {
      if (ref?.current) {
        ref.current.scrollIntoView(true);
      }
    });
  };
  useLayoutEffect(() => {
    scrollToElemets(testRef);
  }, [chat, messages]);

  //scroll to last element
  useLayoutEffect(() => {
    lastElementRef?.current?.scrollIntoView(true);
  }, [messagesByDate, lastElementRef]);

  return (
    <>
      <div
        className={classNames(
          'tw-grow tw-flex tw-flex-col md:tw-translate-x-0 tw-transform tw-transition-tw-transform tw-duration-300 tw-ease-in-out',
          msgSidebarOpen ? 'tw-translate-x-1/3 tw-invisible md:tw-visible' : 'tw-translate-x-0',
          'md:tw-w-[calc(100% - 18rem)] xl:tw-w-[calc(100% - 20rem)] tw-h-[calc(100vh-102px)]'
        )}
      >
        {chat && (

        <MessageBodyHeader handleToggleSidebar={toggleSidebar} photo={chat?.photos?.[0]} hostName={chat?.host} />
        )}
        <div
          className={classNames(
            'tw-relative tw-grow tw-overflow-x-hidden tw-flex tw-flex-col tw-justify-end tw-overflow-y-auto',
            msgSidebarOpen ? '' : 'tw-border-l'
          )}
        >
          {isLoading ? (
            <Loader />
          ) : chat ? (
            <>
              {messages.length === 0 ? (
                <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                  <span className="tw-text-gray-500">Нет сообщений</span>
                </div>
              ) : (
                <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-pt-16">
                  {Object.entries(messagesByDate).flatMap(([day, messages], groupIndex) => {
                    return [
                      <div
                        key={day}
                        className="tw-text-[#666] tw-text-sm tw-mb-6 tw-flex tw-flex-row tw-justify-center"
                      >
                        {day}
                      </div>,
                      messages.map((message, i) => {
                        return (
                          <Message
                            key={message.id}
                            message={message}
                            isNextMessageDifferent={messages[i].is_supplier_msg !== messages[i + 1]?.is_supplier_msg}
                            checkProfileImage={checkProfileImage}
                          />
                        );
                      }),
                    ];
                  })}
                  <div ref={testRef} className="tw-bg-black testtest"></div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                <span className="tw-text-gray-500">Выберите, кому хотите написать</span>
              </div>
            </>
          )}
        </div>
        {chat && <MessageBox isSubmitting={isSubmitting} handleSubmit={handleSendMessage} />}
      </div>
    </>
  );
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  link: {
    color: '#818cf8',
    textDecoration: 'underline',
  },
};

export const EmptyMessageBody = () => {
  return (
    <div className="tw-w-full tw-grow tw-bg-[#F7F7F7] md:tw-w-[calc(100% - 18rem)] xl:tw-w-[calc(100% - 20rem)] tw-h-[calc(100vh-102px)] tw-flex tw-flex-col tw-items-center tw-justify-center">
      <p className="tw-text-[#4C4C4C] tw-text-sm tw-leading-[18px] tw-font-semibold tw-text-center">
        У вас нет сообщений
      </p>
    </div>
  );
};
