/* eslint-disable prefer-destructuring */
// Import Chart.js
import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  CategoryScale,
  Legend,
} from 'chart.js';
import moment from 'moment';
// Import TailwindCSS variables
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../configs/tailwind.config';
// Import utilities
import { formatThousands, hexToRGB } from '../../utils/common';

// Tailwind config
const fullConfig = resolveConfig(tailwindConfig);

Chart.register(
  LineController,
  LineElement,
  Legend,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  CategoryScale
);

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.marginLeft = fullConfig.theme.margin[3];
      // Button element
      const button = document.createElement('button');
      button.style.display = 'inline-flex';
      button.style.alignItems = 'center';
      button.style.opacity = item.hidden ? '.3' : '';
      button.onclick = () => {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        chart.update();
      };
      // Color box
      const box = document.createElement('span');
      box.style.display = 'block';
      box.style.width = fullConfig.theme.width[3];
      box.style.height = fullConfig.theme.height[3];
      box.style.borderRadius = fullConfig.theme.borderRadius.full;
      box.style.marginRight = fullConfig.theme.margin[2];
      box.style.borderWidth = '3px';
      box.style.borderColor = chart.data.datasets[item.datasetIndex].borderColor;
      box.style.pointerEvents = 'none';
      // Label
      const label = document.createElement('span');
      label.style.color = fullConfig.theme.colors.gray[500];
      label.style.fontSize = fullConfig.theme.fontSize.sm[0];
      label.style.lineHeight = fullConfig.theme.fontSize.sm[1].lineHeight;
      const labelText = document.createTextNode(item.text);
      label.appendChild(labelText);
      li.appendChild(button);
      button.appendChild(box);
      button.appendChild(label);
      ul.appendChild(li);
    });
  },
};

// A chart built with Chart.js 3
// https://www.chartjs.org/
export const PriceChartJs = ({ id, data1 = [], data2 = [], labels = [] }) => {
  const ctx = document.getElementById(id).getContext('2d');
  if (!ctx) return;
  // eslint-disable-next-line no-unused-vars
  const chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      datasets: [
        // Indigo line
        {
          label: 'Открытые источники',
          data: data1,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(fullConfig.theme.colors.blue[500])}, 0.08)`,
          borderColor: fullConfig.theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointBackgroundColor: fullConfig.theme.colors.indigo[500],
        },
        // // Gray line
        {
          label: 'Государственные контракты',
          data: data2,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(fullConfig.theme.colors.gray[300])}, 0.08)`,
          borderColor: fullConfig.theme.colors.gray[300],
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointBackgroundColor: fullConfig.theme.colors.gray[300],
        },
      ],
    },
    options: {
      layout: {
        padding: 20,
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback: (value) => formatThousands(value),
          },
        },
        x: {
          //   type: 'time',
          //   time: {
          //     parser: 'MM-DD-YYYY',
          //     unit: 'days',
          //     displayFormats: {
          //       month: 'MMM YY',
          //       days: 'DD-MM-YYYY',
          //     },
          //   },
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        // htmlLegend: {
        //   // ID of the container to put the legend in
        //   containerID: `legend-${id}`,
        // },
        tooltip: {
          callbacks: {
            title: (context) => context?.[0]?.label,
            label: (context) => formatThousands(context.parsed.y),
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
      },
      maintainAspectRatio: false,
    },
    // plugins: [htmlLegendPlugin],
  });
  return { chart };
};

export default PriceChartJs;
