import { useDialog } from '../../providers/dialog.provider';
import { Dialog } from '@mui/material';
import Button from '../button';
import Input from '../input';
import { Form, Field } from 'react-final-form';
import { TextArea } from '../input/TextArea';
import { ReactComponent as ImageIcon } from '../../icons/imageIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { useEditFaqMutation } from '../../services';
import { IMAGE_MIME_TYPES } from '../../utils/constant';
import { createErrorNotification } from '../../utils/notifications';
import { useRef } from 'react';
import DOMPurify from 'dompurify';

export const FaqEditModal = ({ initialValues }) => {
  const dialog = useDialog();
  const [editFaq, { isLoading }] = useEditFaqMutation();
  const fileInputRef = useRef(null);

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'content') {
          formData.append(key, DOMPurify.sanitize(value));
        } else if (key === 'uploaded_files' && value) {
          const file = value instanceof Array ? value[0] : value;
          if (file instanceof File) {
            formData.append(key, file);
          }
        } else {
          formData.append(key, value);
        }
      });

      await editFaq({ id: values.id, body: formData });
      dialog.close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '623px',
          width: '100%',
          margin: 0,
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <h4 className="tw-leading-5 tw-mb-8">Редактирование вопроса – ответа</h4>
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6">
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            subscription={{ submitting: true, pristine: true, values: true }}
          >
            {({ handleSubmit, form }) => {
              const { values, pristine } = form.getState();
              const isDisabled = pristine || !values.header || !values.content;

              return (
                <form onSubmit={handleSubmit} className="tw-w-full tw-flex tw-flex-col tw-h-full">
                  <div className="tw-w-full">
                    <div className="tw-mb-8">
                      <div className="tw-mb-3">Вопрос</div>
                      <Input form={form} name="header" type="text" required size="small" />
                    </div>
                    <div className="tw-mb-10">
                      <div className="tw-mb-3">Ответ</div>
                      <Field name="content">
                        {({ input }) => <TextArea {...input} required multiline rows={6} fullWidth />}
                      </Field>
                    </div>
                    <Field
                      name="uploaded_files"
                      render={({ input }) => {
                        const handleFileChange = (e) => {
                          const file = e.target.files[0];
                          if (file && !IMAGE_MIME_TYPES.includes(file.type)) {
                            createErrorNotification('Допустимы только файлы .png .jpeg или .jpg');
                            return;
                          }
                          input.onChange(file);
                        };

                        const handleEditClick = () => {
                          fileInputRef?.current?.click();
                        };

                        const handleRemoveFile = () => {
                          input.onChange(null);
                        };

                        const file = input.value;

                        const preview = file instanceof File ? URL.createObjectURL(file) : file;
                        const fileName = file ? file.name : null;
                        return (
                          <div className="tw-mt-6">
                            {!preview ? (
                              <>
                                <label
                                  for="uploadBtn"
                                  className="tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1  tw-leading-5 tw-font-medium tw-cursor-pointer"
                                >
                                  <ImageIcon /> Загрузить изображение
                                </label>
                                <input
                                  {...input}
                                  id="uploadBtn"
                                  type="file"
                                  hidden
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={handleFileChange}
                                />
                              </>
                            ) : (
                              <div className="tw-flex tw-flex-row tw-items-start tw-gap-[10px]">
                                <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                                  <img
                                    src={preview}
                                    alt={fileName}
                                    className="tw-w-[120px] tw-h-[86px] tw-object-cover tw-rounded-lg"
                                  />
                                  <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px]">{fileName}</p>
                                </div>
                                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                                  <Button
                                    variant="text"
                                    onClick={handleEditClick}
                                    className="tw-text-blue-600 tw-w-fit tw-p-0"
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Button
                                    variant="text"
                                    onClick={handleRemoveFile}
                                    className="tw-text-red-600 tw-w-fit tw-p-0"
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </div>
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={handleFileChange}
                                  hidden
                                />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4">
                    <Button
                      variant="text"
                      className="tw-mr-[20px] tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
                      onClick={() => dialog.close()}
                    >
                      Отменить
                    </Button>
                    <Button
                      disabled={isDisabled || isLoading}
                      className="tw-w-fit tw-rounded-lg tw-px-4 tw-py-3 tw-font-medium"
                      type="submit"
                    >
                      Редактировать
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};
