import classNames from 'classnames';

export function SupportIcon({ isActive }) {
  return (
    <svg className="tw-shrink-0 tw-h-6 tw-w-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        className={classNames('tw-fill-current ', isActive && '')}
        d="M12 22L11.75 19H11.5C9.13333 19 7.125 18.175 5.475 16.525C3.825 14.875 3 12.8667 3 10.5C3 8.13333 3.825 6.125 5.475 4.475C7.125 2.825 9.13333 2 11.5 2C12.6833 2 13.7875 2.22083 14.8125 2.6625C15.8375 3.10417 16.7375 3.7125 17.5125 4.4875C18.2875 5.2625 18.8958 6.1625 19.3375 7.1875C19.7792 8.2125 20 9.31667 20 10.5C20 11.75 19.7958 12.95 19.3875 14.1C18.9792 15.25 18.4208 16.3167 17.7125 17.3C17.0042 18.2833 16.1625 19.175 15.1875 19.975C14.2125 20.775 13.15 21.45 12 22ZM14 18.35C15.1833 17.35 16.1458 16.1792 16.8875 14.8375C17.6292 13.4958 18 12.05 18 10.5C18 8.68333 17.3708 7.14583 16.1125 5.8875C14.8542 4.62917 13.3167 4 11.5 4C9.68333 4 8.14583 4.62917 6.8875 5.8875C5.62917 7.14583 5 8.68333 5 10.5C5 12.3167 5.62917 13.8542 6.8875 15.1125C8.14583 16.3708 9.68333 17 11.5 17H14V18.35ZM11.475 15.975C11.7583 15.975 12 15.875 12.2 15.675C12.4 15.475 12.5 15.2333 12.5 14.95C12.5 14.6667 12.4 14.425 12.2 14.225C12 14.025 11.7583 13.925 11.475 13.925C11.1917 13.925 10.95 14.025 10.75 14.225C10.55 14.425 10.45 14.6667 10.45 14.95C10.45 15.2333 10.55 15.475 10.75 15.675C10.95 15.875 11.1917 15.975 11.475 15.975ZM10.75 12.8H12.25C12.25 12.3 12.3 11.95 12.4 11.75C12.5 11.55 12.8167 11.1833 13.35 10.65C13.65 10.35 13.9 10.025 14.1 9.675C14.3 9.325 14.4 8.95 14.4 8.55C14.4 7.7 14.1125 7.0625 13.5375 6.6375C12.9625 6.2125 12.2833 6 11.5 6C10.7667 6 10.15 6.20417 9.65 6.6125C9.15 7.02083 8.8 7.51667 8.6 8.1L10 8.65C10.0833 8.36667 10.2417 8.0875 10.475 7.8125C10.7083 7.5375 11.05 7.4 11.5 7.4C11.95 7.4 12.2875 7.525 12.5125 7.775C12.7375 8.025 12.85 8.3 12.85 8.6C12.85 8.88333 12.7667 9.1375 12.6 9.3625C12.4333 9.5875 12.2333 9.81667 12 10.05C11.4167 10.55 11.0625 10.9458 10.9375 11.2375C10.8125 11.5292 10.75 12.05 10.75 12.8Z"
        fill="white"
      />
    </svg>
  );
}
