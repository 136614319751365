import { useSelector } from 'react-redux';
import { useDialog } from '../../../../providers/dialog.provider';
import { Dialog } from '@mui/material';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../../components/input/search';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import Button from '../../../../components/button';
import { useGetProductBlockedHostsQuery, useRestoreDeletedProductBlockedHostMutation } from '../../../../services';
import { createNotification } from '../../../../utils/notifications';
import { ReactComponent as RestoreIcon } from '../../../../icons/restoreIcon.svg';
// import {ReactComponent as SendMessageIcon} from '../../../../icons/sendMessageIcon.svg'

export const ProductBlockedHosts = ({ product_id }) => {
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const dialog = useDialog();

  const [searchText, setSearchText] = useState('');

  const [restoreBlockedHost] = useRestoreDeletedProductBlockedHostMutation();

  const handleRestoreHost = useCallback(
    async (host) => {
      await restoreBlockedHost({ report_id: report.id, product_id, host })
        .unwrap()
        .then(() => {
          createNotification('Источник восстановлен');
        });
    },
    [product_id, report.id, restoreBlockedHost]
  );

  const { data } = useGetProductBlockedHostsQuery(
    {
      report_id: report.id,
      product_id,
    },
    {
      skip: !report?.id,
    }
  );

  const handleCloseDialog = () => {
    dialog.close();
  };

  const filteredHostsBySearchText = useMemo(() => {
    if (!data || !data.blocked_hosts) return [];
    if (searchText === '') {
      return data.blocked_hosts;
    }
    return data.blocked_hosts.filter((host) => host.includes(searchText));
  }, [data, searchText]);

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '824px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-rounded-lg tw-gap-4 tw-relative tw-min-h-[400px]">
        <Button
          variant="text"
          onClick={handleCloseDialog}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[14px] tw-right-[14px]"
        >
          <CloseIcon />
        </Button>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-6 tw-pt-6 tw-pl-6 tw-pr-6">
          <h6 className="tw-text-[#191919] tw-text-[18px] tw-font-medium tw-leading-[21px]">
            Заблокированные источники
          </h6>
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                onSearchChange={(value) => {
                  setSearchText(value);
                }}
                name="search"
                type="text"
                placeholder="Найти источник"
                className="tw-mb-4"
              />
            )}
          </Form>
        </div>
        <div className="tw-flex tw-flex-col tw-divide-y tw-px-6 tw-overflow-auto tw-max-h-[568px]">
          {data?.blocked_hosts.length > 0 ? (
            <>
              {filteredHostsBySearchText.length > 0 ? (
                filteredHostsBySearchText.map((host) => (
                  <>
                    <BlockedHost host={host} key={host} onRestore={handleRestoreHost} />
                  </>
                ))
              ) : (
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                  <p className="tw-text-[#191919] tw-text-center">Не найдено</p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                <p className="tw-text-[#191919] tw-text-center">Нет заблокированных источников</p>
              </div>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const BlockedHost = ({ host, onRestore }) => {
  const handleRestoreSource = () => {
    onRestore(host);
  };
  return (
    <div className="tw-w-full tw-pt-[18px] tw-pb-[15px] tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-flex-row tw-items-start tw-gap-[14px]">
        <img
          src="/images/placeholders/other.png"
          className="tw-h-[70px] tw-w-[70px] tw-min-h-[70px] tw-min-w-[70px]"
          alt="host-img"
        />
        <div className="tw-flex tw-flex-col tw-gap-1">
          {host && (
            <a
              className="tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-medium"
              href={host}
              target="_blank"
              rel="noreferrer"
            >
              {host}
            </a>
          )}
          {/* Текст хоста, пока не приходит с бэка */}
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-[31px] tw-items-center">
        <Button
          variant="text"
          onClick={handleRestoreSource}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-flex tw-flex-row tw-items-center tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-medium"
        >
          <RestoreIcon />
          Восстановить источник
        </Button>
      </div>
    </div>
  );
};
