import classNames from 'classnames';
import { ReactComponent as MessageRead } from '../../../icons/messageReadIcon.svg';
import { ReactComponent as MessageSend } from '../../../icons/messageSendIcon.svg';
import { ReactComponent as MessageError } from '../../../icons/messageErrorIcon.svg';
import moment from 'moment';

export const MESSAGE_STATE = {
  sent: <MessageSend className="tw-w-[16px] tw-h-[16px]" />,
  read: <MessageRead className="tw-w-[16px] tw-h-[16px]" />,
  error: <MessageError className="tw-w-[16px] tw-h-[16px]" />,
};

export const MessageSidebarItem = ({
  isSelected,
  photo,
  host,
  messageState,
  sentAt,
  lastMessage,
  unreadMessageAmount,
  handleClick,
}) => {
  const checkDate = (date) => {
    if (!date) return '';
    const withoutTime = moment(date).format('DD.MM.YYYY');
    if (moment(withoutTime).isSame(new Date())) {
      const formattedDate = moment(date).format('HH:mm');
      return formattedDate;
    } else return withoutTime;
  };
  const formattedText = (text) => {
    const cleanText = text?.replace(/<\/?[^>]+(>|$)/g, '');
    if (cleanText) return cleanText;
    else return text;
  };
  return (
    <>
      <button
        className={classNames(
          'tw-flex tw-items-center tw-justify-between tw-w-full tw-p-2 tw-rounded-xl active:tw-bg-[#EEEEEE] tw-transition-colors',
          isSelected ? 'tw-bg-[#F7F7F7]' : 'hover:tw-bg-[#FCFCFC]'
        )}
        onClick={handleClick}
      >
        <div className="tw-flex tw-items-center tw-w-full">
          <img
            className="tw-rounded-full tw-mr-2 tw-w-[42px] tw-h-[42px]"
            src={photo || "/images/defaultLogo.png"}
            onError={(e) => {
              e.target.src = "images/defaultLogo.png";
            }}
            alt="Логотип поставщика"
          />
          <div className="tw-flex tw-flex-col tw-justify-start tw-items-start  tw-w-full">
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-[218px]">
              <span className="tw-text-sm tw-font-bold tw-truncate tw-max-w-[55%]">{host}</span>
              <div className="tw-flex tw-flex-row tw-gap-2 tw-justify-end ">
                {MESSAGE_STATE[messageState] ?? <></>}
                <p className="tw-text-[#78858B] tw-text-xs">{checkDate(sentAt) ?? <></>}</p>
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-row tw-justify-between ">
              <span className="tw-font-normal tw-text-[#666666] tw-text-sm tw-truncate tw-max-w-[198px]">
                {formattedText(lastMessage) ?? <></>}
              </span>
              {unreadMessageAmount ? (
                <span className="tw-min-w-[18px] tw-rounded-full tw-bg-[#FF4343] tw-text-white tw-px-[6px] tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-xs tw-leading-4 tw-font-semibold">
                  {unreadMessageAmount}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </button>
    </>
  );
};
