import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import Button from '../../../button';
import { Form } from 'react-final-form';
import Input from '../../../input';
import { createErrorNotification } from '../../../../utils/notifications';
import { useAddCorrectPriceMutation } from '../../../../services';
import { useSelector } from 'react-redux';

export const CorrectingPrice = ({ item, onSubmit, productName, product_id }) => {
  const dialog = useDialog();
  const { project_id } = useSelector((state) => state?.organizations?.selectedReport) ?? { project_id: null };
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 2,
  });

  const [updatePrice, { isLoading }] = useAddCorrectPriceMutation();

  const organization = useSelector((state) => state.organizations.selected);

  const handleSubmit = async (price) => {
    const body = {
      correct_price: Number(price),
      found_price: item.price,
      name: productName,
      original_price: item.price,
      product_id: product_id,
      project_id: project_id,
      url: item.url,
      organization_id: organization?.id,
    };
    await updatePrice(body)
      .unwrap()
      .then((res) => {
        onSubmit(res);
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при попытке изменить цену');
      });
  };
  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-min-w-[400px]">
        <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 tw-mb-[26px]">Некорректная цена товара</p>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-[22px]">
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Заявленная цена</p>
          <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
            {numberFormatter
              .format(item?.price ?? 0)
              .replace('₽', '')
              .trim()}{' '}
            <span className="tw-ml-1">₽</span>
          </p>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-2">
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Найденная в источнике цена</p>
          <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
            {numberFormatter
              .format(item?.price ?? 0)
              .replace('₽', '')
              .trim()}{' '}
            <span className="tw-ml-1">₽</span>
          </p>
        </div>
        {item?.url && (
          <a
            href={item.url}
            className="tw-text-[#0844B4] tw-leading-[18px] tw-max-w-[389px] tw-truncate tw-mb-[22px] tw-cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            {item.url}
          </a>
        )}
        <Form
          initialValues={{ price: '' }}
          onSubmit={({ price }) => {
            handleSubmit(price);
          }}
          render={({ handleSubmit, submitting, form, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-flex tw-flex-col ">
                <p className="tw-text-[#191919] tw-leading-[18px] tw-mb-[10px]">Цена на сайте</p>
                <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-14">
                  <Input form={form} name="price" type="number" required placeholder={'Цена в рублях'} />
                </div>
                <DialogActions className="tw-gap-6 tw-p-0">
                  <Button
                    variant="text"
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                    disabled={isLoading}
                    onClick={() => dialog.close()}
                  >
                    Закрыть
                  </Button>
                  <Button
                    type="submit"
                    className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={!values['price'] || isLoading}
                  >
                    {isLoading && (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="tw-inline tw-w-4 tw-h-4 tw-mr-2 tw-text-white tw-animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                    Отправить
                  </Button>
                </DialogActions>
              </div>
            </form>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};
