import { useCallback, useState, useEffect } from 'react';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import { Pagination } from '../../components/table/pagination';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { Form } from 'react-final-form';
import classes from './size-handbook.module.sass';
import { SearchInput } from '../../components/input/search';
import { useDialog } from '../../providers/dialog.provider';
import { SizesFormDialog } from './components/SizesFormDialog';
import { useCrudEntity, CrudTable } from '../../components/table/CrudTable';
import { handbookAPIs } from '../../services';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { usePagination } from '../../components/table/usePagination';
import { UploadExcelButtons } from './components/UploadExcelButtons';
import { calculateProductOrderNumber } from '../../utils/common';

export default function SizeHandbook() {
  const sizesCrudAdapter = useCrudEntity({
    read: (id) => handbookAPIs.getHandbook('standard_sizes', id),
    create: (payload) => handbookAPIs.addHandbook('standard_sizes', payload),
    edit: (id, payload) => handbookAPIs.editHandbook('standard_sizes', id, payload),
    readAll: (limit, page_num, query) => handbookAPIs.getHandbooks('standard_sizes', limit, page_num, query),
    delete: (id) =>
      handbookAPIs
        .deleteHandbook('standard_sizes', id)
        .then(() => sizesCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data))),
  });

  const [data, setData] = useState([]);
  console.log(data)
  const fetchData = useCallback(
    async (limit = 10, page_num = 1, query = '') => {
      try {
        const res = await sizesCrudAdapter.readAll(limit, page_num, query);
        return res;
      } catch (err) {
        createErrorNotification('Что-то пошло не так');
        console.error(err);
      }
    },
    [sizesCrudAdapter]
  );
  const {
    data: paginatedData,
    currentPage,
    pageSize,
    setPageSize,
    goToPage,
    total,
    handleChangeQuery,
    isLoading,
  } = usePagination(fetchData);
  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);
  const dialog = useDialog();

  const columns = {
    name: {
      label: 'Имя',
    },
    length: {
      label: 'Длина',
    },
    height: {
      label: 'Высота',
    },
    width: {
      label: 'Ширина',
    },
    maximum_load_weight: {
      label: 'Макс. вес',
    },
    size_units: {
      label: 'Ед. размера',
    },
    weight_units: {
      label: 'Ед. массы',
    },
  };

  const createSize = useCallback(
    async (category) => {
      try {
        await sizesCrudAdapter
          .create(category)
          .then(() => sizesCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data)));
        createNotification('Типовой размер создан', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        if (err.statusCode === 409) {
          createErrorNotification('Типовой размер уже существует');
        } else {
          createErrorNotification('Что-то пошло не так');
        }
      }
    },
    [sizesCrudAdapter]
  );

  const editSize = useCallback(
    async (id, category) => {
      try {
        await sizesCrudAdapter
          .edit(id, category)
          .then(() => sizesCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data)));
        createNotification('Типовой размер изменён', { type: 'success', hideProgressBar: true });
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [sizesCrudAdapter]
  );

  const showCreateSizeForm = useCallback(() => {
    dialog.open(
      <SizesFormDialog
        title={'Добавление типового размера'}
        value={{
          name: '',
          length: '',
          height: '',
          width: '',
          maximum_load_weight: '',
          size_units: '',
          weight_units: '',
        }}
        onSubmit={createSize}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, createSize]);

  const showEditSizeForm = useCallback(
    ({ id: sizeId }) => {
      sizesCrudAdapter.read(sizeId).then((category) => {
        dialog.open(<SizesFormDialog value={category} title={'Редактирование типового размера'} onSubmit={editSize} />);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog, sizesCrudAdapter]
  );
  const askConfirmationDelete = useCallback(
    (category) => {
      dialog.open(
        <ConfirmModal
          value={category}
          title={`Вы уверены, что хотите удалить ${category.name}?`}
          onAgree={sizesCrudAdapter.delete}
        />
      );
    },
    [dialog, sizesCrudAdapter]
  );

  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-border-b-0">
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => showEditSizeForm(entity)}
        >
          <EditIcon />
        </Button>
        <Button
          variant="text"
          className="tw-p-0 tw-max-w-6 hover:tw-bg-inherit"
          onClick={() => askConfirmationDelete(entity)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };
  const onUploadFile = () => {
    sizesCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data));
  };
  return (
    <GeneralLayout>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">
        Справочник типовых размеров
      </h1>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
        <div className="tw-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-gap-4">
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                className={` tw-max-w-[312px] ${classes.searchInput}`}
                onSearchChange={handleChangeQuery}
                name="search"
                placeholder="Найти типовой размер"
                type="text"
                required
                size="small"
              />
            )}
          </Form>
          <div className="tw-flex lg:tw-flex-row tw-flex-col lg:tw-items-center md:tw-items-start lg:tw-gap-4 md:tw-gap-0 tw-flex-wrap">
            <UploadExcelButtons onUpload={onUploadFile} />
            <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateSizeForm()}>
              <AddIcon className="tw-mr-[4px]" />
              Добавить типовой размер
            </Button>
          </div>
        </div>
        {!isLoading ? (
          <CrudTable rows={data} entityColumns={columns} crudButtons={crudButtons} />
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </GeneralLayout>
  );
}
