import { Dialog } from "@mui/material"
import { useDialog } from "../../../../providers/dialog.provider"

export const ShowQR = () => {
    const dialog = useDialog()
    return (
        <>
        <Dialog open={dialog.visibility} onClick={() => dialog.close()} sx={{
           '& .MuiPaper-root': {
            borderRadius: '22px'
           }
        }}>
            <div className="tw-rounded-[22px] tw-inline-block" onClick={(e) => e.stopPropagation()}>
            <img src="/images/telegramLarge.png" className='tw-cursor-pointer tw-rounded-[22px] tw-block' alt="telegramQR" width={314} height={314}/>
            </div>
        </Dialog>
        </>
    )
}