export const ORGANIZATIONS__GET_LIST = 'ORGANIZATIONS__GET_LIST';
export const ORGANIZATIONS__ADD_ORGANIZATION = 'ORGANIZATIONS__ADD_ORGANIZATION';
export const ORGANIZATIONS__UPDATE_ORGANIZATION = 'ORGANIZATIONS__UPDATE_ORGANIZATION';
export const ORGANIZATIONS__DELETE_ORGANIZATION = 'ORGANIZATIONS__DELETE_ORGANIZATION';
export const ORGANIZATIONS__SET_SELECTED = 'ORGANIZATIONS__SET_SELECTED';
export const ORGANIZATIONS__SET_SELECTED_REPORT = 'ORGANIZATIONS__SET_SELECTED_REPORT';

export const ORGANIZATIONS__TOGGLE_SELECT_MODAL = 'ORGANIZATIONS__TOGGLE_SELECT_MODAL';
export const ORGANIZATIONS__TOGGLE_SELECT_MODAL_CLOSEBTN = 'ORGANIZATIONS__TOGGLE_SELECT_MODAL_CLOSEBTN';

export const ORGANIZATIONS__GET_PRODUCTS = 'ORGANIZATIONS__GET_PRODUCTS';
export const ORGANIZATIONS__LOAD_MORE_PRODUCTS = 'ORGANIZATIONS__LOAD_MORE_PRODUCTS';
export const ORGANIZATIONS__UPDATE_PRODUCT = 'ORGANIZATIONS__UPDATE_PRODUCT';
export const ORGANIZATIONS__CLEAN_PRODUCTS = 'ORGANIZATIONS__CLEAN_PRODUCTS';
export const ORGANIZATIONS__DELETE_PRODUCT = 'ORGANIZATIONS__DELETE_PRODUCT';

export const ORGANIZATIONS__GET_PROJECTS = 'ORGANIZATIONS__GET_PROJECTS';
export const ORGANIZATIONS__GET_ARCHIVED_PROJECTS = 'ORGANIZATIONS__GET_ARCHIVED_PROJECTS';
export const ORGANIZATIONS__GET_ARCHIVED_PRODUCTS = 'ORGANIZATIONS__GET_ARCHIVED_PRODUCTS';
export const ORGANIZATIONS__ADD_PROJECT = 'ORGANIZATIONS__ADD_PROJECT';
export const ORGANIZATIONS__DELETE_PROJECT = 'ORGANIZATIONS__DELETE_PROJECT';
export const ORGANIZATIONS__REMOVE_ARCHIVED_PROJECT = 'ORGANIZATIONS__REMOVE_ARCHIVED_PROJECT';
export const ORGANIZATIONS__UPDATE_PROJECT = 'ORGANIZATIONS__UPDATE_PROJECT';

export const ORGANIZATIONS__SET_SAVED_SOURCES = 'ORGANIZATIONS__SET_SAVED_SOURCES';

export const ORGANIZATIONS__SET_SHOW_ARCHIVE = 'ORGANIZATIONS__SET_SHOW_ARCHIVE';
export const ORGANIZATIONS__SET_ARCHIVED_SOURCES = 'ORGANIZATIONS__SET_ARCHIVED_SOURCES';

export const ORGANIZATIONS__SET_SHOW_REQUEST_PRICE = 'ORGANIZATIONS__SET_SHOW_REQUEST_PRICE';
export const ORGANIZATIONS__SET_PRICE_REQUESTS = 'ORGANIZATIONS__SET_PRICE_REQUESTS';

export const ORGANIZATIONS_CATEGORIES = 'ORGANIZATIONS_CATEGORIES';
export const ORGANIZATIONS_PROJECT_USER_ACCEPTED_SELECTED_CHANGES =
  'ORGANIZATIONS_PROJECT_USER_ACCEPTED_SELECTED_CHANGES';
