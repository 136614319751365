import { Dialog, MenuItem, Select, TextField } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import Button from '../../../components/button';
import { useEffect, useState } from 'react';
import { suppliersAPIs } from '../../../services';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ReactComponent as ArrowDown } from '../../../icons/arrowDownIcon.svg';

const ArrowDownIcon = (props) => {
  return <ArrowDown className=" tw-pointer-events-none MuiSvgIcon-root MuiSelect-icon tw-w-6 tw-h-6" {...props} />;
};

export const AddSupplierDialog = () => {
  const dialog = useDialog();
  const [categories, setCategories] = useState([]);
  const organizationId = localStorage.getItem('organizationId');

  const fields = [
    {
      name: 'url',
      placeholder: 'Источник: (*обязательно)',
      required: true,
      type: 'text',
    },
    {
      name: 'email',
      placeholder: 'Почта: (*обязательно)',
      required: true,
      type: 'text',
    },
    {
      name: 'telephone',
      placeholder: 'Телефон: (*обязательно)',
      required: true,
      type: 'number',
    },
    {
      name: 'name',
      placeholder: 'Название: (*обязательно)',
      required: true,
      type: 'text',
    },
    {
      name: 'inn',
      placeholder: 'ИНН: (*обязательно)',
      required: true,
      type: 'number',
    },
    {
      name: 'kpp',
      placeholder: 'КПП',
      required: false,
      type: 'number',
    },
    {
      name: 'manager',
      placeholder: 'Генеральный директор: (*обязательно)',
      required: true,
      type: 'text',
    },
  ];
  const handleSubmitForm = async (values) => {
    await suppliersAPIs
      .addSupplier(organizationId, values)
      .then(() => {
        createNotification('Поставщик добавлен');
        dialog.close();
      })
      .catch((err) => {
        createErrorNotification('Ошибка при создании поставщика');
      });
  };

  useEffect(() => {
    const getCategories = async () => {
      await suppliersAPIs
        .getCategories()
        .then((res) => setCategories(res.data))
        .catch((err) => {
          createErrorNotification('Ошибка при получении категорий');
        });
    };
    getCategories();
  }, []);

  return (
    <Dialog open={dialog.visibility}>
      <Form
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
        initialValues={{
          url: '',
          email: '',
          telephone: '',
          name: '',
          inn: '',
          kpp: '',
          manager: '',
          category: '',
        }}
      >
        {({ handleSubmit, pristine, invalid }) => (
          <form
            className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-rounded-lg tw-text-[#191919] tw-min-w-[440px]"
            onSubmit={handleSubmit}
          >
            <h3 className=" tw-leading-5 tw-font-semibold tw-mb-6">Добавление поставщика</h3>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-14">
              {fields.map((field) => (
                <Field name={field.name} key={field.name}>
                  {({ input: { onChange } }) => (
                    <>
                      <TextField
                        required={field.required}
                        name={field.name}
                        type={field.type}
                        className="tw-w-full tw-text-sm"
                        sx={{
                          '& .MuiInputBase-root': {
                            borderRadius: '8px',
                            '& input': {
                              padding: '14px 16px',
                            },
                          },
                        }}
                        placeholder={field.placeholder}
                        onChange={(e) => onChange(e.target.value)}
                      />
                    </>
                  )}
                </Field>
              ))}
              <Field name="category">
                {({ input: { onChange } }) => (
                  <>
                    <Select
                      onChange={onChange}
                      displayEmpty
                      defaultValue={''}
                      className="tw-p-[14px] tw-pr-[10px]"
                      sx={{
                        '& .MuiSelect-select': {
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiSelect-icon': {
                          top: 'calc(50% - 0.7em)',
                        },
                      }}
                      IconComponent={ArrowDownIcon}
                    >
                      <MenuItem value="">
                        <p className="tw-text-[#999999] tw-text-sm tw-font-medium tw-leading-[18px]">Категория</p>
                      </MenuItem>
                      {Object.keys(categories)?.map((category) => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </div>
            <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end">
              <Button
                variant="text"
                className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
                onClick={() => dialog.close()}
              >
                Отменить
              </Button>
              <Button
                className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-white tw-text-sm tw-leading-[18px]"
                type="submit"
                disabled={pristine || invalid}
              >
                Добавить поставщика
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
