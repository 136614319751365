import { Avatar, Checkbox, Chip, CircularProgress, Dialog, MenuItem, Select, Tooltip } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg';
import { ReactComponent as ArrowLeft } from '../../../icons/arrowRightIcon.svg';
import { usePagination } from '../../../components/table/usePagination';
import { useOrganizationSuppliers } from '..';
import Table from '../../../components/table';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Pagination } from '../../../components/table/pagination';
import Button from '../../../components/button';
import classes from '../suppliers.module.sass';
import { SearchInput } from '../../../components/input/search';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { chatsAPIs } from '../../../services';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ReactComponent as GoodSupplierIcon } from '../../../icons/goodSupplierIcon.svg';
import { ReactComponent as ArrowDown } from '../../../icons/arrowDownIcon.svg';

export const MailingDialog = ({ categories }) => {
  const dialog = useDialog();
  const getOrganizationSuppliers = useOrganizationSuppliers();
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [searchCategoryId, setSearchCategoryId] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [isGoodSupplier, setIsGoodSupplier] = useState(false);

  const regions = useSelector((state) => state.auth.regions);
  const regionsList = regions.map((region) => {
    return {
      name: region.name,
      value: region.name,
    };
  });
  const categoriesList =
    categories &&
    Object.keys(categories).map((category) => {
      return {
        name: category,
        value: categories[category],
      };
    });

  const handleSelectSupplier = (entity) => {
    const index = selectedSuppliers.findIndex((supplier) => supplier.id === entity.id);
    if (index !== -1) {
      setSelectedSuppliers(selectedSuppliers.filter((supplier) => supplier.id !== entity.id));
    } else {
      setSelectedSuppliers([...selectedSuppliers, { id: entity.id, name: entity.name, logo: entity.logo }]);
    }
  };
  const getAmountOfSelectedSuppliers = useCallback(() => {
    const amount = suppliers.reduce((acc, supplier) => {
      if (selectedSuppliers.some((selectedSupplier) => selectedSupplier.id === supplier.id)) acc++;
      return acc;
    }, 0);
    return amount;
  }, [selectedSuppliers, suppliers]);

  const handleHeaderCheckbox = (event) => {
    if (event.target.checked) {
      suppliers.forEach((supplier) => {
        const isSupplierExists = selectedSuppliers.some((selectedSupplier) => selectedSupplier.id === supplier.id);
        if (!isSupplierExists) {
          setSelectedSuppliers((prev) => [...prev, { id: supplier.id, name: supplier.name, logo: supplier.logo }]);
        }
      });
    } else {
      const filteredSuppliers = selectedSuppliers.filter(
        (supplier) => !suppliers.some((entity) => supplier.id === entity.id)
      );

      setSelectedSuppliers(filteredSuppliers);
    }
  };

  const columns = {
    choosen: {
      renderHeaderCell: () => {
        return (
          <Checkbox
            checked={getAmountOfSelectedSuppliers() === suppliers.length}
            onClick={handleHeaderCheckbox}
            indeterminate={getAmountOfSelectedSuppliers() > 0 && getAmountOfSelectedSuppliers() !== suppliers.length}
          />
        );
      },
      renderBodyCell: (value) => {
        const handleChangeCheckbox = () => {
          handleSelectSupplier(value);
        };
        const index = selectedSuppliers.findIndex((supplier) => supplier.id === value.id);
        return <Checkbox checked={index !== -1} onChange={handleChangeCheckbox} />;
      },
    },
    host: {
      renderHeaderCell: () => (
        <Form onSubmit={() => {}}>
          {() => (
            <SearchInput
              className={` tw-max-w-[312px] ${classes.searchInput} tw-bg-[#F7F7F7] tw-rounded-lg`}
              onSearchChange={setSearchText}
              name="search"
              placeholder="Найти поставщика"
              type="text"
              required
              value={searchText}
              size="small"
            />
          )}
        </Form>
      ),
      renderBodyCell: (value) => {
        return (
          <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center tw-max-w-[280px] tw-truncate">
            <p className="tw-text-[#0844B4] tw-text-sm tw-font-medium tw-max-w-[251px] tw-truncate hover:tw-text-[#0A50D4] tw-block">
              {value.name}
            </p>
            {value?.is_conscientious && (
              <Tooltip
                title={
                  <>
                    <p className="tw-text-xs">Добросовестный поставщик</p>
                  </>
                }
                arrow
                placement="top-start"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#1E1B39',
                      borderColor: '#1E1B39',
                      padding: '8px',
                      '& .MuiTooltip-arrow': {
                        color: '#1E1B39',
                      },
                    },
                  },
                }}
              >
                <GoodSupplierIcon className="tw-w-[23px] tw-h-[23px]" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    category: {
      label: 'Категория',
    },
    region: {
      label: 'Регион',
    },
  };
  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    isLoading,
    setPageSize,
    pageSize,
    handleChangeQuery,
  } = usePagination(getOrganizationSuppliers);
  useEffect(() => {
    setSuppliers(paginatedData);
  }, [paginatedData]);
  useEffect(() => {
    handleChangeQuery(
      `${searchText}&category_id=${searchCategoryId}&region=${searchRegion}${
        isGoodSupplier ? '&is_conscientious=true' : ''
      }`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchCategoryId, searchRegion, isGoodSupplier]);
  const handleChipDelete = (chipToDelete) => {
    setSelectedSuppliers((suppliers) => suppliers.filter((supplier) => supplier.id !== chipToDelete.id));
  };
  const handleSubmit = async (message) => {
    const ids = selectedSuppliers.map((supplier) => supplier.id);
    const body = {
      suppliers_ids: ids,
      text: message,
    };
    setIsSending(true);
    await chatsAPIs
      .sendMessageToSuppliers(body)
      .then(() => {
        createNotification('Рассылка успешно отправлена');
        dialog.close();
      })
      .catch((err) => {
        console.error(err?.message);
        createErrorNotification('Ошибка при отправке рассылки');
      })
      .finally(() => setIsSending(false));
  };
  const handleChecboxChange = (e) => {
    setIsGoodSupplier(e.target.checked);
  };
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '70%',
          minWidth: '70%',
          minHeight: 'calc(100% - 30px)',
          height: '100%',
        },
      }}
    >
      <div className="tw-bg-white tw-flex tw-flex-col tw-p-[14px] tw-pb-8 tw-rounded-lg tw-relative tw-min-w-[70%] tw-h-full">
        <CloseIcon
          className="tw-self-end tw-cursor-pointer tw-absolute tw-right-[14px] tw-top-[14px]"
          onClick={() => {
            setCurrentStep(1);
            dialog.close();
          }}
        />
        {currentStep === 1 && (
          <div className="tw-flex tw-flex-col tw-pt-[10px] tw-px-[18px]">
            <h3 className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-normal tw-mb-4">
              Рассылка по категории
            </h3>
            <p className="tw-text-[#191919] tw-text-sm tw-mb-[23px]">
              Текст, который объясняет, что делает пользователь и зачем
            </p>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mb-4">
              <ParamSelect
                options={regionsList}
                onChange={(event) => setSearchRegion(event.target.value)}
                placeholder={'Выберите регион'}
              />
              <ParamSelect
                options={categoriesList}
                onChange={(event) => setSearchCategoryId(event.target.value)}
                placeholder={'Выберите категорию'}
              />
              <div className="tw-flex tw-flex-row tw-items-center tw-bg-white tw-gap-2 tw-px-4 tw-py-[14px] tw-rounded-lg">
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  checked={isGoodSupplier}
                  onChange={handleChecboxChange}
                />
                <p>Добросовестный поставщик</p>
              </div>
            </div>
            <div className={`tw-max-h-[526px] tw-overflow-y-auto ${classes.sticky_header} tw-relative`}>
              <Table rows={suppliers} columns={columns} stickyHeader isLoading={isLoading} />
            </div>

            <div className="tw-relative tw-mb-[42px]">
              <div className="tw-absolute tw-bg-white tw-bottom-0 tw-z-10 tw-text-[#666666] tw-text-sm tw-font-medium tw-h-[52px] tw-flex tw-flex-row tw-items-center tw-justify-start tw-w-[300px]">
                <p>{`Найдено ${total} поставщиков`}</p>
              </div>
              <Pagination
                rowsPerPage={pageSize}
                count={total}
                page={currentPage}
                onPageChange={goToPage}
                onLimitChange={setPageSize}
              />
            </div>
            <Button
              disabled={selectedSuppliers.length === 0}
              className={'tw-w-fit tw-text-sm'}
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Далее
            </Button>
          </div>
        )}
        {currentStep === 2 && (
          <div className="tw-flex tw-flex-col tw-pt-[10px] tw-px-[18px] tw-h-full">
            <h3 className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-normal tw-mb-4">
              Сообщение рассылки
            </h3>
            <p className="tw-text-[#191919] tw-text-sm tw-mb-[21px]">
              Текст, который объясняет, что делает пользователь и зачем
            </p>
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-mb-8">
              <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">Кому:</p>
              <div className="tw-flex tw-flex-row tw-p-4 tw-items-center tw-gap-[10px] tw-flex-wrap tw-border tw-border-[#D5D5D5] tw-rounded-lg tw-w-full tw-max-h-[204px] tw-overflow-y-auto">
                {selectedSuppliers.map((supplier, index) => (
                  <Chip
                    icon={
                      <Avatar
                        src={supplier.logo || '/images/defaultLogo.png'}
                        className="tw-w-6 tw-h-6"
                        alt={supplier.name}
                      />
                    }
                    label={supplier.name}
                    key={index}
                    className="tw-bg-[#F4F7FE] tw-max-w-[199px] tw-truncate"
                    onDelete={() => handleChipDelete(supplier)}
                  />
                ))}
              </div>
            </div>
            <Form
              onSubmit={({ message }) => {
                handleSubmit(message);
              }}
            >
              {({ handleSubmit }) => (
                <>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-mb-[40px] tw-h-full " id="text-body">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Текст сообщения:</p>
                    <Field
                      name="message"
                      render={({ input }) => (
                        <div className="tw-w-full tw-h-full">
                          <ReactQuill
                            {...input}
                            className={`tw-w-full tw-h-full tw-text-sm tw-max-h-[465px] tw-pb-[40px] ${classes.textEditor}`}
                            bounds={'#text-body'}
                            modules={{
                              toolbar: [['bold', 'italic', 'underline'], ['link', 'image'], ['clean']],
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                    <Button
                      className={'tw-w-fit tw-text-sm tw-rounded-lg'}
                      type="submit"
                      disabled={selectedSuppliers.length === 0 || isSending}
                      onClick={handleSubmit}
                    >
                      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                        <span>Отправить</span>
                        {isSending && (
                          <CircularProgress
                            className="tw-text-white"
                            sx={{
                              height: '14px !important',
                              width: '14px !important',
                            }}
                          />
                        )}
                      </div>
                    </Button>
                    <Button
                      variant="text"
                      className="tw-text-[#666666] tw-p-0 hover:tw-bg-transparent tw-w-fit"
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      <ArrowLeft className="tw-fill-[#7F7F7F] tw-w-6 tw-h-6 tw-rotate-180" /> <span>Назад</span>
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        )}
      </div>
    </Dialog>
  );
};

const ArrowDownIcon = (props) => {
  return <ArrowDown className=" tw-pointer-events-none MuiSvgIcon-root MuiSelect-icon tw-w-6 tw-h-6" {...props} />;
};

const ParamSelect = ({ options, onChange, placeholder }) => {
  return (
    <Select
      onChange={onChange}
      displayEmpty
      defaultValue={''}
      className="tw-p-[14px] tw-pr-[10px]"
      sx={{
        '& .MuiSelect-select': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiSelect-icon': {
          top: 'calc(50% - 0.7em)',
        },
      }}
      IconComponent={ArrowDownIcon}
    >
      <MenuItem value="">
        <p className="tw-text-[#999999] tw-text-sm tw-font-medium tw-leading-[18px]">{placeholder}</p>
      </MenuItem>
      {options?.map((option, index) => (
        <MenuItem value={option.value} key={index}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};
