import DatePicker from 'react-datepicker';
import { InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import './DatePickerCalendar.css'
import moment from 'moment';

export const DatePickerUniversal = ({value, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);
  const [formattedDate, setFormattedDate] = useState(moment(value || new Date()).format('YYYY-MM-DD'));


  const formatDate = (date) => {
    if (!date) return '';
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    let formattedDate = date.toLocaleDateString('ru-RU', options);
    formattedDate = formattedDate.replace(' г.', '');
    return formattedDate;
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (date) {
      const newFormattedDate = moment(date).format('YYYY-MM-DD');
      setFormattedDate(newFormattedDate);
      onChange(newFormattedDate);
    }
  };

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
      setFormattedDate(moment(value).format('YYYY-MM-DD'));
    }
  }, [value]);


  return (
    <DatePicker
      locale="ru"
      selected={startDate}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
      onChange={handleDateChange}
      customInput={
        <InputGroup>
          <InputGroup.Text className="tw-bg-white tw-rounded-lg">
            {formatDate(startDate)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className={`${isOpen ? 'tw-rotate-180 tw-duration-300' : 'tw-rotate-0 tw-transition-transform'} tw-ml-4`}
            >
              <path
                d="M11.9988 15.5754C11.8655 15.5754 11.7362 15.5504 11.6108 15.5004C11.4855 15.4504 11.3815 15.3837 11.2988 15.3004L6.69883 10.7004C6.51549 10.5171 6.42383 10.2837 6.42383 10.0004C6.42383 9.71706 6.51549 9.48372 6.69883 9.30039C6.88216 9.11706 7.11549 9.02539 7.39883 9.02539C7.68216 9.02539 7.91549 9.11706 8.09883 9.30039L11.9988 13.2004L15.8988 9.30039C16.0822 9.11706 16.3155 9.02539 16.5988 9.02539C16.8822 9.02539 17.1155 9.11706 17.2988 9.30039C17.4822 9.48373 17.5738 9.71706 17.5738 10.0004C17.5738 10.2837 17.4822 10.5171 17.2988 10.7004L12.6988 15.3004C12.5988 15.4004 12.4905 15.4714 12.3738 15.5134C12.2572 15.5554 12.1322 15.5761 11.9988 15.5754Z"
                fill="#7F7F7F"
              />
            </svg>
          </InputGroup.Text>
        </InputGroup>
      }
    />
  )
}