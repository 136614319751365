import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { STEPS_SECTIONS } from '../../utils/constant';
import { useSelector } from 'react-redux';

export const Tours = ({ sectionName  }) =>   {
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const [run, setRun] = useState(() => {
    const showHints = localStorage.getItem(`showHints_${sectionName}`);
    return showHints === 'true';
  });
  const [stepIndex, setStepIndex] = useState(
    () => parseInt(localStorage.getItem(`stepIndexHints_${sectionName}`), 10) || 0);


  useEffect(() => {
      const showHints = localStorage.getItem(`showHints_${sectionName}`);
      if (showHints === null) {
        localStorage.setItem(`showHints_${sectionName}`, 'true');
        localStorage.setItem(`stepIndexHints_${sectionName}`, '0');
        setRun(true);
        setStepIndex(0);
      }
  }, []);

  useEffect(() => {
    localStorage.setItem(`stepIndexHints_${sectionName}`, stepIndex);
  }, [stepIndex, sectionName]);

  const joyrideCallback = (data) => {
    const { action, type, index, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem(`showHints_${sectionName}`, 'false');
      localStorage.setItem('stepIndexHints', '0');
      setRun(false)
      return;
    }

    if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1);
    }

    if (type === EVENTS.STEP_AFTER && action === ACTIONS.CLOSE) {
      localStorage.setItem(`showHints_${sectionName}`, 'false');
      localStorage.setItem(`stepIndexHints_${sectionName}`, '0');
      setRun(false);
      setStepIndex(0);
    }

    if (action === ACTIONS.SKIP) {
      localStorage.setItem(`showHints_${sectionName}`, 'false');
      localStorage.setItem(`stepIndexHints_${sectionName}`, '0');
      setRun(false);
      setStepIndex(0);
    }
  };

  const steps = STEPS_SECTIONS[sectionName] || [];
  const filteredSteps = steps.filter(step => step.roles?.includes(userRole));

  return (
    <>
      <Joyride
        steps={filteredSteps}
        continuous
        run={run}
        callback={joyrideCallback}
        stepIndex={stepIndex}
        disableOverlayClose={false}
        disableScrolling={true}
      />
    </>
  )
}