import { Dialog } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import Button from '../../button';
import { login, logout } from '../../../store/actions/auth';
import { useCallback } from 'react';
import { createErrorNotification } from '../../../utils/notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ChangePasswordModal } from '../../../pages/reset-password/components/ChangePasswordModal';

export const MobileDropdownModal = ({ user, organization, showChangeOrganization, limits }) => {
  const dialog = useDialog();
  const adminUser = sessionStorage.getItem('a_user');
  const currentUser = sessionStorage.getItem('c_user')
  const history = useHistory()

  const canReturn = adminUser && currentUser

  const handleChangeOrganization = () => {
    dialog.close();
    showChangeOrganization();
  };
  const returnToStartAccount = useCallback(async () => {
    const email = Buffer.from(adminUser.split(' ')[0], 'base64').toString('utf-8')
    const password = Buffer.from(adminUser.split(' ')[1], 'base64').toString('utf-8')
    await login({email, password}).then(() => {
      history.push('/personal-area/users')
      sessionStorage.removeItem('c_user')
    }).catch((err) => {
      console.error(err)
      createErrorNotification('Произошла ошибка при попытке вернуться в аккаунт')
    })
  }, [adminUser, history]);

  const showResetPasswordModal = useCallback(() => {
    dialog.open(<ChangePasswordModal />)
  }, [dialog])

  return (
    <Dialog open={dialog.visibility} onClick={() => dialog.close()}>
      <div
        className="tw-bg-white tw-flex tw-flex-col tw-rounded-xl tw-w-[290px] tw-gap-2 tw-pb-1"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="tw-flex tw-flex-col tw-py-4 tw-gap-[6px] tw-px-[14px] tw-w-full">
          <p className="tw-font-medium tw-text-[#191919] tw-text-sm tw-leading-[18px]">{organization?.name}</p>
          <p className="tw-text-xs tw-text-[#999999] tw-font-medium tw-leading-4">{user?.email}</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-center">
        <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={() => showResetPasswordModal()}
          >
            Изменить пароль
          </Button>
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={() => {}}
          >
            Лимит запросов {limits?.reached} из {limits?.maxLimit}
          </Button>
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={() => handleChangeOrganization()}
          >
            Изменить организацию
          </Button>
          {canReturn && (
            <Button
              variant="text"
              className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
              onClick={() => returnToStartAccount()}
            >
              Вернуться в мой аккаунт
            </Button>
          )}
          <Button
            variant="text"
            className="tw-w-fit tw-whitespace-nowrap tw-text-[#0844B4] tw-font-semibold tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
            onClick={logout}
          >
            Выход
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
