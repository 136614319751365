import moment from 'moment';
import GeneralLayout from '../../components/general-layout';
import Table from '../../components/table';
import { useGetSupportChatsUserQuery } from '../../services/supportAPIs';
import { useCallback, useMemo } from 'react';
import { getDecisionDays, supportRequestStatusFormatter } from '../../utils/common';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Form } from 'react-final-form';
import { SearchInput } from '../../components/input/search';
import { useUrlState } from '../../hooks/useUrlState';
import { Pagination } from '../../components/table/pagination';

export const SupportAnswers = () => {
  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const query = getQueryParam('query') || '';

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );
  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const { data } = useGetSupportChatsUserQuery({
    page_size,
    page_num,
    query,
  });

  const columns = useMemo(
    () => ({
      id: {
        label: 'Номер заявки',
        renderBodyCell: (value) => (
          <Link
            to={`/support-answers/${value.id}`}
            className="tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-medium"
          >
            {value?.id}
          </Link>
        ),
      },
      status: {
        label: 'Статус',
        renderBodyCell: (value) => <>{supportRequestStatusFormatter(value.status)}</>,
      },
      project: {
        label: 'Проект',
        renderBodyCell: (value) => (
          <p className="tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-medium">
            {value?.project ?? 'Не указан'}
          </p>
        ),
      },
      created_at: {
        label: 'Создано',
        renderBodyCell: (value) => <p>{moment(value.created_at).format('DD.MM.YYYY')}</p>,
      },
      decision_days: {
        label: 'Срок',
        renderBodyCell: (value) => <p>{getDecisionDays(value.created_at, value?.decision_days)}</p>,
      },
    }),
    []
  );
  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-mb-5 sm:tw-mb-[34px]">
        Ответы тех. поддержки
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-w-full">
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-pt-[25px] tw-px-6">
          <div className=" tw-max-w-[312px]">
            <Form
              onSubmit={() => {}}
              initialValues={{
                search: query,
              }}
            >
              {() => (
                <form>
                  <SearchInput name="search" placeholder="Найти" type="text" onSearchChange={handleQueryChange} />
                </form>
              )}
            </Form>
          </div>
          <Table rows={data?.data} columns={columns} />
        </div>
        <Pagination
          rowsPerPage={page_size}
          count={data?.count}
          page={data?.page_num}
          onPageChange={goToPage}
          onLimitChange={handleLimitChange}
        />
      </div>
    </GeneralLayout>
  );
};
