import { TablePagination, Pagination as PaginationMUI } from '@mui/material';
import classes from './table.module.sass';
import Button from '../button';
import { debounce } from 'lodash';

export const Pagination = ({ rowsPerPage, page = 1, count, onPageChange, onLimitChange, range }) => {
  const PaginationActions = ({ count, rowsPerPage }) => {
    const correctInput = debounce((page) => {
      if (page > Math.ceil(count / rowsPerPage)) {
        onPageChange(Math.ceil(count / rowsPerPage));
      } else if (page <= 0 || Number.isNaN(page)) {
        onPageChange(1);
      } else onPageChange(page);
    }, 500);
    return (
      <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center tw-ml-8">
        <span className="tw-min-w-[fit-content]">Перейти на страницу</span>
        <input
          type="number"
          placeholder="1"
          className={`${classes.paginationInput} tw-h-[32px] tw-w-[48px]`}
          onChange={(e) => correctInput(parseInt(e.target.value, 10))}
        />
      </div>
    );
  };
  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  const CustomLabelPerPage = ({ perPageNumbers, limit, onChange }) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-mr-auto">
        <span>Выводить по: </span>
        {perPageNumbers.map((num) => (
          <Button
            onClick={() => onChange(num)}
            variant={num === limit ? 'contained' : 'text'}
            className={`tw-p-0 tw-w-[32px] tw-h-[32px] tw-text-sm tw-font-medium ${
              num !== limit && 'tw-text-[#83A1D9]'
            }`}
            key={num}
          >
            {num}
          </Button>
        ))}
      </div>
    );
  };

  const CustomLabelDisplayedRows = () => {
    return (
      <>
        <PaginationMUI
          sx={{
            button: {
              '&.Mui-selected': {
                backgroundColor: '#0844B4',
                color: '#fff',
              },
            },
          }}
          shape="rounded"
          onChange={handlePageChange}
          count={Math.ceil(count / rowsPerPage)}
          page={page}
        />
      </>
    );
  };
  return (
    <>
      <TablePagination
        component={'div'}
        rowsPerPage={rowsPerPage}
        page={page}
        sx={{
          '& .MuiTablePagination-spacer': {
            display: 'none !important',
          },
          '& .MuiSelect-select': {
            display: 'none !important',
          },
          '& .MuiTablePagination-input': {
            display: 'none !important',
          },
          '& .MuiTablePagination-displayedRows': {
            marginLeft: 'auto',
          },
        }}
        className={`tw-w-full tw-mt-7 ${classes.pagination}`}
        count={count}
        rowsPerPageOptions={[1, 2]}
        onPageChange={onPageChange}
        ActionsComponent={PaginationActions}
        labelRowsPerPage={
          <CustomLabelPerPage perPageNumbers={range ?? [10, 50, 100]} limit={rowsPerPage} onChange={onLimitChange} />
        }
        labelDisplayedRows={CustomLabelDisplayedRows}
      />
    </>
  );
};
