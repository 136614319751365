import { useEffect, useState } from 'react';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import Alert from '../alert';

export const ConfirmModal = ({ title }) => {
  const [state, setState] = useState({
    show: false,
    onOk: () => {},
  });

  const handleClose = () => setState({ show: false });
  const handleShow = (data) => {
    setState({ ...data, show: true });
  };

  const handleOk = async () => {
    try {
      await state.onOk();
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CONFIRM_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CONFIRM_MODAL, handleShow);
    };
  }, []);

  return (
    <>
      <Alert show={state.show} onHide={handleClose} onSubmit={handleOk} title={title} />
    </>
  );
};
