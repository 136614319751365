import { request } from '../utils/request';
import { Api } from './api-client';
import * as constants from '../store/constants/organizations';

export const reportsAPIs = {
  updateAddress: (report_id, newAddress, postal_code) =>
    request(`/api/reports/${report_id}/search_address`, {
      method: 'POST',
      body: {
        address: newAddress,
        postal_code: postal_code,
      },
    }),
  selectSources: (report_id, body) =>
    request(`/api/reports/${report_id}/save_selected_sources`, {
      method: 'POST',
      body,
    }),
};

export const newReportsAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    updateReport: builder.mutation({
      query: ({ report_id, body }) => ({
        url: `/api/reports/${report_id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Report' }],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: data });
        } catch (err) {
          console.error('Error updating report', err);
        }
      },
    }),
    updateAddress: builder.mutation({
      query: ({ report_id, newAddress, postal_code }) => ({
        url: `/api/reports/${report_id}/search_address`,
        method: 'POST',
        body: {
          address: newAddress,
          postal_code: postal_code,
        },
      }),
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
  }),
});

export const { useUpdateReportMutation, useUpdateAddressMutation } = newReportsAPIs;
