import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const handbookAPIs = {
  getHandbooks: (endpoint, limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/catalogs/${endpoint}?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  addHandbook: (endpoint, body) =>
    request(`/api/admin/catalogs/${endpoint}`, {
      method: 'POST',
      body,
    }),
  deleteHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'DELETE',
    }),
  editHandbook: (endpoint, id, body) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'PATCH',
      body,
    }),
  getHandbook: (endpoint, id) =>
    request(`/api/admin/catalogs/${endpoint}/${id}`, {
      method: 'GET',
    }),
};

export const newHandbookAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadSizeExcel: builder.mutation({
      query: (file) => ({
        url: '/api/admin/catalogs/standard_sizes/upload_file',
        method: 'POST',
        body: file
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Типовые размеры успешно загружены');
      },
    }),
  }),
});

export const {useUploadSizeExcelMutation} = newHandbookAPIs