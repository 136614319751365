/* eslint-disable prefer-destructuring */
// Import Chart.js
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from 'chart.js';
import moment from 'moment';
// Import TailwindCSS variables
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../configs/tailwind.config';
// Import utilities
import { formatThousands, hexToRGB } from '../../utils/common';

// Tailwind config
const fullConfig = resolveConfig(tailwindConfig);

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

// A chart built with Chart.js 3
// https://www.chartjs.org/
export const CategoryChart = ({ data = [], labels = [] }) => {
  const ctx = document.getElementById('category-chart');
  if (!ctx) return;
  // eslint-disable-next-line no-unused-vars
  const chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      datasets: [
        // Indigo line
        {
          label: 'Current',
          data,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(fullConfig.theme.colors.blue[500])}, 0.08)`,
          borderColor: fullConfig.theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 5,
          pointBackgroundColor: fullConfig.theme.colors.indigo[500],
        },
        // // Gray line
        // {
        //   label: 'Previous',
        //   data: [
        //     8000, 5000, 6500, 5000, 6500, 12000, 8000, 9000, 8000, 8000, 12500, 10000, 10000, 12000, 11000, 16000,
        //     12000, 10000, 10000, 14000, 9000, 10000, 15000, 12500, 14000, 11000,
        //   ],
        //   borderColor: fullConfig.theme.colors.gray[300],
        //   fill: false,
        //   borderWidth: 2,
        //   tension: 0,
        //   pointRadius: 0,
        //   pointHoverRadius: 3,
        //   pointBackgroundColor: fullConfig.theme.colors.gray[300],
        // },
      ],
    },
    options: {
      layout: {
        padding: 20,
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            callback: (value) => formatThousands(value),
          },
        },
        x: {
          type: 'time',
          time: {
            parser: 'MM-DD-YYYY',
            unit: 'days',
            displayFormats: {
              month: 'MMM YY',
              days: 'DD-MM-YYYY',
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            autoSkipPadding: 48,
            maxRotation: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              if (context?.[0]?.parsed?.x) return moment(context?.[0]?.parsed?.x).format('DD-MM-YYYY');
            },
            label: (context) => formatThousands(context.parsed.y),
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
      },
      maintainAspectRatio: false,
    },
  });
  return { chart };
};

export default CategoryChart;
