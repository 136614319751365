import moment from 'moment';

import { Link } from 'react-router-dom';
import Table from '../../table';
import { Tooltip } from '@mui/material';
import { useSortableData } from '../../../hooks/useSortableData';

export const ArchivedProductsResult = ({ data }) => {
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 0,
  });
  const { items, requestSort, sortingLabel } = useSortableData(data);
  const columns = {
    name: {
      label: 'Название',
      renderBodyCell: (value) => {
        return (
          <div className="tw-w-[300px] tw-truncate">
            <Tooltip title={value.name} placement="top-start">
              <Link
                to={`/archived-projects/${value.product_sources?.[0]?.project_id}`}
                className="tw-text-[#0844B4] tw-text-sm"
              >
                {value.name}
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
    created_at: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('created_at')}>
            {sortingLabel('Дата создания', 'created_at')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <p className="tw-text-sm">{moment(value.created_at).format('DD.MM.YYYY')}</p>;
      },
    },
    unit: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('unit')}>
            {sortingLabel('Количество', 'unit')}
          </div>
        );
      },
      renderBodyCell: (value) => {
        return <p>{numberFormatter.format(value.unit)}</p>;
      },
    },
  };
  //  max height of table content is 651px
  // ! need to calculate max height of table content in %
  return (
    <div>
      <Table rows={items} columns={columns} />
    </div>
  );
};
