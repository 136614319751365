import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createErrorNotification = (message, options = { type: 'error' }) => {
  toast(message, { position: toast.POSITION.BOTTOM_RIGHT, ...options });
};

export const createNotification = (message, options = { type: 'success', color: 'blue' }) => {
  toast(message, { position: toast.POSITION.BOTTOM_RIGHT, ...options });
};
