import classNames from 'classnames';
import React from 'react';

export function RequestIcon({ isActive }) {
  return (
    <svg className="tw-shrink-0 tw-h-6 tw-w-6" viewBox="0 0 24 24">
        <path fill="white"    className={classNames('tw-fill-current ', isActive && '')}
              d="M4 12.025H11.825V10.525H4V12.025ZM4 8.775H16V7.275H4V8.775ZM4 5.525H16V4.025H4V5.525ZM0 20V1.5C0 1.11667 0.15 0.770833 0.45 0.4625C0.75 0.154167 1.1 0 1.5 0H18.5C18.8833 0 19.2292 0.154167 19.5375 0.4625C19.8458 0.770833 20 1.11667 20 1.5V14.5C20 14.8833 19.8458 15.2292 19.5375 15.5375C19.2292 15.8458 18.8833 16 18.5 16H4L0 20ZM1.5 16.375L3.375 14.5H18.5V1.5H1.5V16.375Z" ></path>

    </svg>
  );
}
