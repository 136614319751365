import { Dialog } from '@mui/material';
import { useDialog } from '../../../../../providers/dialog.provider';
import { DELIVERY_SERVICES } from '../../../../../utils/constant';
import { priceFormatter } from '../../../../../utils/priceFormatter';
import { useMemo } from 'react';
import { Tooltip } from '../../../../../components/tooltip/Tooltip';
import Table from '../../../../../components/table';
import { ReactComponent as CloseIcon } from '../../../../../icons/closeIcon.svg';

const columns = {
  delivery_service_id: {
    label: 'Транспортная компания',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">
          {DELIVERY_SERVICES[value.delivery_service_id]}
        </p>
      );
    },
  },
  price: {
    renderHeaderCell: () => {
      return (
        <p className="tw-text-[#666666] tw-flex tw-flex-row tw-items-center tw-gap-1">
          Цена
          <Tooltip title={`Цена рассчитана за доставку сборного груза (всех товаров проекта)`} />
        </p>
      );
    },
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
          {!value?.price ? '-' : priceFormatter(value?.price, 2)}
          <span className="tw-ml-1">₽</span>
        </p>
      );
    },
  },
};

export const DeliveryInfoModal = ({ delivery_info }) => {
  const dialog = useDialog();

  const validDeliveryPrices = useMemo(
    () => Object.entries(delivery_info)
      .filter(([key, price]) => price !== 0 && price !== null)
      .map(([key, price]) => ({ delivery_service_id: key, price })), 
    [delivery_info]
  );

  return (
    <Dialog open={dialog.visibility} sx={{
        '& .MuiPaper-root': {
          minWidth: '584px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}>
      <div className="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 tw-relative">
        <CloseIcon className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" onClick={() => dialog.close()} />
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-5 tw-mb-6">Информация о доставке</p>
        <Table rows={validDeliveryPrices} columns={columns} />
      </div>
    </Dialog>
  );
};
