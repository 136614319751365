import * as constants from '../constants/auth';

const initialState = {
  user: null,
  error: false,
  regions: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case constants.AUTH__LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case constants.AUTH__ERROR:
      return {
        ...state,
        error: action.status,
      };
    case constants.AUTH__LOGOUT:
      return initialState;
    case constants.AUTH__SET_REGIONS:
      return {
        ...state,
        regions: action.regions,
      };
    default:
      return state;
  }
}
