import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ChevronDownIcon from '../../icons/chevron-down';
import EmptyImageIcon from '../../icons/empty-image';
import { getWordEnding1 } from '../../utils/common';
import { IMAGES_CATEGORIES } from '../../utils/constant';
import SavedSourcesList from '../saved-sources-list';
import classes from './product-statistics-card.module.sass';

export default function ProductStatisticCardChart({ product, onReloadSavedSources }) {
  const [showProductSources, setShowProductSources] = useState(false);
  const [selected, setSelected] = useState(0);
  const createdAt = moment(product.created_at).format('DD.MM.YYYY');
  const getPicture = () => {
    if (product.img) {
      return product.img;
    }
    if (IMAGES_CATEGORIES.includes(product.category)) {
      const url = `/images/categories/${product.category}.svg`;
      return url;
    }
    return null;
  };

  const firstPic = useMemo(() => getPicture(), [product]);

  const sources = useMemo(() => product.product_sources, [product]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.summary}>
            <div className={classes.images}>
              <div className={classes.imageItem} style={{ backgroundImage: `url(${firstPic})` }}>
                {!firstPic && <EmptyImageIcon />}
              </div>
            </div>
            <div>
              <h5 className={classes.title}>
                <p>{product.name}</p>
              </h5>
              <div className={classes.desc}>
                <div>
                  <p className={classes.text}>от {createdAt}</p>
                </div>
              </div>
              {product.category && <p>Категория: {product.category}</p>}
            </div>
          </div>

          <div className={classes.link}>
            <a href={product.source} target="_blank" rel="noopener noreferrer">
              {product.source}
            </a>
          </div>
        </div>

        <div className={classes.prices}>
          <div className={classes.linkDown} onClick={() => setShowProductSources(!showProductSources)}>
            Найдено {sources?.length ?? 0} {getWordEnding1('источник', sources?.length)}
            <br />
            <div className={showProductSources ? classes.downIcon : ''}>
              <ChevronDownIcon />
            </div>
          </div>
        </div>
      </div>

      {showProductSources && (
        <div className={classNames(classes.productSources, 'tw-grid tw-grid-cols-12 tw-p-0')}>
          <div className="tw-flex tw-flex-col tw-col-span-full xl:tw-col-span-3 tw-bg-white tw-rounded-sm tw-border tw-border-r-0 tw-border-gray-200">
            <header className="tw-px-5 tw-py-4 tw-border-b tw-border-gray-100">
              <h2 className="tw-font-semibold tw-text-gray-800 tw-text-left">Все источники</h2>
            </header>
            <div className="tw-flex tw-flex-col tw-h-full">
              <div className="tw-grow tw-p-3" style={{ maxHeight: '280px', overflow: 'scroll' }}>
                <div className="tw-overflow-x-auto">
                  <table className="tw-table-auto tw-w-full">
                    <tbody className="tw-text-sm tw-divide-y tw-divide-gray-100">
                      {sources?.map((source, i) => (
                        <tr
                          key={i}
                          className={classNames(
                            'tw-cursor-pointer hover:tw-bg-stone-100',
                            selected === i && 'tw-bg-stone-100'
                          )}
                          onClick={() => setSelected(i)}
                        >
                          <td className="tw-py-2 tw-pl-2">
                            <div className="tw-text-left"> {new URL(source.source).hostname}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="tw-text-right tw-px-5 tw-pb-4">
          <a className="tw-text-sm tw-font-medium tw-text-indigo-500 hover:tw-text-indigo-600" href="#0">
            Real-Time Report
          </a>
        </div> */}
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-col-span-full xl:tw-col-span-9 tw-bg-white tw-rounded-sm tw-border tw-border-gray-200">
            <SavedSourcesList
              product={sources?.[selected]}
              index={selected}
              onReloadSavedSources={onReloadSavedSources}
            />
          </div>
          {/* {product.product_sources &&
            product.product_sources.map((source, sourceIndex) => (
              <SavedSourcesList
                key={sourceIndex}
                product={source}
                index={sourceIndex}
                onReloadSavedSources={onReloadSavedSources}
              />
            ))} */}
        </div>
      )}
    </>
  );
}
