import { ReactComponent as SearchIcon } from '../../icons/searchIcon.svg';
import { OnChange } from 'react-final-form-listeners';
import classes from './input.module.sass';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import Input from './index';

export function SearchInput({ value, onSearchChange, ...props }) {
  const onSearchChangeDebounced = useCallback(debounce(searchText => {
    onSearchChange(searchText)
  }, 500), [onSearchChange])

  return (
    <>
      <Input
        sx={{
          '.MuiInputBase-root': {
            transition: 'background-color 0.5s ease',
            backgroundColor: '#F7F7F7',
            '&:hover': {
              backgroundColor: '#EEE'
            }
          },
          'fieldset': {
            border: 'none !important'
          }
        }}
        SearchProps={{
          startAdornment: <SearchIcon className={classes.searchIcon} />,
        }}
        {...props}
      />
      <OnChange name={props.name}>
        {(values) => {
          onSearchChangeDebounced(values)
        }}
      </OnChange>
    </>
  );
};
