import { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { createProject, updateProject, uploadProjectFile } from '../../store/actions/organizations';
import { projectNameValidator } from '../../utils/common';
import Button from '../button';
import Input from '../input';
import classes from './add-project.module.sass';

export default function AddProjectModal({ show, onHide }) {
  const history = useHistory();
  const [createdProject, setCreatedProject] = useState(null);
  const [isFileLoading, setFileLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!createdProject) return;
      setFileLoading(true);
      setError(false);
      await uploadProjectFile(createdProject.id, acceptedFiles[0]).then((res) => {
        if (res === null) {
          setError(true);
          return;
        }
        setError(false);
      });
      setCreatedProject(null);
      setFileLoading(false);
    },
    [createdProject]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/png',
      'application/pdf',
    ],
    maxFiles: 1,
  });

  const onSubmit = async ({ name, region_id }, form) => {
    const project = await createProject({ name, region_id });
    if (!project) {
      return { name: 'Такой проект уже существует' };
    }
    onHide();
    history.push(`/projects/${project.id}`);
  };

  if (!show) {
    return null;
  }

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Input
              name="name"
              placeholder="Введите название проекта"
              className={classes.input}
              validate={projectNameValidator}
              disabled={isFileLoading}
              required
            />
            <div {...getRootProps()} className={classes.buttonHolder}>
              <input {...getInputProps()} />
              <Button onClick={handleSubmit} className={classes.button}>
                Добавить
              </Button>
            </div>
          </form>
        )}
      </Form>
      {error && <p>Ошибка загрузки</p>}
    </Modal>
  );
}
