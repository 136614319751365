import React, { useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { useGetFaqsQuery } from '../../services';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactComponent as ExpandIcon } from '../../icons/expandIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as EditIconWhite } from '../../icons/editIconWhite.svg';
import Button from '../../components/button';
import { useDialog } from '../../providers/dialog.provider';
import { FaqModal } from '../../components/modal/FaqModal';
import { checkIsAdmin } from '../../utils/common';
import { useSelector } from 'react-redux';
import { FaqRemoveModal } from '../../components/modal/FaqRemoveModal';
import { FaqEditModal } from '../../components/modal/FaqEditModal';

const AccordionMUI = ({ isExpanded, name, handleChange, header, content, faq_id, file_links }) => {
  const textColor = isExpanded ? 'tw-text-[#FFF]' : 'tw-text-[#191919]';
  const dialog = useDialog();
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    dialog.open(<FaqRemoveModal header={header} faq_id={faq_id}/>);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    dialog.open(<FaqEditModal initialValues={{ id: faq_id, header, content, uploaded_files:file_links?.[0]}} />);
  };
  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={handleChange(name)}
        sx={{
          '&.MuiAccordion-root': {
            borderRadius: '8px',
            boxShadow: 'none',
            backgroundColor: isExpanded ? '#0A3D9B' : '#FFF',
            '&::before': {
              height: '0px',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          sx={{
            '&.MuiAccordionSummary-root': {
              padding: '0 32px',
              minHeight: '66px',
            },
          }}
        >
          <div className="tw-flex tw-justify-between tw-w-full">
            <p className={`tw-text-[18px] tw-leading-[22px] tw-font-semibold ${textColor}`}>{header}</p>
            {isAdmin && (
              <div className="tw-flex tw-items-center">
                <DeleteIcon className="tw-mr-4" onClick={handleDeleteClick} />
                {isExpanded ? (
                  <EditIconWhite className="tw-mr-4" onClick={handleEditClick} />
                ) : (
                  <EditIcon className="tw-mr-4" onClick={handleEditClick} />
                )}
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              padding: '2px 32px 30px',
            },
          }}
        >
          <p
            className={`${textColor} tw-text-[16px] tw-leading-5 tw-font-medium tw-max-w-[87%] tw-whitespace-pre-line`}
          >
            {content}
          </p>
          {file_links?.length > 0 && (
            <img
              className="tw-max-w-[415px] tw-max-h-96 tw-mb-6 tw-rounded-lg tw-mt-2 tw-w-full"
              src={file_links[0]}
              alt="article_img"
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const FaqPage = () => {
  const [expanded, setExpanded] = useState('panel0');
  const dialog = useDialog();
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const { data: faqs } = useGetFaqsQuery();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenModalFaq = () => {
    dialog.open(<FaqModal />);
  };

  return (
    <>
      <GeneralLayout>
        <h1 className="tw-text-[#191919] tw-font-semibold tw-text-[32px] tw-leading-[38px] tw-mb-[40px]">FAQ</h1>
        {isAdmin && (
          <div className="tw-mb-8">
            <Button onClick={handleOpenModalFaq} className="tw-rounded-lg lg:tw-w-[93px] md:tw-w-[93px] sm:tw-w-[80%]">
              <svg
                width="20"
                height="20"
                className="tw-mr-2"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.325 15H10.825V10.85H15V9.35H10.825V5H9.325V9.35H5V10.85H9.325V15ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3583 0 9.975C0 8.60833 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.64167 0 10.025 0C11.3917 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10.025 18.5C12.375 18.5 14.375 17.6708 16.025 16.0125C17.675 14.3542 18.5 12.3417 18.5 9.975C18.5 7.625 17.675 5.625 16.025 3.975C14.375 2.325 12.3667 1.5 10 1.5C7.65 1.5 5.64583 2.325 3.9875 3.975C2.32917 5.625 1.5 7.63333 1.5 10C1.5 12.35 2.32917 14.3542 3.9875 16.0125C5.64583 17.6708 7.65833 18.5 10.025 18.5Z"
                  fill="white"
                />
              </svg>
              Добавить вопрос – ответ
            </Button>
          </div>
        )}
        <div className="tw-flex tw-flex-col tw-gap-3">
          {faqs?.data?.length > 0 &&
            faqs.data.map((data, index) => (
              <>
                <AccordionMUI
                  content={data?.content}
                  isExpanded={expanded === `panel${index}`}
                  name={`panel${index}`}
                  key={data?.header}
                  handleChange={handleChange}
                  header={data?.header}
                  faq_id={data?.id}
                  file_links={data?.file_links}
                />
              </>
            ))}
        </div>
      </GeneralLayout>
    </>
  );
};
