import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useUrlState = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Helper function to get the current URL search params as an object
  const getQueryParams = useCallback(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);

  // Function to set a URL state by updating the query parameter
  const setQueryParam = useCallback(
    (key, value) => {
      if (value === null || value === undefined) {
        searchParams.delete(key); // Remove if the value is null/undefined
      } else {
        searchParams.set(key, value); // Set/Update the query param
      }

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, searchParams]
  );

  // Function to get the value of a specific query parameter
  const getQueryParam = useCallback(
    (key) => {
      return searchParams.get(key);
    },
    [searchParams]
  );

  return {
    getQueryParams, // Get all query params as an object
    getQueryParam, // Get a specific query param
    setQueryParam, // Set or update a specific query param
  };
};
