import GeneralLayout from '../../components/general-layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddProjectModal from '../../components/add-project-modal';
import CreateProjectForm from './components/CreateProjectForm';
import WelcomeBanner from './components/WelcomeBanner';
import classes from './landing.module.sass';
import { checkIsAdmin } from '../../utils/common';
import { UserGuide } from '../../components/user-guide';
import NewsWidget from '../../components/widgets/newsWidget/NewsWidget';
import TechSupportWidget from '../../components/widgets/techSupportWidget/TechSupportWidget';
import InstructionsWidget from '../../components/widgets/instructionsWidget/InstructionsWidget';
import { Tours } from '../../components/tour/Tours';
import { Footer } from '../../components/footer';

export default function LandingPage() {
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <GeneralLayout bg="white">
      <Tours sectionName="deskTour" />
      <h1
        className="tw-inline-flex tw-text-[#191919] tw-text-2xl sm:tw-text-[32px] tw-font-semibold tw-mb-8 md:tw-leading-[38px] tw-leading-[28px]">
        Рабочий стол
      </h1>
      <div className={`${classes.root} ${isAdmin ? classes.layout : classes.layoutNotAdmin}`}>
        <div className={classes.welcomeBanner}>
          <WelcomeBanner />
        </div>
        <div className={classes.newsWidget}>
          <div className={` tw-h-full ${classes.recentlyActivity}`}>
            <NewsWidget />
          </div>
        </div>
        <div className="tw-flex-col tw-h-full">
          <CreateProjectForm />
        </div>
        <div>
          <TechSupportWidget />
        </div>
        <div>
          <InstructionsWidget />
        </div>
      </div>
      <div className="tw-mt-4">
        <Footer />
      </div>
      <AddProjectModal show={showAddProjectModal} onHide={() => setShowAddProjectModal(false)} />
      <UserGuide />
    </GeneralLayout>
  );
}
