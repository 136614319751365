import * as constants from '../constants/statistics';
import { AUTH__LOGOUT } from '../constants/auth';

const initialState = {
  dates: {
    since: null,
    till: null,
  },
  statistics: null,
};

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.STATISTICS__GET:
      return {
        ...state,
        statistics: action.statistics,
      };
    case constants.STATISTICS__SET_DATE:
      return {
        ...state,
        dates: {
          since: action.since,
          till: action.till,
        },
      };
    case AUTH__LOGOUT:
      return initialState;
    default:
      return state;
  }
}
