import * as constants from '../constants/organizations';
import { AUTH__LOGOUT } from '../constants/auth';

const initialState = {
  list: [],
  selected: null,
  showSelectModal: false,
  showSelectModalCloseBtn: false,
  projectList: [],
  canLoadMoreProjects: true,
  canLoadMoreArchivedProjects: true,
  canLoadMoreArchivedProducts: true,
  productList: [],
  selectedReport: null,
  savedSources: [],
  archivedProjectList: [],
  archivedProductList: [],

  showArchive: false,
  archivedSources: [],
  showRequestPrice: false,
  priceRequests: [],
  categories: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case constants.ORGANIZATIONS__GET_LIST:
      return {
        ...state,
        list: action.organizations,
      };
    case constants.ORGANIZATIONS__ADD_ORGANIZATION:
      return {
        ...state,
        list: [action.organization, ...state.list],
      };
    case constants.ORGANIZATIONS__UPDATE_ORGANIZATION: {
      const isSelected = state.selected && state.selected.id === action.organization.id;
      return {
        ...state,
        list: state.list.map((o) => {
          if (o.id === action.organization.id) {
            return action.organization;
          }
          return o;
        }),
        selected: isSelected ? action.organization : state.selected,
      };
    }
    case constants.ORGANIZATIONS__DELETE_ORGANIZATION: {
      const isSelected = state.selected && state.selected.id === action.id;
      return {
        ...state,
        list: state.list.filter((o) => o.id !== action.id),
        selected: isSelected ? null : state.selected,
        showSelectModal: isSelected,
      };
    }
    case constants.ORGANIZATIONS__GET_PRODUCTS:
      return {
        ...state,
        productList: action.products,
      };
    case constants.ORGANIZATIONS__LOAD_MORE_PRODUCTS:
      return {
        ...state,
        productList: [...state.productList, ...action.products],
      };
    case constants.ORGANIZATIONS__SET_SELECTED:
      return {
        ...state,
        selected: action.organization,
        showSelectModal: false,
      };
    case constants.ORGANIZATIONS__TOGGLE_SELECT_MODAL:
      return {
        ...state,
        showSelectModal: !state.showSelectModal,
      };
    case constants.ORGANIZATIONS__TOGGLE_SELECT_MODAL_CLOSEBTN:
      return {
        ...state,
        showSelectModalCloseBtn: action.showSelectModalCloseBtn,
      };
    case constants.ORGANIZATIONS__GET_PROJECTS:
      return {
        ...state,
        projectList: action.upsert ? [...state.projectList, ...action.projects] : action.projects,
        canLoadMoreProjects: action.canLoadMoreProjects,
      };
    case constants.ORGANIZATIONS__GET_ARCHIVED_PROJECTS:
      return {
        ...state,
        archivedProjectList: [...state.archivedProjectList, ...action.projects],
        canLoadMoreArchivedProjects: action.canLoadMoreProjects,
      };
    case constants.ORGANIZATIONS__GET_ARCHIVED_PRODUCTS:
      return {
        ...state,
        archivedProductList: action.refresh
          ? action.archivedProducts
          : [...state.archivedProductList, ...action.archivedProducts],
        canLoadMoreArchivedProducts: action.canLoadMoreArchivedProducts,
      };
    case constants.ORGANIZATIONS__ADD_PROJECT:
      return {
        ...state,
        projectList: [action.project, ...state.projectList],
      };
    case constants.ORGANIZATIONS__SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: action.report,
      };
    case constants.ORGANIZATIONS__CLEAN_PRODUCTS:
      return {
        ...state,
        selectedReport: null,
        productList: [],
      };
    case constants.ORGANIZATIONS__DELETE_PRODUCT:
      return {
        ...state,
        productList: state.productList.filter((product) => product.id !== action.id),
      };
    case constants.ORGANIZATIONS__DELETE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.filter((p) => p.id !== action.id),
      };
    case constants.ORGANIZATIONS__REMOVE_ARCHIVED_PROJECT:
      return {
        ...state,
        archivedProjectList: state.archivedProjectList.filter((p) => p.id !== action.id),
      };
    case constants.ORGANIZATIONS__UPDATE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.map((p) => {
          if (p.id === action.project.id) {
            return action.project;
          }
          return p;
        }),
      };
    case constants.ORGANIZATIONS__UPDATE_PRODUCT:
      return {
        ...state,
        productList: state.productList.map((p) => {
          if (p.id === action.product.id) {
            return action.product;
          }
          return p;
        }),
        selectedReport: action.report,
      };
    case constants.ORGANIZATIONS__SET_SAVED_SOURCES:
      return {
        ...state,
        savedSources: action.savedSources,
      };
    case constants.ORGANIZATIONS__SET_SHOW_ARCHIVE:
      return {
        ...state,
        showArchive: action.hideArchive ? false : !state.showArchive,
      };
    case constants.ORGANIZATIONS__SET_ARCHIVED_SOURCES:
      return {
        ...state,
        archivedSources: action.archivedSources,
      };
    case constants.ORGANIZATIONS__SET_SHOW_REQUEST_PRICE:
      return {
        ...state,
        showRequestPrice: action.hide ? false : !state.showRequestPrice,
      };
    case constants.ORGANIZATIONS__SET_PRICE_REQUESTS:
      return {
        ...state,
        priceRequests: action.priceRequests,
      };
    case constants.ORGANIZATIONS_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case AUTH__LOGOUT:
      return initialState;
    default:
      return state;
  }
}
