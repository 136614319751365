import { useCallback } from 'react';
import { useDialog } from '../../../../providers/dialog.provider';
import { Dialog, DialogActions } from '@mui/material';
import { Form } from 'react-final-form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { convertStringToURL, isValidUrl } from '../../../../utils/common';

export const ProductAddSupplier = ({ onSubmit }) => {
  const dialog = useDialog();
  const handleFormSubmit = useCallback(
    async (payload) => {
      const { url, price } = payload;
     
        const validUrl = convertStringToURL(url);

        await onSubmit(Number(price), validUrl.href).then(() => {
          dialog.close();
        });
    },
    [dialog, onSubmit]
  );

  const validate = (values) => {
    const errors = {};

    const parsedPrice = Number(values.price);

    if (!/^\d+(\.\d{1,2})?$/.test(values?.price)) {
      errors.price = 'Цена должна иметь до 2х знаков после запятой';
    }
    if (!Number.isNaN(parsedPrice) && parsedPrice < 0) {
      errors.price = 'Введите корректную цену больше 0';
    }

    if (!isValidUrl(values?.url)) {
      errors.url = 'Введите корректный URL';
    }
    return errors;
  };

  return (
    <Dialog open={dialog.visibility}>
      <Form onSubmit={handleFormSubmit} validate={validate} validateOnBlur initialValues={{ url: '', price: '' }}>
        {({ handleSubmit, form, submitting, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-lg lg:tw-w-[440px] tw-flex tw-flex-col ">
              <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Добавление источника</p>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-14">
                <Input
                  form={form}
                  name="url"
                  type="text"
                  className={`${invalid && 'tw-mb-2'}`}
                  required
                  placeholder={'Ссылка на источник'}
                />
                <Input form={form} name="price" type="number" required placeholder={'Цена в рублях'} />
              </div>
              <DialogActions className="tw-gap-6 tw-p-0">
                <Button
                  variant="text"
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                  disabled={submitting}
                  onClick={() => dialog.close()}
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={pristine || submitting || invalid}
                >
                  Добавить источник
                </Button>
              </DialogActions>
            </div>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
