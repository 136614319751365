import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg';
import { ReactComponent as VKLogo } from '../../../icons/vk.svg';
import { ReactComponent as TelegramLogo } from '../../../icons/telegram.svg';
import { ReactComponent as WhatsappLogo } from '../../../icons/whatsapp.svg';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import classes from '../suppliers.module.sass';

const TextBlock = ({ title, text }) => {
  const formatText = (text) => {
    if (text === '' || !text) return 'Нет данных';
    if (Array.isArray(text))
      return (
        <div className="tw-flex tw-flex-col">
          {text.map((value, index) => {
            return <span key={index}>{value}</span>;
          })}
        </div>
      );
    return text;
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-[3px]">
      <h6 className="tw-text-sm tw-text-[#666]">{title}</h6>
      <p className="tw-text-base tw-text-[#191919] tw-break-words tw-max-w-[406px]">{formatText(text)}</p>
    </div>
  );
};

export const InfoDialog = ({ entity }) => {
  const dialog = useDialog();
  const [currentTab, setCurrentTab] = useState('1');
  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };
  const mobile = useMediaQuery('(max-width: 500px)');
  return (
    <Dialog open={dialog.visibility}>
      <div className={`tw-bg-white tw-flex tw-flex-col tw-p-[14px] tw-rounded-lg ${!mobile && 'tw-min-w-[590px]'}`}>
        <CloseIcon className="tw-self-end tw-cursor-pointer tw-absolute tw-right-[14px] tw-top-[14px] tw-z-10" onClick={() => dialog.close()} />
        <div className="tw-pr-[10px] tw-pl-[10px] tw-pb-[10px] tw-pt-0 tw-flex tw-flex-col">
          <TabContext value={currentTab}>
            <div className={classes.tabs}>
              <TabList onChange={handleTabChange}>
                <Tab label="Информация" value="1" className="tw-normal-case tw-p-0" />
              </TabList>
            </div>
            <TabPanel value="1" className='tw-px-0 tw-pb-2 tw-pt-8 tw-overflow-y-auto'>
              <div className={`tw-inline-flex tw-flex-row tw-gap-6 tw-mb-6`}>
                <div className=" tw-inline-flex tw-flex-col tw-gap-4">
                  <TextBlock title="Название" text={entity.name} />
                  <div className="tw-flex tw-flex-row tw-gap-[26px]">
                    <TextBlock title="ИНН" text={entity.inn} />
                    <TextBlock title="КПП" text={entity.kpp} />
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-[18px]">
                <TextBlock title="Управляющий делами:" text={entity.manager} />
                <TextBlock title="Категория:" text={entity.category} />
                <TextBlock title="Адрес:" text={entity.address[0]} />
                <TextBlock title="E-mail:" text={Array.isArray(entity.email) ? entity.email[0] : entity.email} />
                <TextBlock title="Регион:" text={entity.region} />
                <TextBlock
                  title="Ссылка:"
                  text={
                    <a
                      className="tw-cursor-pointer tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-max-w-[500px] tw-truncate"
                      href={entity.link}
                    >
                      {entity.link}
                    </a>
                  }
                />
                <div className="tw-flex tw-flex-row tw-gap-[14px]">
                  {entity.social_medias.vk && (
                    <a href={entity.social_medias.vk}>
                      <VKLogo className="tw-w-6 tw-h-6 " />
                    </a>
                  )}
                  {entity.social_medias.vk && (
                    <a href={entity.social_medias.tg}>
                      <TelegramLogo className="tw-w-6 tw-h-6 " />
                    </a>
                  )}
                  {entity.social_medias.wa && (
                    <a href={entity.social_medias.tg}>
                      <WhatsappLogo className="tw-w-6 tw-h-6 " />
                    </a>
                  )}
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </Dialog>
  );
};
