import { Modal } from 'react-bootstrap';
import Button from '../button';
import classes from './alert.module.sass';

export default function Alert({ show, onHide, onSubmit, title, additionalButton }) {
  const handleSubmit = async () => {
    await onSubmit();
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.actions}>
          {additionalButton && (
            <Button className={classes.cancel} onClick={additionalButton.onClick}>
              {additionalButton.label}
            </Button>
          )}
          <Button className={classes.cancel} onClick={onHide}>
            Отменить
          </Button>
          <Button className={classes.ok} onClick={handleSubmit}>
            Ок
          </Button>
        </div>
      </div>
    </Modal>
  );
}
