import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as MessageIcon } from '../../../icons/messageIcon.svg';
import Button from '../../../components/button';
import { Field, Form } from 'react-final-form';
import { TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSendAnswerToSupportTicketMutation } from '../../../services/supportAPIs';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const SupportAnswersChat = ({ chat, isTicketClosed, isAdmin }) => {
  const [isAnswering, setIsAnswering] = useState(false);
  const { id } = useParams();

  const [sendMessage, { isLoading }] = useSendAnswerToSupportTicketMutation();
  const handleOpenAnswerForm = () => {
    setIsAnswering(true);
  };
  const handleCloseAnswerForm = () => {
    setIsAnswering(false);
  };

  const handleSendMessage = useCallback(
    async (values, form) => {
      await sendMessage({
        support_request_id: id,
        text: values.text,
      })
        .unwrap()
        .then(() => {
          form.reset();
          setIsAnswering(false);
        });
    },
    [id, sendMessage]
  );
  return (
    <div className={`tw-flex tw-flex-col tw-w-full ${!isAdmin && 'tw-gap-6'} tw-max-w-[916px]`}>
      <div className="tw-flex tw-flex-col tw-items-start tw-w-full tw-divide-y tw-divide-y-[#D5D5D5] tw-max-h-[400px] tw-overflow-hidden tw-overflow-y-auto">
        {chat?.length > 0 &&
          chat.map((message) => (
            <SupportAnswer
              text={message?.text}
              date={message?.created_at}
              isAdmin={message?.is_support_message}
              key={message.id}
            />
          ))}
      </div>
      {isTicketClosed ? (
        isAdmin ? null : (
          <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold">
            Заявка закрыта, при необходимости откройте новую.
          </p>
        )
      ) : !isAnswering ? (
        <Button
          variant="text"
          onClick={handleOpenAnswerForm}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-flex tw-flex-row tw-items-center tw-gap-[6px] tw-text-[#134B98] tw-leading-5 tw-font-medium"
        >
          <MessageIcon /> Ответить
        </Button>
      ) : (
        <Form
          onSubmit={handleSendMessage}
          initialValues={{
            text: '',
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <form className="tw-flex tw-flex-col tw-items-start" onSubmit={handleSubmit}>
              <p className="tw-leading-5 tw-font-medium tw-mb-3 tw-text-[#191919]">Ответ</p>
              <Field
                name="text"
                render={({ input }) => (
                  <TextField {...input} required placeholder="Написать ответ" multiline maxRows={6} fullWidth />
                )}
              />
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-[22px] tw-mt-5">
                <Button
                  type="submit"
                  disabled={pristine || invalid || isLoading}
                  className="tw-w-fit tw-py-3 tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-text-white tw-font-semibold tw-bg-[#134B98] disabled:tw-bg-[#8CA6C1]"
                >
                  Ответить
                </Button>
                <Button
                  variant="text"
                  onClick={handleCloseAnswerForm}
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
                >
                  Отменить
                </Button>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};

export const SupportAnswer = ({ text, date, isAdmin }) => {
  const user = useSelector((state) => state?.auth?.user);
  return (
    <div className="tw-flex tw-flex-row tw-py-4 tw-gap-3 tw-w-full">
      {isAdmin ? (
        <img src="/images/support_answerImg.png" className="tw-w-10 tw-min-w-10 tw-h-10 tw-min-h-10" alt="admin_img" />
      ) : (
        <span className="tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-min-w-10 tw-min-h-10 tw-rounded-full tw-bg-[#0844B4] tw-uppercase tw-text-white">
          {(user?.full_name?.[0] || '') + (user?.full_name?.[1] || '')}
        </span>
      )}
      <div className="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2">
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">{text}</p>
        <p className="tw-text-[#7F7F7F] tw-text-sm tw-leading-[18px]">{moment(date).format('DD.MM.YYYY в HH:mm')}</p>
      </div>
    </div>
  );
};
