import React from 'react';
import GeneralLayout from '../../components/general-layout';

function DocumentsPage() {
  return (
    <GeneralLayout>
      <div></div>
    </GeneralLayout>
  );
}

export default DocumentsPage;
