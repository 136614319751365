import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const TextArea = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    input: {
      color: '#333333',
      fontSize: '0.875rem',
      padding: '14px 16px',
    },
    fieldset: {
      border: '1px solid #CCC',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #D5D5D5',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#CCC',
    },
    '&:focus fieldset': {
      borderColor: '#000',
    },
  },
}));