import { useCallback, useEffect, useState } from 'react';
import { checkIsAdmin } from '../utils/common';
import { organizationsAPIs } from '../services';
import { useSelector } from 'react-redux';


export const useOrganizationLimits = (user) => {
  const [limits, setLimits] = useState({
    reached: 0,
    maxLimit: 0,
  });

  const isAdmin = checkIsAdmin(user);
  const organization_id = useSelector((state) => state?.organizations?.selected?.id);

  const fetchOrg = useCallback(async (organization_id) => {
    try {
      if (isAdmin) {
        return  await organizationsAPIs.getAdminOrganization(organization_id);
      } else {
        return await organizationsAPIs.getUserOrganization(organization_id);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [isAdmin]);

  useEffect(() => {
    const fetchInitialLimits = async () => {
      try {
        const res = await fetchOrg(organization_id);
        setLimits({
          reached: res.count_scan_in_month,
          maxLimit: res.scan_limit_max,
        });
      } catch (err) {
        console.error(err);
      }
    };
    if(organization_id){
      fetchInitialLimits();
    }
  }, [fetchOrg, organization_id]);

  return limits;
}