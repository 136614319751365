import { request } from '../utils/request';

export const chatsAPIs = {
  getChats: (query) =>
    request(`/api/chats${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  getMessages: (chatId) =>
    request(`/api/chats/${chatId}`, {
      method: 'GET',
    }),
  sendMessage: (chatId, body) =>
    request(`/api/chats/${chatId}`, {
      method: 'POST',
      body,
    }),
  getChatSupplier: (reportId, productId) =>
    request(`/api/reports/${reportId}/products/${productId}/suppliers`, {
      method: 'GET',
    }),
  getSuppliers: (organizationId, query) =>
    request(`/api/organizations/${organizationId}/suppliers${query ? `?${query}` : ''}`, {
      method: 'GET',
    }),
  getSupplierChats: (supplierId) =>
    request(`/api/chats/suppliers/${supplierId}`, {
      method: 'POST',
    }),
  sendMessageFromProduct: (productId, supplierId, body) =>
    request(`/api/chats/suppliers/${productId}/${supplierId}`, {
      method: 'POST',
      body,
    }),
  getSuppliersProduct: (reportId, productId) =>
    request(`/api/reports/${reportId}/products/${productId}/suppliers`, {
      method: 'GET',
    }),
  addPrice: (chatId) =>
    request(`/api/chats/${chatId}/add_price`, {
      method: 'POST',
    }),
  sendMessageToSuppliers: (body) =>
    request('/api/chats/send_newsletter_to_chats', {
      method: 'POST',
      body,
    }),
};
