import { useCallback, useState } from 'react';
import Button from '../../../components/button';
import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon.svg';
import { downloadFile } from '../../../utils/request';
import { createErrorNotification } from '../../../utils/notifications';
import Loader from '../../../components/loader';

export const UsersReport = ({ report }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadFile = useCallback(async (downloadUrl, documentName) => {
    setIsLoading(true);
    await downloadFile(downloadUrl, `${documentName}.xlsx`)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        createErrorNotification('Произошла ошибка при попытке скачивания файла');
      });
  }, []);

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-py-5" key={report.downloadUrl}>
        <p className="tw-text-[#191919]">{report.title}</p>
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
          onClick={() => handleDownloadFile(report.downloadUrl, report.documentName)}
        >
          {isLoading ? <Loader /> : <DownloadIcon />}
        </Button>
      </div>
    </div>
  );
};
