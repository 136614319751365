import { Button as ButtonMUI } from '@mui/material';
import styled from '@emotion/styled';

const StyledButton = styled(ButtonMUI)(() => ({
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: '600',
  fontFamily: 'Inter',
  lineHeight: '1.25rem',
  padding: '0.875rem 1.125rem',
  minWidth: 'fit-content',
  cursor: 'pointer'
}));

export default function Button({
  children,
  variant = 'contained',
  type = 'button',
  onClick,
  disabled = false,
  className,
}) {
  return (
    <StyledButton
      className={className}
      variant={variant}
      type={type}
      onClick={onClick}
      disabled={disabled ?? false}
      fullWidth
      disableRipple
      disableTouchRipple
    >
      {children}
    </StyledButton>
  );
}
