import { useParams } from 'react-router-dom';
import GeneralLayout from '../../components/general-layout';
import { useGetProjectReportQuery, useStopSearchMutation } from '../../services';
import { ProductsList } from './components/Product/ProductsList';
import moment from 'moment';
import { AddProduct } from './components/AddProduct/index';
import { ReportStatus } from './components/ReportStatus/ReportStatus';
import { CreateProjectCard } from './components/CreateProjectCard/CreateProjectCard';
import { SidePanel } from './components/SidePanel/SidePanel';
import { useCallback, useMemo } from 'react';
import { useDialog } from '../../providers/dialog.provider';
import { CheckCorrectProductsNames } from '../products/components/CheckCorrectProductsNames';
import { RequestLimitNotification } from '../../components/request-limit';
import { createNotification } from '../../utils/notifications';
import { REPORT_STATUS } from '../../utils/constant';
import { ScreenshotsProgress } from './components/Screenshots/ScreenshotsProgress';
import { useSelector } from 'react-redux';
import { isScreenshotsInProgress } from '../../store/selectors/organization';

export const Project = () => {
  const { id } = useParams();
  const dialog = useDialog();
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress)
  const [stopSearch] = useStopSearchMutation();

  const { data } = useGetProjectReportQuery(
    {
      project_id: id,
    },
    {
      pollingInterval: 15000,
    }
  );

  const report = data?.reports?.[0];

  const isOptimizing = report?.status === REPORT_STATUS.OPTIMIZED;

  const noProductsInProject = useMemo(() => {
    const noProducts = report?.total_count === 0;

    return report?.status === REPORT_STATUS.INITIAL && noProducts;
  }, [report?.status, report?.total_count]);

  const openOptimizingModalForm = useCallback(() => {
    dialog.open(<CheckCorrectProductsNames />);
  }, [dialog]);

  const handleStopProjectSearch = useCallback(async () => {
    await stopSearch(id)
      .unwrap()
      .then(() => {
        createNotification('Поиск проекта остановлен');
      })
  }, [id, stopSearch]);

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-w-full tw-relative">
        {isOptimizing && openOptimizingModalForm()}
        <div className="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-mb-6">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
            <h2 className="tw-text-[#191919] tw-font-semibold tw-font-roboto tw-text-[32px] tw-leading-[38px]">
              {data?.project_name}
            </h2>
            <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">
              Дата создания {moment(report?.created_at).format('DD.MM.YYYY')}
            </p>
          </div>
          <div className="tw-mb-6 tw-w-full">
            <RequestLimitNotification
              stopCallback={handleStopProjectSearch}
              isSearching={report?.status === REPORT_STATUS.PROCESSING}
            />
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-gap-[27px]">
          <div className="tw-flex tw-flex-col tw-w-full tw-max-w-[72%]">
            <div className="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-mb-6">
              <AddProduct />
              <CreateProjectCard isEmptyProject={noProductsInProject} />
              <ReportStatus />
            </div>
            {!noProductsInProject && <ProductsList report_id={report?.id} />}
          </div>
          <div className="tw-w-[28%]">
            <SidePanel isProjectEmpty={noProductsInProject} report_id={report?.id} />
          </div>
        </div>
        {screenshotsIsUpdating && <ScreenshotsProgress />}
      </div>
    </GeneralLayout>
  );
};
