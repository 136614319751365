import { useState } from 'react';
import Button from '../button';
import { Menu } from '@mui/material';

const DropdownMenu = ({ buttonChildren, children, btnProps, menuProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        disableElevation
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        {...btnProps}
      >
        {buttonChildren}
      </Button>
      <Menu
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        <div onClick={handleClose}>

        {children}
        </div>
      </Menu>
    </>
  );
};

export default DropdownMenu;
