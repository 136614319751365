import { Dialog, MenuItem, Select } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { useCallback, useState } from 'react';
import Button from '../../components/button';
import { ReactComponent as ArrowDown } from '../../icons/arrowDownIcon.svg';
import { useChangeRequestStatusMutation } from '../../services/supportAPIs';
import { createNotification } from '../../utils/notifications';
import { CloseSupportRequest } from './modals/CloseSupportRequest';

const ArrowDownIcon = (props) => {
  return <ArrowDown className=" tw-pointer-events-none MuiSvgIcon-root MuiSelect-icon tw-w-6 tw-h-6" {...props} />;
};

const REQUEST_PRIORITIES = [
  {
    value: 'low',
    label: 'Низкий',
  },
  {
    value: 'medium',
    label: 'Средний',
  },
  {
    value: 'high',
    label: 'Высокий',
  },
];

const REQUEST_STATUSES = [
  {
    value: 'accepted',
    label: 'Принято к сведению',
  },
  {
    value: 'in_queue',
    label: 'В работе',
  },
  {
    value: 'completed',
    label: 'Завершено',
  },
];

export const RequestSettingsModal = ({ currentStatus, currentPriority, request_id, onChange }) => {
  const dialog = useDialog();
  const [status, setStatus] = useState(currentStatus);
  const [priority, setPriority] = useState(currentPriority);

  const [changeRequest] = useChangeRequestStatusMutation();

  const checkIfInitial = () => {
    return (
      status?.toLowerCase() === currentStatus?.toLowerCase() &&
      priority?.toLowerCase() === currentPriority?.toLowerCase()
    );
  };

  const handleCloseRequest = useCallback(() => {
    dialog.open(<CloseSupportRequest priority={priority} request_id={request_id} />);
  }, [dialog, priority, request_id]);

  const handleChangeRequest = async () => {
    if (status === 'completed') {
      handleCloseRequest();
    } else {
      const body = {
        criticality: priority,
        status,
      };
      await changeRequest({ support_request_id: request_id, body })
        .unwrap()
        .then(() => {
          createNotification('Решение по заявке изменено');
          dialog.close();
        });
    }
  };

  const handleCloseModal = () => {
    setStatus(currentStatus);
    setPriority(currentPriority);
    dialog.close();
  };

  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 tw-gap-6 tw-w-[328px]">
        <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start">
          <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">Приоритет запроса</p>
          <Select
            onChange={(e) => setPriority(e.target.value)}
            displayEmpty
            value={priority}
            className="tw-p-[14px] tw-pr-[10px] tw-w-full"
            sx={{
              '& .MuiSelect-select': {
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiSelect-icon': {
                top: 'calc(50% - 0.7em)',
              },
            }}
            IconComponent={ArrowDownIcon}
          >
            <MenuItem value="">
              <p className="tw-text-[#999999] tw-text-sm tw-font-medium tw-leading-[18px]">Выбрать приоритет запроса</p>
            </MenuItem>
            {REQUEST_PRIORITIES?.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-[10px] tw-items-start">
          <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">Статус заявки</p>
          <Select
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            value={status}
            className="tw-p-[14px] tw-pr-[10px] tw-w-full"
            sx={{
              '& .MuiSelect-select': {
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiSelect-icon': {
                top: 'calc(50% - 0.7em)',
              },
            }}
            IconComponent={ArrowDownIcon}
          >
            <MenuItem value="">
              <p className="tw-text-[#999999] tw-text-sm tw-font-medium tw-leading-[18px]">Выбрать приоритет запроса</p>
            </MenuItem>
            {REQUEST_STATUSES?.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-6 tw-mt-8 tw-items-center tw-justify-end">
          <Button
            variant="text"
            className="tw-w-fit hover:tw-bg-transparent tw-p-0 tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
            onClick={() => handleCloseModal()}
          >
            Отменить
          </Button>
          <Button
            className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg"
            disabled={checkIfInitial()}
            onClick={() => handleChangeRequest()}
          >
            Изменить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
