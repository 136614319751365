export default function LogoIcon({ className, primary }) {
  return (
    <svg width='148' height='45' viewBox='0 0 137 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_8414_91471)'>
      <path
        d='M17.2493 0C7.72771 0 3.12146e-06 7.67999 3.12146e-06 17.1428C-0.00139665 19.4561 0.467995 21.7458 1.37995 23.8742L6.66971 21.6343C6.07177 20.2515 5.74983 18.7427 5.74983 17.1428C5.74983 10.8343 10.9016 5.71432 17.2493 5.71432C23.5971 5.71432 28.7489 10.8343 28.7489 17.1428C28.7489 22.9942 24.3216 27.8057 18.6293 28.4914L19.3193 34.1599C27.8634 33.1428 34.4987 25.92 34.4987 17.1428C34.4987 7.67999 26.771 0 17.2493 0ZM15.5244 40H9.77459V14.2858H15.5244V40Z'
        fill='#C12026' />
    </g>
    <path d='M49.6211 17.4062V25.7179H48.3633V18.4777H44.4267V25.7179H43.1689V17.4062H49.6211Z' fill='white' />
    <path
      d='M51.5506 25.7179V17.4062H54.5317C55.1823 17.4062 55.7214 17.5239 56.1488 17.7593C56.5762 17.9947 56.8961 18.3167 57.1085 18.7252C57.3208 19.1311 57.427 19.5883 57.427 20.097C57.427 20.6084 57.3195 21.0683 57.1044 21.4769C56.892 21.8827 56.5708 22.2047 56.1406 22.4428C55.7132 22.6782 55.1755 22.7959 54.5276 22.7959H52.4776V21.7325H54.4133C54.8243 21.7325 55.1578 21.6622 55.4138 21.5215C55.6697 21.3781 55.8575 21.1833 55.9773 20.9371C56.0971 20.6909 56.157 20.4108 56.157 20.097C56.157 19.7831 56.0971 19.5045 55.9773 19.261C55.8575 19.0175 55.6683 18.8267 55.4097 18.6887C55.1538 18.5507 54.8162 18.4817 54.3969 18.4817H52.8125V25.7179H51.5506Z'
      fill='white' />
    <path
      d='M58.9063 25.7179V17.4062H64.1497V18.4858H60.1681V21.0183H63.8761V22.0937H60.1681V24.6384H64.1987V25.7179H58.9063Z'
      fill='white' />
    <path
      d='M65.2023 27.7269V24.6546H65.7536C65.9414 24.4977 66.117 24.2745 66.2804 23.985C66.4437 23.6955 66.5825 23.3045 66.6969 22.8121C66.8112 22.3197 66.8902 21.6879 66.9337 20.9168L67.1298 17.4062H72.2874V24.6546H73.2348V27.7147H72.0261V25.7179H66.411V27.7269H65.2023ZM67.24 24.6546H71.0786V18.4858H68.2732L68.1425 20.9168C68.1071 21.5039 68.0486 22.0315 67.9669 22.4996C67.8879 22.9677 67.7859 23.3816 67.6606 23.7415C67.5381 24.0986 67.3979 24.403 67.24 24.6546Z'
      fill='white' />
    <path
      d='M74.6171 17.4062H75.8667V23.5101H75.9443L80.1995 17.4062H81.3674V25.7179H80.1056V19.6222H80.028L75.781 25.7179H74.6171V17.4062Z'
      fill='white' />
    <path
      d='M88.1698 25.7179L85.3602 21.9923H84.47V25.7179H83.2081V17.4062H84.47V20.9168H84.8824L88.0432 17.4062H89.5827L86.2382 21.1278L89.7093 25.7179H88.1698Z'
      fill='white' />
    <path d='M90.4658 18.4858V17.4062H96.9384V18.4858H94.3289V25.7179H93.0712V18.4858H90.4658Z' fill='white' />
    <path
      d='M98.3687 17.4062H99.6183V23.5101H99.6959L103.951 17.4062H105.119V25.7179H103.857V19.6222H103.78L99.5325 25.7179H98.3687V17.4062Z'
      fill='white' />
    <path
      d='M106.96 25.7179V17.4062H110.022C110.616 17.4062 111.107 17.5037 111.497 17.6985C111.886 17.8906 112.177 18.1517 112.37 18.4817C112.564 18.8091 112.66 19.1784 112.66 19.5897C112.66 19.936 112.596 20.2282 112.469 20.4663C112.341 20.7017 112.169 20.8911 111.954 21.0345C111.742 21.1752 111.507 21.278 111.252 21.3429V21.4241C111.529 21.4376 111.8 21.5269 112.064 21.692C112.331 21.8543 112.552 22.0856 112.726 22.386C112.9 22.6863 112.987 23.0515 112.987 23.4817C112.987 23.9065 112.886 24.288 112.685 24.6262C112.486 24.9617 112.179 25.2282 111.762 25.4257C111.346 25.6205 110.813 25.7179 110.165 25.7179H106.96ZM108.222 24.6425H110.043C110.647 24.6425 111.08 24.5261 111.341 24.2934C111.603 24.0607 111.733 23.7699 111.733 23.4209C111.733 23.1584 111.667 22.9176 111.533 22.6985C111.4 22.4793 111.209 22.3048 110.962 22.1749C110.717 22.045 110.425 21.9801 110.088 21.9801H108.222V24.6425ZM108.222 21.002H109.912C110.195 21.002 110.45 20.9479 110.676 20.8397C110.904 20.7315 111.086 20.58 111.219 20.3851C111.355 20.1876 111.423 19.955 111.423 19.6871C111.423 19.3435 111.302 19.0553 111.06 18.8226C110.817 18.59 110.446 18.4736 109.945 18.4736H108.222V21.002Z'
      fill='white' />
    <path
      d='M114.484 25.7179V17.4062H115.746V21.0183H119.915V17.4062H121.181V25.7179H119.915V22.0937H115.746V25.7179H114.484Z'
      fill='white' />
    <path
      d='M123.725 25.7179H122.386L125.395 17.4062H126.853L129.863 25.7179H128.523L126.159 18.916H126.094L123.725 25.7179ZM123.95 22.4631H128.295V23.5183H123.95V22.4631Z'
      fill='white' />
    <path
      d='M137 25.7179H135.734V18.4817H134.15C133.736 18.4817 133.4 18.544 133.141 18.6684C132.882 18.7902 132.693 18.966 132.573 19.196C132.454 19.426 132.394 19.6979 132.394 20.0118C132.394 20.3229 132.454 20.5894 132.573 20.8113C132.693 21.0331 132.881 21.2022 133.137 21.3186C133.393 21.4349 133.726 21.4931 134.137 21.4931H136.281V22.5726H134.019C133.374 22.5726 132.836 22.4658 132.406 22.252C131.979 22.0383 131.657 21.7393 131.442 21.3551C131.23 20.9682 131.124 20.5204 131.124 20.0118C131.124 19.5004 131.23 19.0499 131.442 18.6603C131.655 18.268 131.974 17.9609 132.402 17.739C132.832 17.5172 133.37 17.4062 134.015 17.4062H137V25.7179ZM132.871 21.9679H134.268L132.238 25.7179H130.805L132.871 21.9679Z'
      fill='white' />
    <path
      d='M43.7896 40.0037H42.4502L45.4598 31.692H46.9177L49.9274 40.0037H48.5879L46.2235 33.2017H46.1582L43.7896 40.0037ZM44.0142 36.7488H48.3592V37.804H44.0142V36.7488Z'
      fill='white' />
    <path
      d='M51.1351 40.0037V31.692H52.397V35.304H56.5664V31.692H57.8323V40.0037H56.5664V36.3795H52.397V40.0037H51.1351Z'
      fill='white' />
    <path
      d='M60.3764 40.0037H59.037L62.0466 31.692H63.5045L66.5141 40.0037H65.1747L62.8103 33.2017H62.7449L60.3764 40.0037ZM60.601 36.7488H64.946V37.804H60.601V36.7488Z'
      fill='white' />
    <path
      d='M67.0889 40.0037V38.916L67.3339 38.9079C67.6661 38.8998 67.9274 38.7875 68.118 38.571C68.3113 38.3546 68.4515 38.0055 68.5386 37.5239C68.6285 37.0423 68.6883 36.3998 68.7183 35.5962L68.8612 31.692H74.1496V40.0037H72.904V32.7553H70.0496L69.9189 35.929C69.8835 36.8191 69.7937 37.5672 69.6494 38.1733C69.5051 38.7766 69.2601 39.2325 68.9143 39.541C68.5713 39.8494 68.0826 40.0037 67.4483 40.0037H67.0889Z'
      fill='white' />
    <path
      d='M75.9872 31.692H77.2368V37.7959H77.3144L81.5695 31.692H82.7375V40.0037H81.4756V33.9079H81.398L77.151 40.0037H75.9872V31.692Z'
      fill='white' />
    <path d='M84.1657 32.7715V31.692H90.6383V32.7715H88.0289V40.0037H86.7711V32.7715H84.1657Z' fill='white' />
    <path
      d='M92.0686 31.692H93.3182V37.7959H93.3958L97.651 31.692H98.8189V40.0037H97.5571V33.9079H97.4795L93.2325 40.0037H92.0686V31.692Z'
      fill='white' />
    <path
      d='M105.621 40.0037L102.812 36.278H101.921V40.0037H100.66V31.692H101.921V35.2025H102.334L105.495 31.692H107.034L103.69 35.4136L107.161 40.0037H105.621Z'
      fill='white' />
    <path
      d='M109.036 40.0037H107.697L110.706 31.692H112.164L115.174 40.0037H113.834L111.47 33.2017H111.405L109.036 40.0037ZM109.261 36.7488H113.606V37.804H109.261V36.7488Z'
      fill='white' />
    <defs>
    <clipPath id="clip0_8414_91471">
        <rect width="34.4987" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
}
