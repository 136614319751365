import classNames from 'classnames';
import { priceFormatter } from '../../../../utils/priceFormatter';
import Button from '../../../../components/button';
import { ReactComponent as EditIcon } from '../../../../icons/editIcon.svg';
import { Skeleton } from '@mui/material';
import { PRODUCT_STATUS } from '../../../../utils/constant';
import { isScreenshotsInProgress } from '../../../../store/selectors/organization';
import { useSelector } from 'react-redux';

export const SourceLogisticInfo = ({
  asTable,
  productPrice,
  productLogisticStatus,
  onCorrectPrice,
  isDimensionsCalculationFalied,
  logisticPrice,
  handleViewDetailedLogisticInfo,
}) => {
  const isValidSourcePrice = (price) => {
    try {
      if (!price) return false;
      const parsedPrice = Number(price);
      return typeof parsedPrice === 'number' && !Number.isNaN(parsedPrice);
    } catch (err) {
      return false;
    }
  };

  const isLogisticLoading = productLogisticStatus === PRODUCT_STATUS.PARSING;
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress)

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start">
        <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-medium">Цена за товар</p>
        <div className="tw-flex tw-flex-row tw-gap-[2px]">
          <p
            className={classNames(
              'tw-inline-flex tw-gap-[2px] tw-items-baseline tw-text-[#191919] tw-font-semibold tw-leading-5',
              { 'tw-text-sm': asTable }
            )}
          >
            {isValidSourcePrice(productPrice) ? priceFormatter(productPrice, 2) : 'Не найдено '}
            <span className="tw-text-xs tw-leading-[12px]">₽</span>
          </p>
          <span data-tour="wrongPriceStep">
            <Button variant="text" onClick={onCorrectPrice} className="tw-w-fit tw-p-0 hover:tw-bg-transparent" disabled={screenshotsIsUpdating}>
              <EditIcon className="tw-w-4 tw-h-4" />
            </Button>
          </span>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start">
        <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-medium">Логистика</p>
        <div className="tw-flex tw-flex-row tw-gap-[2px]">
          <p
            className={classNames(
              'tw-inline-flex tw-gap-[2px] tw-items-baseline tw-text-[#191919] tw-font-semibold tw-leading-5',
              {
                'tw-text-sm': asTable,
                'tw-text-[#C12026]': isDimensionsCalculationFalied,
                'tw-text-[#191919]': !isDimensionsCalculationFalied,
              }
            )}
          >
            {isLogisticLoading ? (
              <Skeleton variant="rounded" width={80} height={20} />
            ) : isValidSourcePrice(logisticPrice) ? (
              <>
                {priceFormatter(logisticPrice ?? 0, 2)}
                <span className="tw-text-xs tw-leading-[12px]">₽</span>
              </>
            ) : (
              'Не найдено'
            )}
          </p>
        </div>
        <span data-tour="logisticStep">
          <Button
            variant="text"
            onClick={handleViewDetailedLogisticInfo}
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#134B98] tw-text-sm tw-font-medium tw-text-left tw-leading-[18px]"
          >
            Габаритные размеры
          </Button>
        </span>
      </div>
    </>
  );
};
