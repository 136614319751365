import React, { useCallback, useMemo, useState } from 'react';
import {
  useCancelProductSearchMutation,
  useDeleteProductMutation,
  useReloadProductMutation,
  useUpdateProductMutation,
} from '../../../../services';
import { createNotification } from '../../../../utils/notifications';
import { Collapse } from '@mui/material';
import { filterSourcesByType, getWordEnding1 } from '../../../../utils/common';
import { PriceGraph } from '../../PriceGraph/PriceGraph';
import { SourcesList } from '../SourcesList/SourcesList';
import { useSelector } from 'react-redux';
import { useDialog } from '../../../../providers/dialog.provider';
import { ConfirmModal } from '../../../../components/modal/ConfirmModal';
import { DeliveryDetails } from '../modals/DeliveryDetails';
import { ProductSkeleton } from '../Skeletons/ProductSkeleton';
import { ProductOverview } from './components/ProductOverview';
import { ProductLogistic } from './components/ProductLogistic';
import { PRODUCT_LOADING_STATES, PRODUCT_STATUS } from '../../../../utils/constant';
import { Tours } from '../../../../components/tour/Tours';

export const Product = ({ product, orderNumber }) => {
  const isLoading = PRODUCT_LOADING_STATES.includes(product?.status);
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const dialog = useDialog();

  const [isSourcesOpen, setIsSourcesOpen] = useState(false);

  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const [reloadProduct] = useReloadProductMutation();
  const [stopProductSearch] = useCancelProductSearchMutation();

  const handleChangeAmount = useCallback(
    async (value) => {
      await updateProduct({
        report_id: report.id,
        product_id: product.id,
        body: {
          amount: Number(value),
        },
      });
    },
    [product?.id, report?.id, updateProduct]
  );

  const handleStopProductSearch = useCallback(async () => {
    await stopProductSearch(product.id)
      .unwrap()
      .then(() => {
        createNotification('Поиск товара остановлен');
      });
  }, [product?.id, stopProductSearch]);

  const handleReloadProduct = async () => {
    await reloadProduct({ report_id: report?.id, product_id: product?.id });
  };

  const handleUpdateProduct = useCallback(
    async (body) => {
      const report_id = report?.id;
      const product_id = product?.id;
      await updateProduct({ report_id, product_id, body });
    },
    [product.id, report.id, updateProduct]
  );

  const handleDeleteProduct = useCallback(async () => {
    await deleteProduct({ report_id: report.id, product_id: product.id });
  }, [deleteProduct, product?.id, report?.id]);

  const showDeleteProductModal = useCallback(() => {
    dialog.open(
      <ConfirmModal title={`Вы действительно хотите удалить товар ${product.name}?`} onAgree={handleDeleteProduct} />
    );
  }, [dialog, handleDeleteProduct, product?.name]);

  const showDetailedDeliveryInfo = useCallback(() => {
    dialog.open(<DeliveryDetails delivery_info={product?.delivery_stats} />);
  }, [dialog, product?.delivery_stats]);

  const noSources = useMemo(() => {
    const projectIsNotSearching =
      product.status === PRODUCT_STATUS.FINISHED || product.status === PRODUCT_STATUS.CANCELED;
    if (product?.sources?.length === 0 && projectIsNotSearching) {
      return true;
    }
    return false;
  }, [product.sources.length, product.status]);

  const handleSourcesListVisability = () => {
    setIsSourcesOpen((prev) => !prev);
  };

  const sourcesToDisplay = useMemo(() => {
    return product?.sources ? filterSourcesByType(product.sources, ['yandex_search', 'engine_search']) : [];
  }, [product?.sources]);

  return (
    <>
      {!isLoading ? (
        <div className="tw-bg-white tw-relative tw-flex tw-flex-col tw-rounded-lg tw-divide-x tw-divide-[#D9D9D9]">
          {orderNumber && (
            <span className="tw-absolute tw-top-0 tw-left-0 tw-border-[#D5D5D5] tw-border tw-bg-white tw-rounded-tl-lg tw-rounded-br-lg tw-text-sm tw-leading-[18px] tw-text-center tw-min-h-[31px] tw-min-w-[31px] tw-py-[6px] tw-z-[2]">
              {orderNumber}
            </span>
          )}
          <div className="tw-flex tw-flex-row tw-divide-x tw-divide-[#D9D9D9]">
            <ProductOverview
              onUpdateProduct={handleUpdateProduct}
              isProductLoading={isLoading}
              product={product}
              onChangeAmount={handleChangeAmount}
              handleReloadProduct={handleReloadProduct}
            />
            <div className="tw-w-[30%] ">
              <ProductLogistic
                hasIncorrectSources={product?.sources_with_incorrect_dimensions > 0}
                avgDeliveryPrice={product?.avg_cost_of_delivery}
                showDeliveryInfo={showDetailedDeliveryInfo}
                hasSources={!noSources}
                onDeleteProduct={showDeleteProductModal}
                avgProductPrice={product?.avg_price}
                onReloadProduct={handleReloadProduct}
                handleShowSourcesList={handleSourcesListVisability}
                sourcesAmount={product?.sources?.length}
                isSourceListVisible={isSourcesOpen}
                productLogisticStatus={product?.delivery_status}
              />
            </div>
          </div>
          <Collapse in={isSourcesOpen && !noSources}>
            <div className="tw-flex tw-flex-col tw-w-full tw-pt-[35px] tw-pb-4">
              <p className="tw-pl-[15px] tw-text-[#191919] tw-font-semibold tw-leading-5">
                Найдено {product?.sources?.length ?? 0} {getWordEnding1('источник', product?.sources?.length ?? 0)}
              </p>
              <div className="tw-pl-4 tw-mt-[69px] tw-mb-[17px]">
                <PriceGraph minPrice={product?.min_price} avgPrice={product?.avg_price} maxPrice={product?.max_price} />
              </div>
              <SourcesList
                sources={sourcesToDisplay}
                product_id={product.id}
                product_name={product.name}
                selectedSources={product?.selected_sources}
              />
            </div>
          </Collapse>
        </div>
      ) : (
        <ProductSkeleton handleStopSearching={handleStopProductSearch} />
      )}
    </>
  );
};
