import { Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { SelectMUI } from '../../../components/selectMUI';
import { Form, Field } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';

export const SizesFormDialog = ({ title, onSubmit, onReject, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  useEffect(() => {
    if (formValue?.id !== value?.id) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name, length, height, width, maximum_load_weight, size_units, weight_units } = payload;
      const body = {
        name: name,
        length: length !== '' ? parseInt(length, 10) : length,
        height: height !== '' ? parseInt(height, 10) : height,
        width: width !== '' ? parseInt(width, 10) : width,
        maximum_load_weight: maximum_load_weight !== '' ? parseInt(maximum_load_weight, 10) : maximum_load_weight,
        size_units: size_units,
        weight_units: weight_units,
      };
      try {
        onSubmit &&
          (!id ? await onSubmit(body).then(() => dialog.close()) : await onSubmit(id, body).then(() => dialog.close()));
      } catch (err) {
        console.error(err);
      }
    },
    [onSubmit, dialog]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);

  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={formValue}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-xl">
              <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
              <div className="tw-flex tw-flex-row tw-gap-6 tw-flex-wrap tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                <div className="tw-w-full">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Имя</p>
                  <Input form={form} value={values['name']} name="name" type="text" required />
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Длина</p>
                  <Input form={form} value={values['length']} name="length" type="number" />
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Высота</p>
                  <Input form={form} value={values['height']} name="height" type="number" />
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Ширина</p>
                  <Input form={form} value={values['width']} name="width" type="number" />
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Макс. вес</p>
                  <Input form={form} value={values['maximum_load_weight']} name="maximum_load_weight" type="number" />
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Ед. размера</p>
                  <div className="tw-w-[88px]">
                    <Field name="size_units" component={SelectMUI} form={form} options={['мм', 'см']} />
                  </div>
                </div>
                <div className="tw-w-[20%]">
                  <p className="tw-text-sm tw-font-medium tw-text-[#191919] tw-mb-[10px]">Ед. массы</p>
                  <div className="tw-w-[88px]">
                    <Field name="weight_units" component={SelectMUI} form={form} options={['кг', 'г']} />
                  </div>
                </div>
              </div>
              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm"
                  variant="text"
                  disabled={submitting}
                  onClick={handleFormReject}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={submitting && invalid && pristine}
                  type="submit"
                >
                  Добавить
                </Button>
              </DialogActions>
              {submitError && createErrorNotification(submitError)}
            </div>
          </form>
        )}
      ></Form>
    </Dialog>
  );
};
