import GeneralLayout from '../../components/general-layout';
import { Container } from 'react-bootstrap';
import classes from './answer.emails.module.sass';

export default function AnswerEmailPage() {
    return (
        <GeneralLayout>
            <Container className={classes.container}>
                <h5>На данный момент нет отвеченных писем</h5>
            </Container>
        </GeneralLayout>
    );
}