import classNames from "classnames";

export function FAQIcon({ isActive }) {
  return (
    <svg  className="tw-shrink-0 tw-h-6 tw-w-6" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={classNames('tw-fill-current', isActive && '')}
        d="M14 15C14.2833 15 14.5292 14.8958 14.7375 14.6875C14.9458 14.4792 15.05 14.2333 15.05 13.95C15.05 13.6667 14.9458 13.4208 14.7375 13.2125C14.5292 13.0042 14.2833 12.9 14 12.9C13.7167 12.9 13.4708 13.0042 13.2625 13.2125C13.0542 13.4208 12.95 13.6667 12.95 13.95C12.95 14.2333 13.0542 14.4792 13.2625 14.6875C13.4708 14.8958 13.7167 15 14 15ZM13.25 11.8H14.75C14.75 11.3167 14.8 10.9625 14.9 10.7375C15 10.5125 15.2333 10.2167 15.6 9.85C16.1 9.35 16.4333 8.94583 16.6 8.6375C16.7667 8.32917 16.85 7.96667 16.85 7.55C16.85 6.8 16.5875 6.1875 16.0625 5.7125C15.5375 5.2375 14.85 5 14 5C13.3167 5 12.7208 5.19167 12.2125 5.575C11.7042 5.95833 11.35 6.46667 11.15 7.1L12.5 7.65C12.65 7.23333 12.8542 6.92083 13.1125 6.7125C13.3708 6.50417 13.6667 6.4 14 6.4C14.4 6.4 14.725 6.5125 14.975 6.7375C15.225 6.9625 15.35 7.26667 15.35 7.65C15.35 7.88333 15.2833 8.10417 15.15 8.3125C15.0167 8.52083 14.7833 8.78333 14.45 9.1C13.9 9.58333 13.5625 9.9625 13.4375 10.2375C13.3125 10.5125 13.25 11.0333 13.25 11.8ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
        fill="white"
      />
    </svg>
  );
}
