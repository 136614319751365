import { Form } from 'react-final-form';
import { MenuItem, Select } from '@mui/material';
import { UNIT_OPTIONS } from '../../../../utils/constant';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isScreenshotsInProgress } from '../../../../store/selectors/organization';

const sx = {
  '&.MuiInputBase-root': {
    '& .MuiSelect-select': {
      padding: '0px !important',
    },
    fieldset: {
      border: 'none',
    },
  },
};

export const ChangeUnit = ({ currentUnit, onChange, asTable }) => {
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress)

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
      {!asTable && <p className="tw-text-[#666666] tw-text-sm tw-leading-[16px] tw-font-medium">Ед. измерения</p>}
      <Form onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <Select
            name="unit"
            defaultValue={currentUnit}
            className={classNames('tw-text-[#134B98] tw-text-sm tw-font-medium ', {
              'tw-underline-offset-2 tw-underline': !asTable,
            })}
            IconComponent={() => null}
            sx={sx}
            disabled={screenshotsIsUpdating}
            onChange={(e, _) =>
              onChange({
                unit: e.target.value,
              })
            }
          >
            {UNIT_OPTIONS.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Form>
    </div>
  );
};
