import moment from 'moment';
import DeleteIcon from '../../icons/delete';
import { deleteSavedSource, favoriteSavedSource } from '../../store/actions/organizations';
import Loader from '../loader';
import Price from '../price';
import classes from './saved-sources-list.module.sass';
import eventBus from '../../utils/eventBus';
import { EVENTS, STANDARD_DATE_FORMAT } from '../../utils/constant';
import HeartIcon from '../../icons/heart';
import HeartFillIcon from '../../icons/heart-fill';
import ChartSavedSource from '../chart-saved-sources';
import { LineChart } from '../charts';
import { useEffect } from 'react';

export default function SavedSourcesList({ product, index, onReloadSavedSources }) {
  const isProcessing = product.status === 'parsing' || product.status === 'initial';
  const id = `saved-source-chart-${product.id}-${index}`;
  let chart;

  const labels = [];
  const data = [];
  product?.daily_changes?.forEach((d) => {
    labels.push(moment(d.date).format('MM-DD-YYYY'));
    data.push(d.price);
  });

  useEffect(() => {
    if (labels && data) {
      const chartInstance = LineChart({ id, labels, data });
      if (chartInstance) {
        chart = chartInstance.chart;
        chart.update();
      }
    }
    return () => {
      if (chart) chart.destroy();
    };
  }, [product]);

  const handleDeleteSavedSource = async () => {
    await deleteSavedSource(product.id);
    onReloadSavedSources();
  };

  const handleFavoriteSavedSource = async () => {
    await favoriteSavedSource(product.id, !product.is_favorited);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.chart}>
          <div className={classes.link}>
            <a href={product.source} target="_blank" rel="noopener noreferrer" className={classes.title}>
              {new URL(product.source).hostname}
            </a>
            <a href={product.source} target="_blank" rel="noopener noreferrer" className={classes.linkDown}>
              {product.source}
            </a>
          </div>
          <div className="tw-m-aut tw-w-full">
            <canvas id={id} width="100%" height="220"></canvas>
          </div>
        </div>

        <div className={classes.prices}>
          <div className={classes.text}>Средняя цена за единицу</div>
          <div className={classes.priceMain}>
            <span className={classes.dot} />
            <Price value={product.medium_price} />
          </div>
          <div className={classes.text}>Минимальная цена за единицу</div>
          <div className={classes.price}>
            <Price value={product.minimum_price} />
          </div>
          <div
            className={classes.heartBtn}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleFavoriteSavedSource();
            }}
          >
            {product.is_favorited ? <HeartFillIcon /> : <HeartIcon />}
          </div>
          <div
            className={classes.deleteBtn}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                onOk: handleDeleteSavedSource,
              });
            }}
          >
            <DeleteIcon />
          </div>
          {isProcessing && <Loader absolute />}
        </div>
      </div>
    </>
  );
}
