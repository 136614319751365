export default function EmptyImageIcon() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M34 42.5L46.75 29.75L63.75 46.75V63.75H4.25V51M42.5 51L25.5 34L4.25 55.25V4.25H63.75V51H42.5Z"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.25 25.5C24.7708 25.5 27.625 22.6458 27.625 19.125C27.625 15.6042 24.7708 12.75 21.25 12.75C17.7292 12.75 14.875 15.6042 14.875 19.125C14.875 22.6458 17.7292 25.5 21.25 25.5Z"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
