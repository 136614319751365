import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralLayout from '../../components/general-layout';
import { useGetSupportAnswersChatQuery, useGetSupportAnswersRequestQuery } from '../../services/supportAPIs';
import { getDecisionDays, supportRequestStatusFormatter } from '../../utils/common';
import moment from 'moment';
import { SupportAnswersChat, SupportAnswer } from './components/SupportAnswersChat';

export const SupportAnswersDetailed = () => {
  const { id } = useParams();

  const { data: chat } = useGetSupportAnswersChatQuery(id, {
    skip: !id,
  });
  const { data: support_request } = useGetSupportAnswersRequestQuery(id, {
    skip: !id,
  });

  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-[10px] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-mb-5 sm:tw-mb-[34px]">
        Заявка {supportRequestStatusFormatter(support_request?.status)}
      </h2>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-gap-5">
        {support_request?.project && support_request?.project_id && (
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
            <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Проект</p>
            <Link
              to={`/projects/${support_request.project_id}`}
              className="tw-text-[#195CB9] tw-text-sm tw-leading-[18px] tw-font-medium"
            >
              {support_request.project}
            </Link>
          </div>
        )}
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Дата создания</p>
          <p className="tw-text-[#191919] tw-font-medium tw-text-sm tw-leading-[18px]">
            {moment(support_request?.created_at).format('DD.MM.YYYY')}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Дата создания</p>
          <p className="tw-text-[#191919] tw-font-medium tw-text-sm tw-leading-[18px]">
            {moment(support_request?.created_at).format('DD.MM.YYYY')}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Срок</p>
          <p className="tw-text-[#191919] tw-font-medium tw-text-sm tw-leading-[18px]">
            {getDecisionDays(support_request?.created_at, support_request?.decision_days)}
          </p>
        </div>
        <div className="tw-mt-1">
          <p className="tw-text-[#191919] tw-font-medium tw-leading-5">Обращение</p>
          <div className="tw-flex tw-flex-col tw-divide-y tw-divide-y-[#D5D5D5]">
            <SupportAnswer text={support_request?.description} date={support_request?.created_at} />
            <SupportAnswersChat chat={chat?.data} isTicketClosed={support_request?.status === 'completed'}/>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};
