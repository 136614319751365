import classNames from 'classnames';
import React from 'react';

function PersonalIcon({ isActive }) {
  return (
    <svg className="tw-shrink-0 tw-h-6 tw-w-6" viewBox="0 0 24 24">
      <path
        className={classNames('tw-fill-current tw-text-gray-400', isActive && 'tw-text-indigo-300')}
        d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
      />
    </svg>
  );
}

export default PersonalIcon;
