import { useEffect } from 'react';

export const useScrollRestoration = (dependencies = [], topElId = 'top') => {
  useEffect(() => {
    const topEl = document.getElementById(topElId);
    if (topEl) {
      topEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, dependencies);
};
