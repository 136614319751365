import { useCallback, useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { usePagination } from '../../components/table/usePagination';
import { feedbackAPIs } from '../../services/feedbackAPIs';
import moment from 'moment';
import Button from '../../components/button';
import Table from '../../components/table';
import { Pagination } from '../../components/table/pagination';
import { DatePicker } from '../../components/date-picker';
import { Menu, MenuItem } from '@mui/material';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { useHistory } from 'react-router-dom';
import { downloadFile } from '../../utils/request';
import { ReactComponent as DownloadIcon } from '../../icons/downloadIcon.svg';

export const Feedback = () => {
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month'),
    till: moment(),
  });
  const [feedbacks, setFeedbacks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getFeedbacks = useCallback(async (limit, page_num, query) => {
    return await feedbackAPIs.getFeedbackPeriods(query, page_num, limit);
  }, []);

  const getPeriodReport = useCallback(async (id, started_at, ended_at) => {
    const period = `c ${moment(started_at).format('DD.MM.YYYY')} по ${moment(ended_at).format('DD.MM.YYYY')}`
    await downloadFile(`/api/admin/feedback/periods/${id}/export`, `Отчёт по периоду ${period}.xlsx`).catch((err) => {
      console.error(err);
      createErrorNotification('Ошибка при получении отчёта о периоде');
    });
  }, []);

  const { total, pageSize, currentPage, goToPage, setPageSize, handleChangeQuery, allResults } =
    usePagination(getFeedbacks);

  const handleClickLink = (id, started_at, ended_at) => {
    const period = `${moment(started_at).format('DD.MM.YYYY')}–${moment(ended_at).format('DD.MM.YYYY')}`;
    sessionStorage.setItem('feedbackPeriod', period);
    history.push(`/feedback/${id}`);
  };

  const columns = {
    started_at: {
      label: 'Дата начала',
      renderBodyCell: (value) => (
        <>
          <p>{moment(value.started_at).format('DD.MM.YYYY')}</p>
        </>
      ),
    },
    ended_at: {
      label: 'Дата окончания',
      renderBodyCell: (value) => {
        return (
          <>
            <p className="">{moment(value.ended_at).format('DD.MM.YYYY')}</p>
          </>
        );
      },
    },
    users_amount: {
      label: 'Кол-во пользователей',
    },
    download: {
      label: 'Выгрузить',
      renderBodyCell: (value) => {
        return (
          <>
            <Button
              variant="text"
              className="tw-w-fit hover:tw-bg-transparent"
              onClick={() => {
                getPeriodReport(value.id, value.started_at, value.ended_at);
              }}
            >
              <DownloadIcon />
            </Button>
          </>
        );
      },
    },
    id: {
      label: '',
      renderBodyCell: (value) => {
        return (
          <>
            <Button
              variant="text"
              className="tw-w-fit  tw-text-sm tw-leading-[18px] tw-font-medium hover:tw-bg-transparent disabled:tw-text-[#83A1D9]"
              disabled={value.feedback === 'No feedback available'}
              onClick={() => handleClickLink(value.id, value.started_at, value.ended_at)}
            >
              Подробнее
            </Button>
          </>
        );
      },
    },
  };

  useEffect(() => {
    setFeedbacks(allResults?.periods);
  }, [allResults]);

  // const getQuery = useCallback(() => {
  //   const search = searchQuery ? `${searchQuery}` : '';
  //   const dateParams = `&since=${queryParams?.since?.format('DD-MM-YYYY')}&till=${queryParams?.till?.format(
  //     'DD-MM-YYYY'
  //   )}`;
  //   return search + dateParams;
  // }, [queryParams, searchQuery]);

  useEffect(() => {
    const dateParams = `&since=${queryParams?.since?.format('DD-MM-YYYY')}&till=${queryParams?.till?.format(
      'DD-MM-YYYY'
    )}`;
    handleChangeQuery(dateParams);
  }, [handleChangeQuery, queryParams]);

  const handleSetMode = async (mode) => {
    setAnchorEl(null);
    await feedbackAPIs
      .changeFeedbackMode(mode)
      .then(() => createNotification('Режим успешно изменён'))
      .catch((err) => {
        createErrorNotification('Не удалось изменить режим');
        console.error(err);
      });
  };

  return (
    <>
      <GeneralLayout>
        <h2 className="tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-mb-5 sm:tw-mb-[34px]">
          Обратная связь
        </h2>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-[26px]">
          <div>
            <DatePicker queryParams={queryParams} setQueryParams={setQueryParams} />
          </div>
          <div className="tw-flex tw-flex-row tw-gap-4">
            <Button
              className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold"
              onClick={() => handleSetMode('auto_mode=true')}
            >
              Автоматический режим
            </Button>
            <Button
              className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold"
              onClick={handleMenuClick}
            >
              Ручной режим
            </Button>
            <Menu open={open} onClose={handleMenuClose} anchorEl={anchorEl}>
              <MenuItem onClick={() => handleSetMode('show_feedback=true')}>Отобразить форму обратной связи</MenuItem>
              <MenuItem onClick={() => handleSetMode('')}>Не отображать форму обратной связи</MenuItem>
            </Menu>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-bg-white tw-px-2 tw-pt-[25px] tw-rounded-lg">
          <Table rows={feedbacks} columns={columns} />
        </div>
        <Pagination
          rowsPerPage={pageSize}
          count={total}
          page={currentPage}
          onPageChange={goToPage}
          onLimitChange={setPageSize}
        />
      </GeneralLayout>
    </>
  );
};
