import { Dialog } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import Table from '../../../../components/table';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import Button from '../../../../components/button';
import { priceFormatter } from '../../../../utils/priceFormatter';
import { DELIVERY_SERVICES } from '../../../../utils/constant';
import { useMemo } from 'react';

const columns = {
  delivery_service_id: {
    label: 'Транспортная компания',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">
          {DELIVERY_SERVICES[value.delivery_service_id]}
        </p>
      );
    },
  },
  avg_price: {
    label: 'Цена',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
          {!value?.avg_price ? '-' : priceFormatter(value.avg_price, 2)}
          <span className="tw-ml-1">₽</span>
        </p>
      );
    },
  },
};

export const DeliveryDetails = ({ delivery_info }) => {
  const dialog = useDialog();

  const validDeliveryPrices = useMemo(
    () => delivery_info.filter((item) => item.avg_price !== 0 && item.price !== null),
    [delivery_info]
  );

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '584px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-gap-6 tw-p-6 tw-bg-white tw-rounded-lg tw-relative">
        <Button
          variant="text"
          onClick={() => dialog.close()}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[14px] tw-right-[14px]"
        >
          <CloseIcon />
        </Button>
        <p className="tw-text-[#191919] tw-pl-4 tw-text-[18px] tw-font-medium tw-leading-5">Варианты доставки</p>
        <Table rows={validDeliveryPrices} columns={columns} />
      </div>
    </Dialog>
  );
};
