import { useSelector } from 'react-redux';
import { ReactComponent as ProgressIcon } from '../../../../icons/progressIcon.svg';
import { getSelectedReportScreenshots, isScreenshotsInProgress } from '../../../../store/selectors/organization';
import { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';
import moment from 'moment';

export const ScreenshotsProgress = () => {
  const screenshots = useSelector(getSelectedReportScreenshots);
  const screenshotsIsUpdating = useSelector(isScreenshotsInProgress);
  const report = useSelector((state) => state?.organizations?.selectedReport);

  const [startTime, setStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);

  const progress = useMemo(
    () => Math.round(screenshots?.total ? (screenshots?.found / screenshots?.total) * 100 : 0),
    [screenshots?.found, screenshots?.total]
  );

  useEffect(() => {
    if (screenshots && screenshotsIsUpdating) {
      if (!startTime) {
        setStartTime(moment(report?.start_screenshot));
      }

      const now = moment();
      const elapsedTime = moment.duration(now.diff(startTime)).asSeconds();

      if (screenshots?.found > 0) {
        const avgTimePerScreeshot = elapsedTime / screenshots?.found;
        const remainingScreenshots = screenshots?.total - screenshots?.found;
        const estimatedRemainingTime = avgTimePerScreeshot * remainingScreenshots;

        setRemainingTime(moment.duration(estimatedRemainingTime, 'seconds'));
      }
    }
  }, [report?.start_screenshot, screenshots, screenshotsIsUpdating, startTime]);

  return (
    <div className="tw-fixed tw-bottom-6 tw-right-8 tw-bg-white tw-rounded-lg tw-border tw-border-[#DDDDDD] tw-px-4 tw-py-[14px]">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-1">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
          <ProgressIcon /> <p className="tw-text-[#191919] tw-font-medium tw-leading-5">Идёт сканирование скриншотов</p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-[3px]">
          <p className="tw-text-[#7F7F7F] tw-text-xs tw-leading-4 tw-font-medium">
            {screenshots?.found} из {screenshots?.total}
          </p>
          <LinearProgress
            value={progress}
            variant="determinate"
            className="tw-w-[273px] tw-bg-[#EEEEEE]"
            sx={{
              color: '#000',
              borderRadius: '50px',
              span: {
                backgroundColor: '#0BD90B',
              },
            }}
          />
        </div>
        {remainingTime && <p className="tw-text-[#191919] tw-text-xs">Оставшееся время: {remainingTime?.humanize()}</p>}
      </div>
    </div>
  );
};
