import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import validator from 'validator';
import { organizationsAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import Button from '../button';
import Input from '../input';
import classes from './add-supplier.module.sass';

export const AddSupplierModal = ({ productId, show, onHide, onSubmitAdd }) => {
  const organization = useSelector((state) => state.organizations.selected);
  const report = useSelector((state) => state.organizations.selectedReport);
  const categories = useSelector((state) => state.organizations.categories);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  if (!show) {
    return null;
  }

  function validateURL(url) {
    return validator.isURL(url) ? undefined : 'Неверный источник';
  }

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const body = { ...values, category: selectedCategories.map((c) => c.value).join(',') };
      const res = productId
        ? await organizationsAPIs.addSupplierInReport(report.id, productId, body)
        : await organizationsAPIs.addSupplier(organization.id, body);
      onSubmitAdd(res.supplier);
      createNotification('Добавить успешно');
    } catch (e) {
      console.error(e);
      createErrorNotification('Поставщика уже был добавлена');
    } finally {
      setIsSubmitting(false);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.container}>
            <h3 className={classes.title}>Добавить поставщика</h3>
            <Row>
              <Col xs={12} md={6}>
                <div className={classes.fields}>
                  <p>
                    <p>
                      <b>Источник:</b> (*обязательно)
                    </p>
                  </p>
                  <Input className={classes.input} name="url" required validate={validateURL} />
                  <p>
                    <p>
                      <b>Почта:</b> (*обязательно)
                    </p>
                  </p>
                  <Input className={classes.input} name="email" required type="email" />
                  <p>
                    <b>Цена:</b>
                  </p>
                  <Input className={classes.input} name="price" type="number" />
                  <p>
                    <b>Телефон:</b>
                  </p>
                  <Input className={classes.input} name="telephone" />
                  <p>
                    <b>Название:</b>
                  </p>
                  <Input className={classes.input} name="name" />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className={classes.fields}>
                  <p>
                    <b>ИНН:</b>
                  </p>
                  <Input className={classes.input} name="inn" />
                  <p>
                    <b>КПП:</b>
                  </p>
                  <Input className={classes.input} name="kpp" />
                  <p>
                    <b>Генеральный директор:</b>
                  </p>
                  <Input className={classes.input} name="manager" />
                  <p>
                    <b>Регион:</b>
                  </p>
                  <Input className={classes.input} name="region" />
                  <p>
                    <b>Категории:</b>
                  </p>
                  <Select
                    isMulti
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        borderRadius: '8px',
                        border: 'none',
                        minHeight: 40,
                      }),
                    }}
                    placeholder=""
                    options={categories.map((cate) => ({ value: cate, label: cate }))}
                    onChange={(values) => setSelectedCategories(values)}
                  />
                </div>
              </Col>
            </Row>

            <div className={classes.actions}>
              <Button className={classes.cancel} onClick={onHide}>
                Отменить
              </Button>
              <Button className={classes.ok} disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting ? 'Отправка' : 'Сохранить'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};
