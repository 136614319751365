import { Tooltip as TooltipMUI } from '@mui/material';
import {ReactComponent as TooltipIcon} from '../../icons/tooltipIcon.svg'

export const Tooltip = ({ children, title, placement = 'top-start', ...props }) => {
  return (
    <TooltipMUI
      arrow
      placement={placement}
      title={title}
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: '#1E1B39',
            '& .MuiTooltip-arrow': {
              color: '#1E1B39',
            },
          },
        },
      }}
    >
      {children ?? <TooltipIcon />}
    </TooltipMUI>
  );
};
