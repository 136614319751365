import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const newsAPIs = {
  getNews: (page_size, page_num = 1, query) =>
    request(`/api/admin/news?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getNew: (id) =>
    request(`/api/admin/news/${id}`, {
      method: 'GET',
    }),
  addNew: (body) =>
    request('/api/admin/news', {
      method: 'POST',
      body: body,
    }),
  deleteNew: (id) =>
    request(`/api/admin/news/${id}`, {
      method: 'DELETE',
    }),
  editNew: (id, body) =>
    request(`/api/admin/news/${id}`, {
      method: 'PATCH',
      body: body,
    }),
};

export const newNewsAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: ({ page_size, page_num, query }) => ({
        url: `/api/admin/news`,
        params: { page_size, page_num, query },
      }),
      providesTags: [{ type: 'News' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении списка новостей');
      },
    }),
    getArticle: builder.query({
      query: ({id}) => ({
        url: `/api/admin/news/${id}`,
      }),
      providesTags: [{ type: 'Article' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении новости');
      },
    }),
    addArticle: builder.mutation({
      query: (body) => ({
        url: '/api/admin/news',
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при добавлении новости');
      },
      invalidatesTags: [{ type: 'News' }],
    }),
    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/api/admin/news/${id}`,
        method: 'DELETE',
      }),
      
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении новости');
      },
      invalidatesTags: [{ type: 'News' }],
    }),
    editArticle: builder.mutation({
      query: ({id, body}) => ({
        url: `/api/admin/news/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при обновлении новости');
      },
      invalidatesTags: [{ type: 'Article' }, { type: 'News' }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetArticleQuery,
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
} = newNewsAPIs;
