import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralLayout from '../../components/general-layout';
import { useEffect } from 'react';
import { useState } from 'react';
import { adminAPIs } from '../../services/adminAPIs';
import { createErrorNotification } from '../../utils/notifications';

export const DetailedInfo = () => {
  const { id } = useParams();
  const [data, setData] = useState(null)
  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });
  useEffect(() => {
    const fetchData = async () => {
        await adminAPIs.getPriceRequest(id).then((res) => setData(res)).catch((err) => {
            console.error(err)
            createErrorNotification('Произошла ошибка при получении записи')
        })
    }
    id && fetchData()
  }, [id])
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-h-full">
        <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px] tw-mb-8">Обращение</h2>
        <div className="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 tw-gap-[20px]">
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Имя</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.user_name}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Компания</p>
            <p className="tw-text-[#0A50D4] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.organization}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Проект</p>
            <Link
              to={`/projects/${data?.project_id}`}
              className="tw-text-[#0A50D4] tw-leading-[18px] tw-text-sm tw-font-medium"
            >
              {data?.project_name}
            </Link>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Товар</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{data?.name}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Ссылка</p>
            <a href={`${data?.url}`} className="tw-text-[#0A50D4] tw-leading-[18px] tw-text-sm tw-font-medium">
              Ссылка
            </a>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Неправильная цена</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {numberFormatter.format(data?.found_price)}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Правильная цена</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {numberFormatter.format(data?.correct_price)}
            </p>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};
