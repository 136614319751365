import React, { useCallback, useState } from 'react';
import { AddSupplierModal } from '../../../components/add-supplier-modal';
import Button from '../../../components/button';
import { useDialog } from '../../../providers/dialog.provider';
import { AddSupplierDialog } from './addSupplierDialog';
import { useMediaQuery } from '@mui/material';
import classnames from "classnames";

function RightActions({ isLoading, setSearchText, onSubmitAdd, isAdmin }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const dialog = useDialog()
  const isDesktop = useMediaQuery("(min-width: 1440px)");
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLoading) return;
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);
    setSearchText(formProps.searchText);
  };
  const handleSupplierAddModal = useCallback(() => {
    dialog.open(<AddSupplierDialog />)
  },[dialog])

  return (
    <>
      <div className="tw-grid tw-grid-flow-col tw-items-center sm:tw-auto-cols-max tw-justify-start sm:tw-justify-end tw-gap-2">
        {/* <form className="tw-relative" onSubmit={handleSubmit}>
          <label htmlFor="action-search" className="tw-sr-only">
            Search
          </label>
          <input
            id="action-search"
            className="form-input tw-pl-9 focus:tw-border-gray-300 tw-outline-0"
            type="search"
            name="searchText"
            placeholder="Поиск…"
          />
          <button className="tw-absolute tw-inset-0 tw-right-auto tw-group" type="submit" aria-label="Search">
            <svg
              className="tw-w-4 tw-h-4 tw-shrink-0 tw-fill-current tw-text-gray-400 group-hover:tw-text-gray-500 tw-ml-3 tw-mr-2"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
              <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
            </svg>
          </button>
        </form> */}
        {isAdmin && (
          <Button
            className={classnames("tw-flex-row tw-items-center tw-p-0 hover:tw-bg-[transparent]", {
              "tw-flex": isDesktop,
              "tw-hidden": !isDesktop,
            })}
            variant="text"
            onClick={() => handleSupplierAddModal()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M3 15.75V14.25H10.5V15.75H3ZM3 11.625V10.125H14.75V11.625H3ZM3 7.5V6H14.75V7.5H3ZM16.25 20V15.75H12V14.25H16.25V10H17.75V14.25H22V15.75H17.75V20H16.25Z"
                fill="#0844B4"
              />
            </svg>
            <span className="tw-hidden xs:tw-block tw-ml-[3px]">Добавить поставщика</span>
          </Button>
        )}
      </div>
      <AddSupplierModal show={showAddModal} onHide={() => setShowAddModal(false)} onSubmitAdd={onSubmitAdd} />
    </>
  );
}

export default RightActions;
