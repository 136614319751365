import { ThemeProvider, createTheme } from '@mui/material';

const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0844B4',
      dark: '#0A50D4',
      light: '#0A3D9B',
    },
    secondary: {
      main: '#0A50D4',
    },
    background: {
      default: '#F4F7FE',
      paper: '#ffffff',
    },
    action: {
      disabledBackground: `#83A1D9`,
      disabled: '#FFFFFF',
    },
    text: {
      disabled: '#83A1D9',
    },
    typography: {
      fontFamily: 'Inter',
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
        styleOverrides: {
          input: {
            '&:focus': {
              borderColor: '#000'
            }
          }
        }
      },

    },
  },
});

export const ApplicationThemeProvider = ({ children }) => {
  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
};
