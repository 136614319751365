import moment from 'moment';
import styles from '../../news/news.module.sass';
import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { ImageViewer } from '../../news/modals/ImageViewer';
import DOMPurify from 'dompurify';

export const NewsCard = ({ log }) => {
  const contentRef = useRef(null);

  const dialog = useDialog();

  const showImage = useCallback(
    (e) => {
      dialog.open(<ImageViewer src={e.target.src} />);
    },
    [dialog]
  );

  useEffect(() => {
    const content = contentRef.current;
    const img = content.querySelectorAll('img');
    img?.forEach((image) => {
      image.addEventListener('click', showImage);
    });

    return () => {
      img?.forEach((image) => {
        image.removeEventListener('click', showImage);
      });
    };
  }, [showImage]);
  const html = useMemo(() => DOMPurify.sanitize(log?.description), [log?.description]);
  return (
    <>
      <div className="tw-flex tw-flex-col tw-bg-white tw-pt-6 tw-px-8 tw-pb-8 tw-rounded-lg tw-max-w-[679px]">
        {log.file_links.length > 0 && (
          <img className='tw-max-w-2xl tw-max-h-96 tw-mb-6 tw-rounded-lg' src={log.file_links[0]} alt='article_img'/>
        )}
        <p className="tw-text-[#4C4C4C] tw-leading-[18px] tw-text-sm tw-mb-[10px] tw-uppercase">
          {moment(log?.created_at).format('DD MMMM YYYY HH:mm')}
        </p>
        <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[28px] tw-text-2xl tw-mb-[14px]">{log?.title}</h2>
        <p
          ref={contentRef}
          className={`tw-text-[#191919] tw-leading-[22px] ${styles.newsText}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </>
  );
};
