import { Dialog, TextField } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import { useChangeRequestStatusMutation } from '../../../services/supportAPIs';
import { createNotification } from '../../../utils/notifications';
import Button from '../../../components/button';

export const CloseSupportRequest = ({ priority, request_id }) => {
  const dialog = useDialog();


  const [closeSupportRequest, { isLoading }] = useChangeRequestStatusMutation();

  const handleCloseSupportRequest = async (value) => {
    const body = {
      criticality: priority,
      status: 'completed',
      brief_solution: value.brief_solution,
    };
    await closeSupportRequest({ support_request_id: request_id, body })
      .unwrap()
      .then(() => {
        createNotification('Заявка закрыта');
        dialog.close()
      });
  };

  return (
    <Dialog open={dialog.visibility} sx={{
        '& .MuiPaper-root': {
          minWidth: '436px',
          margin: '32px',
          height: 'fit-content',
          maxHeight: 'calc(100% - 64px)',
        },
      }}>
      <div className="tw-flex tw-flex-col tw-gap-5 tw-rounded-lg tw-bg-white tw-p-5">
        <p className="tw-text-[#191919] tw-font-medium tw-leading-5">Укажите краткое решение проблемы</p>
        <Form onSubmit={handleCloseSupportRequest}>
          {({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit} className="tw-flex tw-flex-col tw-items-end">
              <Field
                name="brief_solution"
                render={({ input }) => (
                  <TextField {...input} required placeholder="Написать ответ" multiline maxRows={6} fullWidth />
                )}
              />
              <div className="tw-flex tw-flex-row tw-items-center tw-self-end tw-gap-4 tw-mt-4">
                <Button
                  variant="text"
                  onClick={() => dialog.close()}
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  disabled={pristine || invalid || isLoading}
                  className="tw-w-fit tw-py-3 tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-text-white tw-font-semibold tw-bg-[#134B98] disabled:tw-bg-[#8CA6C1]"
                >
                  Ответить
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
