import { Switch } from '@mui/material';

export const SwitchMUI = ({ handleChange, initialValue = false, disabled = false }) => {

  const handleSwitchChange = (e) => {
    handleChange(e.target.checked);
    // setIsChecked(e.target.checked)
  };

  return (
    <>
      <Switch checked={initialValue} onChange={handleSwitchChange} disabled={disabled}  sx={{
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: '2px',
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#CCCCCC',
          opacity: 1,
          transition: 'ease',
          transitionDuration: '500ms'
        },
      }} />
    </>
  );
};
