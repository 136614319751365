import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import flatpickr from 'flatpickr';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'moment/locale/ru';
import 'chartjs-adapter-moment';

import './styles/main.sass';
import './styles/override.css';
import './styles/main.css';
import 'flatpickr/dist/themes/material_orange.css';
import { getCurrentUser } from './store/actions/auth';
import LoginPage from './pages/login';
import LandingPage from './pages/landing';
import PersonalAreaPage from './pages/personalArea';
import SavedSourcesPage from './pages/saved-sources';
import { getProjects } from './store/actions/organizations';
import StatisticsOverviewPage from './pages/statistics-overview';
import StatisticsPage from './pages/statistics';
import SavedPricesPage from './pages/saved-prices';
import ArchivedProjectPage from './pages/archived-projects';
import ArchivedProductPage from './pages/archived-products';
import AnswerEmailPage from './pages/answer-emails';
import ProjectsPage from './pages/projects/index.jsx';
import TechnicalSupportPage from './pages/technical-support';
import GeneralAnalyticsPage from './pages/general-analytics';
import StatisticsCategoriesPage from './pages/statistics-categories';
import StatisticsCategoryPage from './pages/statistics-category';
import ChangelogPage from './pages/changelog';
import ChatbotPage from './pages/chatbot';
import SuppliersPage from './pages/suppliers';
import DocumentsPage from './pages/documents';
import CategoriesHandbook from './pages/categories-handbook';
import SizeHandbook from './pages/size-handbook';
import { StatisticsItemPage } from './pages/statistics-item/StatisticsItemPage';
import { Import2gis } from './pages/2gis/index.jsx';
import { CorrectPricesTable } from './pages/correct-prices/index.jsx';
import { DetailedInfo } from './pages/correct-prices/DetailedInfo.jsx';
import { SupportRequests } from './pages/support-requests/index.jsx';
import { DetailedRequest } from './pages/support-requests/DetailedRequest.jsx';
import { UsersRequests } from './pages/users-requests/index.jsx';
import { Feedback } from './pages/feedback/index.jsx';
import { DetailedFeedback } from './pages/feedback/DetailedFeedback.jsx';
import { DetailedPeriod } from './pages/feedback/DetailedPeriod.jsx';
import { TestResultsPage } from './pages/test-results/TestResults.jsx';
import { FaqPage } from './pages/faq/index.jsx';
import { Settings } from './pages/settings-list/Settings.jsx';
import { NewsHandbook } from './pages/news/NewsHandbook.jsx';
import { NewsDetailed } from './pages/news/NewsDetailed.jsx';
import { ResetPassword } from './pages/reset-password/index.jsx';
import { ChangePassword } from './pages/reset-password/ChangePassword.jsx';
import { SuccessReset } from './pages/reset-password/SuccessReset.jsx';
import { Storage } from './pages/storage';
import { Project } from './pages/productsNew/index.jsx';
import { Notifications } from './pages/notifications/Notifications';
import { NotificationDetail } from './pages/notifications/notificationDetail/NotificationDetail';
import { SupportAnswers } from './pages/support-answers/index.jsx';
import { SupportAnswersDetailed } from './pages/support-answers/SupportAnswersDetailed.jsx';
import { Reports } from './pages/reports/index.jsx';
import ListNotificationsForUsers from './pages/notifications/ListNotificationsForUsers/ListNotificationsForUsers';
import { GlobalBlocks } from './pages/global-block/index.jsx';

const appRoutes = [
  { path: '/login', component: LoginPage, auth: false },
  { path: '/', component: LandingPage, exact: true, auth: true },
  { path: '/personal-area', component: PersonalAreaPage, auth: true },
  // { path: '/statistics', component: StatisticsOverviewPage, auth: true, exact: true },
  { path: '/emails', component: AnswerEmailPage, auth: true, exact: true },
  // { path: '/statistics/report', component: StatisticsPage, auth: true },
  // { path: '/statistics/archived-projects', component: ArchivedProjectPage, auth: true },
  // { path: '/statistics/archived-products', component: ArchivedProductPage, auth: true },
  // { path: '/statistics/categories', component: StatisticsCategoriesPage, auth: true, exact: true },
  // { path: '/statistics/:id', component: StatisticsCategoryPage, auth: true, exact: true },
  // { path: '/statistics/:id/:productId', component: StatisticsItemPage, auth: true, exact: true },
  // { path: '/statistics', component: GeneralAnalyticsPage, auth: true },
  { path: '/projects/:id', component: Project, auth: true },
  { path: '/archived-projects/:id', component: SavedPricesPage, auth: true },
  { path: '/saved-sources', component: SavedSourcesPage, auth: true },
  { path: '/technical-support', component: TechnicalSupportPage, auth: true },
  { path: '/projects', component: ProjectsPage, auth: true },
  { path: '/chatbot', component: ChatbotPage, auth: true },
  // { path: '/suppliers', key: 'no-category', component: SuppliersPage, auth: true },
  { path: '/suppliers', component: SuppliersPage, auth: true },
  { path: '/changelog', component: ChangelogPage, auth: true },
  { path: '/documents', component: DocumentsPage, auth: true },
  { path: '/2gis', component: Import2gis, auth: true },
  { path: '/storage', component: Storage, auth: true },
  { path: '/handbook/categories', component: CategoriesHandbook, auth: true },
  { path: '/handbook/sizes', component: SizeHandbook, auth: true },
  { path: '/incorrect-prices', component: CorrectPricesTable, auth: true, exact: true },
  { path: '/incorrect-prices/:id', component: DetailedInfo, auth: true, exact: true },
  { path: '/support-requests', component: SupportRequests, auth: true, exact: true },
  { path: '/support-requests/:id', component: DetailedRequest, auth: true, exact: true },
  { path: '/support-answers', component: SupportAnswers, auth: true, exact: true },
  { path: '/support-answers/:id', component: SupportAnswersDetailed, auth: true, exact: true },
  { path: '/requests', component: UsersRequests, auth: true, exact: true },
  { path: '/feedback', component: Feedback, auth: true, exact: true },
  { path: '/feedback/:id', component: DetailedPeriod, auth: true, exact: true },
  { path: '/feedback/:id/:feedback_id', component: DetailedFeedback, auth: true, exact: true },
  { path: '/tests', component: TestResultsPage, auth: true },
  { path: '/faq', component: FaqPage, auth: true },
  { path: '/settings', component: Settings, auth: true },
  { path: '/user/notifications', component: ListNotificationsForUsers, exact: true, auth: true },
  { path: '/notifications/:id', component: NotificationDetail, auth: true },
  { path: '/notifications', component: Notifications, auth: true },
  { path: '/handbook/news', component: NewsHandbook, auth: true, exact: true },
  { path: '/handbook/news/:id', component: NewsDetailed, auth: true, exact: true },
  { path: '/reset-password', component: ResetPassword, auth: false, exact: true },
  { path: '/reset-password/:token', component: ChangePassword, auth: false, exact: true },
  { path: '/success-reset', component: SuccessReset, auth: false, exact: true },
  { path: '/reports', component: Reports, auth: true, exact: true },
  { path: '/global-blocks', component: GlobalBlocks, auth: true, exact: true },
];

function App() {
  const [isInit, setInit] = React.useState(false);
  const isLoggedIn = useSelector((state) => !!state.auth.user);
  const routes = appRoutes.filter((r) => r.auth === isLoggedIn);
  const redirectRoute = isLoggedIn ? '/' : '/login';

  React.useEffect(() => {
    getCurrentUser().then(() => {
      setInit(true);
      getProjects();
    });

    flatpickr('.datepicker', {
      mode: 'range',
      static: true,

      monthSelectorType: 'static',
      dateFormat: 'M j, Y',
      defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onReady: (selectedDates, dateStr, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.element.value = dateStr.replace('to', '-');
        const customClass = instance.element.getAttribute('data-class');
        instance.calendarContainer.classList.add(customClass);
      },
      onChange: (selectedDates, dateStr, instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.element.value = dateStr.replace('to', '-');
      },
    });
  }, []);

  if (!isInit) {
    return null;
  }
  return (
    <Switch>
      {routes.map((r) => (
        <Route key={r.path} {...r} />
      ))}
      <Route path="/health" component={() => 'Healthy'} />
      <Redirect to={redirectRoute} />
    </Switch>
  );
}

export default App;
