export const PERSONAL_AREA__GET_PROVIDERS = 'PERSONAL_AREA__GET_PROVIDERS';
export const PERSONALAREA__FILTER_LIST = 'PERSONALAREA__FILTER_LIST';
export const PERSONALAREA__FILTER_PROVIDERS = 'PERSONALAREA__FILTER_PROVIDERS';
export const PERSONAL_AREA__ADD_PROVIDER = 'PERSONAL_AREA__ADD_PROVIDER';
export const PERSONAL_AREA__DELETE_PROVIDER = 'PERSONAL_AREA__DELETE_PROVIDER';
export const PERSONAL_AREA__SHOW_PROVIDERS = 'PERSONAL_AREA__SHOW_PROVIDERS';

export const PERSONAL_AREA__GET_USERS = 'PERSONAL_AREA__GET_USERS';
export const PERSONAL_AREA__ADD_USER = 'PERSONAL_AREA__ADD_USER';
export const PERSONAL_AREA__UPDATE_USER = 'PERSONAL_AREA__UPDATE_USER';
export const PERSONAL_AREA__DELETE_USER = 'PERSONAL_AREA__DELETE_USER';

export const PERSONAL_AREA__GET_ACCESSES = 'PERSONAL_AREA__GET_ACCESSES';
export const PERSONAL_AREA__ADD_ACCESS = 'PERSONAL_AREA__ADD_ACCESS';
export const PERSONAL_AREA__UPDATE_ACCESS = 'PERSONAL_AREA__UPDATE_ACCESS';
export const PERSONAL_AREA__DELETE_ACCESS = 'PERSONAL_AREA__DELETE_ACCESS';
export const PERSONAL_AREA__CLEAN_ACCESS = 'PERSONAL_AREA__CLEAN_ACCESS';



