import { Dialog } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/input';
import { SelectMUI } from '../../../components/selectMUI';
import { BLOCK_REASONS } from '../../../utils/constant';
import Button from '../../../components/button';
import { isValidUrl } from '../../../utils/common';

export const AddSource = ({ onSubmit, initialValues }) => {
  const dialog = useDialog();

  const handleSubmit = (values) => {
    if (onSubmit) {
      if (initialValues?.id) {
        onSubmit(initialValues?.id, values);
      } else {
        onSubmit(values);
      }
      dialog.close();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (values?.original_link && !isValidUrl(values.original_link)) {
      errors.original_link = 'Введите корректный URL';
    }
    return errors;
  };
  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-min-w-[440px] tw-max-w-[441px]">
        <p className="tw-mb-8 tw-text-[#191919] tw-leading-5 tw-font-semibold">
          {initialValues ? 'Редактирование источника для блокировки' : 'Источник для блокировки'}
        </p>
        <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
          {({ handleSubmit, pristine, invalid, form, submitting }) => (
            <form onSubmit={handleSubmit} className="tw-flex tw-flex-col tw-gap-5 tw-w-full tw-max-w-[440px] ">
              <Input name="name" placeholder="Название источника*" required />
              <Input name="original_link" placeholder="Ссылка на источник*" required />
              <Field form={form} options={BLOCK_REASONS} name="reason" type="text" required>
                {(props) => (
                  <SelectMUI
                    {...props}
                    menuItemStyle={'tw-max-w-[390px] tw-whitespace-normal'}
                    displayEmpty
                    sx={{
                      '& .Mui-focused': {
                        fieldset: {
                          borderColor: '#D5D5D5 !important',
                          borderWidth: '1px !important',
                        },
                      },
                      '& .MuiSelect-select span::before': {
                        content: "'Причина блокировки*'",
                        color: '#999999',
                        fontSize: '14px',
                      },
                      '& .MuiMenuItem-root': {
                        maxWidth: '390px',
                      },
                    }}
                  />
                )}
              </Field>
              <p className="tw-text-[#191919] tw-font-semibold tw-leading-5">Источник для блокировки</p>
              <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-mt-10 tw-justify-end tw-w-full">
                <Button
                  variant="text"
                  onClick={() => dialog.close()}
                  className="tw-text-[#666666] tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-sm tw-font-semibold tw-leading-[18px]"
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  disabled={pristine || invalid || submitting}
                  className="tw-w-fit tw-py-3 tw-px-4 tw-rounded-lg"
                >
                  {initialValues ? 'Редактировать' : 'Блокировать'}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
