import { Dialog } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import Button from '../button';
import { userAPIs } from '../../services';
import { useCallback, useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom/cjs/react-router-dom.min';

export const GuideModal = () => {
  const dialog = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation()

  useEffect(() => {
    if (location?.pathname === '/login') {
      dialog.close();
    }
  }, [dialog, location?.pathname]);

  const userReadGuide = useCallback(async () => {
    setIsLoading(true);
    await userAPIs
      .setUserReadGuide()
      .then(() => {
        setIsLoading(false);
        sessionStorage.setItem('userGuideModalSeen', 'true');
        dialog.close();
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [dialog]);
  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '436px',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <h4 className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-4">Памятка</h4>
        <ol className="tw-mb-14 tw-text-sm tw-text-[#191919] tw-leading-[18px] ">
          Для того, чтобы система выдавала корректные результаты поиска необходимо следовать простым правилам:
          <li className="odd:tw-my-5">1) изучить руководство по работе с сервисом;</li>
          <li className="odd:tw-my-5">
            2) при необходимости сообщить о НЕКОРРЕКТНОЙ цене (в случае не совпадения с ценой из источника).
            <br /> <span className='tw-mt-2'>Внимание! Цену с учетом скидки не исправлять, это нарушает работу сервиса;</span>
          </li>
          <li className="odd:tw-my-5">
            3) указать название товара без описания. Например, «Электрический чайник Tefal Display Digital". Либо
            используйте кнопку оптимизации названий;
          </li>
          <li className="odd:tw-my-5">
            4) указать полное название товара: вместо "бумага" пишем "бумага офисная". Вместо "iPhone" пишем "iPhone 15
            Pro Max".
          </li>
        </ol>
        <div className="tw-flex tw-flex-row tw-justify-end tw-w-full">
          <Button
            className="tw-w-fit tw-rounded-lg tw-font-medium tw-text-sm"
            disabled={isLoading}
            onClick={() => userReadGuide()}
          >
            Понятно
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
