import { request } from '../utils/request';

export const feedbackAPIs = {
  getFeedbackResults: (query, limit, page_num) =>
    request(`/api/admin/feedback/feedback_list?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  changeFeedbackMode: (mode) =>
    request(`/api/admin/feedback/change_status${mode ? `?${mode}` : ''}`, {
      method: 'POST',
    }),
  getCurrentMode: () =>
    request('/api/admin/feedback/current_period', {
      method: 'GET',
    }),
  getFeedback: (id) =>
    request(`/api/admin/feedback/${id}`, {
      method: 'GET',
    }),
  getFeedbackPeriods: (timestamp, page_num, limit) =>
    request(`/api/admin/feedback/periods?${timestamp}&page_num=${page_num}&page_size=${limit}`, {
      method: 'GET',
    }),
  getFeedbacksFromPeriod: (id, query, limit, page_num) =>
    request(`/api/admin/feedback/periods/${id}/users?query=${query}&page_num=${page_num}&page_size=${limit}`, {
      method: 'GET',
    }),
};
