import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const supportAPIs = {
  changeRequestStatus: (id, body) =>
    request(`/api/admin/tech_supports/technical_support/${id}`, {
      method: 'PATCH',
      body,
    }),
  getProjects: (organization_id, limit, page_num, query, signal) =>
    request(
      `/api/organizations/${organization_id}/projects?page_size=${limit}&page_num=${page_num}&query=${query}`,
      {
        method: 'GET',
      },
      signal
    ),
  getReport: (start_date, end_date) =>
    request(`/api/admin/tech_supports/technical_support/export_file?start_date=${start_date}&end_date=${end_date}`, {
      method: 'GET',
    }),
  sendAnswerToTicket: (body) =>
    request('/api/admin/tech_supports/technical_support/answer', {
      method: 'POST',
      body,
    }),
  getRequestsOfSupport: (page_size = 10, page_num = 1, query = '') =>
    request(
      `/api/admin/tech_supports/technical_support/user?page_size=${page_size}&page_num=${page_num}&query=${query}`,
      {
        method: 'GET',
      }
    ),
};

export const newSupportAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    getSupportAnswersChat: builder.query({
      query: (request_id) => ({
        url: `/api/admin/tech_supports/technical_support/${request_id}/chat?sort_by=created_at&sort_order=asc`,
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении чата тех. поддержки');
      },
      providesTags: ['SupportChat'],
    }),
    getSupportChatsUser: builder.query({
      query: ({ page_size, page_num, query }) => ({
        url: `/api/admin/tech_supports/technical_support/user`,
        method: 'GET',
        params: { page_size, page_num, query },
      }),
      providesTags: [{ type: 'SupportRequestsUser' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении списка запросов в тех. поддержку');
      },
    }),
    getSupportAnswersRequest: builder.query({
      query: (request_id) => ({
        url: `/api/admin/tech_supports/technical_support/${request_id}`,
      }),
      providesTags: [{ type: 'SupportRequest' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении запроса тех. поддержки');
      },
    }),
    sendAnswerToSupportTicket: builder.mutation({
      query: ({ support_request_id, text }) => ({
        url: `/api/admin/tech_supports/technical_support/${support_request_id}/chat`,
        method: 'POST',
        body: {
          text,
        },
      }),
      invalidatesTags: [{ type: 'SupportChat' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при отправке ответа в чат тех. поддержки');
      },
    }),
    changeRequestStatus: builder.mutation({
      query: ({ support_request_id, body }) => ({
        url: `/api/admin/tech_supports/technical_support/${support_request_id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'SupportRequest' }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при изменении заявки тех. поддержки');
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSupportChatsUserQuery,
  useGetSupportAnswersChatQuery,
  useGetSupportAnswersRequestQuery,
  useSendAnswerToSupportTicketMutation,
  useChangeRequestStatusMutation,
} = newSupportAPIs;
