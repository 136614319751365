export const statusesSupport = {
  completed: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center  tw-text-[#49CA4E] tw-text-base tw-leading-[18px] tw-font-medium tw-text-center tw-w-fit">
        <p>Завершено</p>
      </div>
    </>
  ),
  in_queue: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center  tw-text-[#DD9F00] tw-text-base tw-leading-[18px] tw-font-medium tw-text-center tw-w-fit">
        <p>В работе</p>
      </div>
    </>
  ),
  accepted: (
    <>
      <div className="tw-flex tw-flex-row tw-items-center  tw-text-[#FF4343]  tw-text-base tw-leading-[18px] tw-font-medium tw-text-center tw-w-fit">
        <p>Принято</p>
      </div>
    </>
  ),
};