import { useCallback, useEffect, useRef, useState } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { CircularProgress, Dialog, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectsAPIs } from '../../../services';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Field, Form, useField } from 'react-final-form';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { dispatch } from '../../../store';
import * as constants from '../../../store/constants/organizations';
import { useRouteMatch } from 'react-router-dom';
import { hasAcceptedSelectedChangesSelector } from '../../../store/selectors/organization';
import { debounce } from 'lodash';
import { ReactComponent as ReturnIcon } from '../../../icons/backIcon.svg';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { getWordEnding3 } from '../../../utils/common';
import classNames from 'classnames';

export const CheckCorrectProductsNames = () => {
  const dialog = useDialog();
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const hasAcceptedSelectedChanges = useSelector(hasAcceptedSelectedChangesSelector);
  const mobile = useMediaQuery('(max-width: 500px)');
  const [isUploading, setIsUploading] = useState(false);
  const route = useRouteMatch('/projects/:id');

  useEffect(() => {
    if (!route?.params?.id) {
      dialog.close();
    }
  }, [route, dialog]);

  useEffect(() => {
    if (!report) {
      return;
    }
    if (hasAcceptedSelectedChanges) {
      dialog.close();
    }
  }, [dialog, hasAcceptedSelectedChanges, report]);

  const [lastElementId, setLastElementId] = useState(null);

  const getOptimizedNames = useCallback(
    async (page, limit, _, signal) => {
      if (report?.id && report?.last_upload_batch_id) {
        return projectsAPIs.getOptimizedProductsNames(report?.id, report?.last_upload_batch_id, page, limit, signal);
      }
    },
    [report?.id, report?.last_upload_batch_id]
  );

  const { data, goToNextPage, hasMore, isLoading, allData, handleForceRefetch } = useInfiniteScroll(
    getOptimizedNames,
    'products',
    1,
    10,
    ''
  );

  const observer = useRef();

  const lastRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          goToNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, goToNextPage]
  );

  useEffect(() => {
    setLastElementId(data[data.length - 1]?._id);
  }, [data]);

  useEffect(() => {
    if (!allData?.optimized_all) {
      const timer = setTimeout(() => {
        handleForceRefetch();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [allData?.optimized_all, handleForceRefetch]);

  const handleStartSearch = useCallback(async () => {
    setIsUploading(true);
    await projectsAPIs
      .beginFileUpload(report?.id, report?.last_upload_batch_id)
      .then((res) => {
        dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: res.report });
        setIsUploading(false);
        createNotification('Поиск запущен');
        dialog.close();
      })
      .catch((err) => {
        setIsUploading(false);
        createErrorNotification('Произошла ошибка при попытке начать поиск');
        console.error(err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [report?.id, dialog, report?.last_upload_batch_id]);

  const handleCancelUpload = useCallback(async () => {
    setIsUploading(true);
    await projectsAPIs
      .cancelFileUpload(report?.id, report?.last_upload_batch_id)
      .then((res) => {
        dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: res.report });
        setIsUploading(false);
        createNotification('Загрузка файла отменена');
        dialog.close();
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при отмене загрузки');
        setIsUploading(false);
        console.error(err);
      });
  }, [dialog, report?.id, report?.last_upload_batch_id]);

  const handleUploadState = (state) => {
    setIsUploading(state);
  };

  const optimizedNamesNotFound = allData?.optimized_all && allData?.optimized_count === 0;

  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => dialog.close()}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '818px',
          maxWidth: mobile ? '100%' : 'inherit',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div
        className={classNames('tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-w-full tw-h-full', {
          'sm:tw-min-h-[400px]': !optimizedNamesNotFound,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="tw-pt-6 tw-px-[26px] tw-pb-[27px]">
          <p className="tw-text-[#191919] tw-font-semibold tw-leading-5">
            {allData?.optimized_all
              ? `Мы нашли более оптимальные названия для ${allData?.optimized_count ?? '-'}
            ${getWordEnding3('товар', allData?.optimized_count)} из ${allData?.count ?? '-'}`
              : 'Поиск оптимальных названий для товаров'}
          </p>
        </div>
        {optimizedNamesNotFound ? (
          <p className="tw-text-[#191919] tw-font-medium tw-px-6">
            В результате проверки наименований товаров - текущие наименования являются оптимальными для дальнейшей
            работы сервиса. Нажмите далее для продолжения
          </p>
        ) : (
          <Form onSubmit={() => {}} validateOnBlur={false}>
            {({ invalid }) => (
              <>
                {!mobile && (
                  <div className="tw-flex tw-flex-row tw-items-center tw-px-6 tw-w-full tw-max-w-[65%] tw-justify-between tw-mr-auto">
                    <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Названия товаров</p>
                    <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Поменять на</p>
                  </div>
                )}
                <div className="tw-flex tw-flex-col tw-divide-y tw-max-h-[601px] tw-overflow-auto tw-pl-[26px] tw-pr-[24px]">
                  {data?.length > 0 && allData?.optimized_all ? (
                    <>
                      {data.map((product, index) => {
                        const shouldSetInitialValue = product?.original_name !== product?.name_by_gpt;
                        const initialValue = shouldSetInitialValue ? product?.filtered_name : '';
                        return (
                          <div ref={product._id === lastElementId ? lastRef : null}>
                            {product?.usegpt && (
                              <ProductNameField
                                handleUpdateState={handleUploadState}
                                item={product}
                                index={index}
                                key={product._id}
                                initialValue={initialValue}
                              />
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[200px] tw-gap-2">
                        <p className="">Идёт проверка наименований</p>
                        <CircularProgress />
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={classNames(
                    'tw-flex tw-flex-row tw-pt-[25px] tw-px-[26px] tw-pb-6 tw-w-full tw-justify-end tw-items-center tw-gap-6 tw-mt-auto',
                    {
                      'tw-border-t tw-border-[#B2B2B233]': mobile,
                      'tw-mt-auto': !optimizedNamesNotFound,
                      'tw-mt-3': optimizedNamesNotFound,
                    }
                  )}
                >
                  <Button
                    variant="text"
                    onClick={handleCancelUpload}
                    disabled={!data || isUploading}
                    className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
                  >
                    Отменить загрузку
                  </Button>
                  <Button
                    className="tw-w-fit tw-py-3 tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold"
                    disabled={!allData?.optimized_all || isUploading || invalid}
                    onClick={handleStartSearch}
                  >
                    {optimizedNamesNotFound ? 'Далее' : 'Применить'}
                  </Button>
                </div>
              </>
            )}
          </Form>
        )}
      </div>
    </Dialog>
  );
};

const invalidCharsRegex = /[^a-zA-Zа-яА-ЯёЁ0-9\s,.%:;()+\-/]/g;

export const ProductNameField = ({ item, index, initialValue, handleUpdateState }) => {
  const { input, meta } = useField(`item-${item?._id}`);
  const [product, setProduct] = useState(item);
  const symbols_limit = useSelector((state) => state?.organizations?.selected)?.symbols_limit;
  const mobile = useMediaQuery('(max-width: 500px)');

  const handleUpdateProductName = useCallback(
    debounce(async (nameToChange) => {
      handleUpdateState(true);
      await projectsAPIs
        .updateOptimizedProductName({
          filtered_name: nameToChange,
          number: 0,
          product_id: item?._id,
        })
        .then((res) => {
          setProduct((prev) => ({
            ...prev,
            filtered_name: res.filtered_name,
          }));
        })
        .catch((err) => {
          createErrorNotification('Произошла ошибка при обновлении названия');
          console.error(err);
        })
        .finally(() => {
          handleUpdateState(false);
        });
    }, 500),
    [item?._id]
  );

  const validate = useCallback((value) => {
    if (!value) {
      return 'Название товара не может быть пустым';
    }
    if (Number.isInteger(symbols_limit) && value?.length > symbols_limit) {
      return `Длина текста не должна превышать ${symbols_limit} символов`;
    }
    const invalidChars = value?.match(invalidCharsRegex);
    if (invalidChars?.length > 0) {
      const uniqueInvalidCharts = [...new Set(invalidChars)];
      return `Название содержит недопустимые символы: ${uniqueInvalidCharts.join(',')}`;
    }
  }, [symbols_limit]);

  const resetToOriginal = useCallback(() => {
    input.onChange(product?.original_name);
    input.onBlur();
  }, [input, product?.original_name]);

  useEffect(() => {
    if (!meta?.touched) {
      input.onBlur()
    }
  }, [input, meta?.touched])

  useEffect(() => {
    if (!meta?.error && meta.dirty) {
      handleUpdateProductName(input.value);
    }
  }, [input?.value, handleUpdateProductName]);

  const isValueChanged = product.name_by_gpt !== product.filtered_name;
  return (
    <>
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-start tw-gap-[22px] tw-justify-between tw-pt-[14px] tw-pb-4">
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 sm:tw-max-w-[50%] sm:tw-w-[50%] tw-w-full tw-break-words">
          <div className="tw-flex tw-flex-row tw-items-start tw-gap-1">
            <p className="tw-text-[#666666] tw-mr-2">{index + 1}.</p>

            <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px] tw-max-h-[250px] tw-overflow-y-auto tw-pr-1">
              {product?.original_name}
            </p>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-[6px] sm:tw-w-[50%] tw-w-full">
          {mobile && <p className="tw-text-sm tw-text-[#666666] tw-leading-[18px]">Поменять на</p>}
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-w-full">
            <Field name={`item-${item?._id}`} validate={validate} initialValue={product?.filtered_name}>
              {({ input, meta }) => {
                return (
                  <div className="tw-flex tw-flex-row tw-items-start tw-gap-[9px] tw-w-full">
                    <Input
                      placeholder={'Название товара'}
                      multiline
                      inlineError
                      errorStyle={'tw-bottom-0'}
                      maxRows={10}
                      sx={{
                        fieldset: {
                          borderColor: !isValueChanged && initialValue && !meta?.error ? 'orange !important' : '',
                        },
                        '&.MuiFormControl-root': {
                          maxHeight: 'none',
                        },
                        textarea: {
                          fontSize: '14px'
                        }
                      }}
                      {...input}
                    />
                    <Button
                      variant="text"
                      className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-mt-[13px]"
                      onClick={resetToOriginal}
                    >
                      <Tooltip title={'Вернуть исходное название товара'}>
                        <ReturnIcon />
                      </Tooltip>
                    </Button>
                  </div>
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};
