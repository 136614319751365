import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { organizationsAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import Button from '../button';
import { Form } from 'react-final-form';
import Input from '../input';
import { SearchInput } from '../input/search';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { BlockedHostsItem } from './components/BlockedHostsItem';
import { isValidUrl } from '../../utils/common';

export const BlockedHostsModal = () => {
  const mobile = useMediaQuery('(max-width: 500px)');
  const dialog = useDialog();

  const [isAdding, setIsAdding] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [searchText, setSearchText] = useState(''); // пока нет пагинации и поиска на бэке
  const [blockedHosts, setBlockedHosts] = useState([]);
  const organization = useSelector((state) => state.organizations.selected);

  useEffect(() => {
    const fetchBlockedHosts = (id) => {
      organizationsAPIs
        .getBlockedSourcesByOrganization(id)
        .then((res) => {
          setBlockedHosts(res.blocked_hosts);
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при получении списка заблокированных источников');
        });
    };
    if (organization?.id) {
      fetchBlockedHosts(organization?.id);
    }
  }, [organization, refetch]);

  const handleCloseDialog = () => {
    dialog.close();
  };

  const validate = (values) => {
    const errors = {};

    if (!isValidUrl(values?.url)) {
      errors.url = 'Введите корректный URL';
    }
    return errors
  };

  const handleAddSourceToBlocked = useCallback(
    async (value) => {
      await organizationsAPIs
        .blockSource(organization.id, {
          blocked_host: value,
        })
        .then(() => {
          createNotification(`Источник ${value} добавлен в заблокированные`);
          setIsAdding(false);
          setRefetch((prev) => !prev);
        })
        .catch((err) => {
          console.error(err);
          if (err.message === 'hostname already exists') {
            createErrorNotification('Этот источник уже заблокирован');
          } else {
            createErrorNotification('Произошла ошибка при блокировке источника');
          }
        });
    },
    [organization?.id]
  );

  const onDelete = useCallback(() => {
    setRefetch((prev) => !prev);
  }, []);

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: mobile ? '100%' : '436px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-relative">
        {!isAdding ? (
          <>
            <Button
              variant="text"
              className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[14px] tw-right-[14px]"
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </Button>
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4">
              <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 ">Заблокированные источники</p>
            </div>
            {blockedHosts.length === 0 ? (
              <>
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-mb-14">
                  На текущий момент заблокированных хостов нет
                </p>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-w-full tw-gap-6">
                  <Button
                    variant="text"
                    className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-leading-[18px] tw-text-sm tw-font-semibold"
                    onClick={handleCloseDialog}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-py-3 tw-px-4 tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold"
                    onClick={() => setIsAdding(true)}
                  >
                    Добавить
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mb-4">
                    <Form onSubmit={() => {}}>
                      {() => (
                        <SearchInput
                          onSearchChange={(value) => {
                            setSearchText(value);
                          }}
                          name="search"
                          type="text"
                          placeholder="Найти источник"
                          className=""
                        />
                      )}
                    </Form>
                    <Button
                      variant="text"
                      className="tw-p-0 tw-w-fit tw-text-sm tw-leading-[18px] tw-font-medium tw-flex tw-flex-row tw-gap-1 tw-items-center"
                      onClick={() => setIsAdding(true)}
                    >
                      <AddIcon />
                      Добавить источник
                    </Button>
                  </div>
                  <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-divide-y">
                    {blockedHosts
                      .filter((host) => host.includes(searchText))
                      .map((host) => (
                        <BlockedHostsItem key={host} item={host} onSuccess={onDelete} />
                      ))}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <Form
              onSubmit={(values) => handleAddSourceToBlocked(values?.url)}
              validate={validate}
              initialValues={{
                url: '',
              }}
            >
              {({ handleSubmit, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <p className="tw-text-[#191919] tw-font-semibold tw-leading-5 tw-mb-4">
                    Добавить источник в заблокированные
                  </p>
                  <div className="tw-flex tw-flex-col tw-gap-14">
                    <Input name='url' placeholder={'Ссылка на источник'} required />

                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-w-full tw-gap-6">
                      <Button
                        variant="text"
                        className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-leading-[18px] tw-text-sm tw-font-semibold"
                        onClick={() => setIsAdding(false)}
                      >
                        Назад
                      </Button>
                      <Button
                        type="submit"
                        disabled={pristine || invalid}
                        className="tw-py-3 tw-px-4 tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold"
                      >
                        Добавить
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </>
        )}
      </div>
    </Dialog>
  );
};
