import { useCallback, useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import Table from '../../components/table';
import { usePagination } from '../../components/table/usePagination';
import { testAPIs } from '../../services/testsAPIs';
import { createErrorNotification } from '../../utils/notifications';
import { Pagination } from '../../components/table/pagination';

export const TestResultsPage = () => {
  const [tests, setTests] = useState([]);
  const getTests = useCallback(async (page_size, page_num) => {
    const res = await testAPIs.getResults(page_num, page_size).catch((err) => {
      createErrorNotification('Произошла ошибка при получении результатов');
      console.error(err);
    });
    return res;
  }, []);
  const { currentPage, goToPage, total, setPageSize, pageSize, allResults } = usePagination(getTests);
  useEffect(() => {
    setTests(allResults?.response);
  }, [allResults]);
  const columns = {
    id: {
        label: 'ID',
    },
    total: {
      label: 'Найдено товаров',
      renderBodyCell: (value) => <p>{`${value?.success_count}/${value?.success_count + value?.failed_count}`}</p>,
    },
    completed: {
      label: 'Выполнено',
      renderBodyCell: (value) => <p>{`${value?.success_count}/${value?.success_count + value?.failed_count}`}</p>,
    },
    time: {
      label: 'Время выполнения',
      renderBodyCell: (value) => <p>{value?.avr_time} сек.</p>,
    },
  };
  return (
    <GeneralLayout>
      <h2 className="tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-mb-5 sm:tw-mb-[34px]">
        Результаты тестов
      </h2>
      <div className="tw-flex tw-flex-col tw-bg-[#fff] tw-rounded-lg">
        <Table columns={columns} rows={tests} />
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={setPageSize}
      />
    </GeneralLayout>
  );
};
