import { Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import { createErrorNotification } from '../../../utils/notifications';
import { Form } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';

export const ModalCopyConfirm = ({handleAgree, title, btnProp}) => {
  const dialog = useDialog();

  return (
    <Dialog open={dialog.visibility}>
      <Form
        onSubmit={handleAgree}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-p-6 tw-rounded-lg">
              <p className="tw-text-[#191919] tw-font-semibold tw-mb-14 tw-text-base">{title}</p>
              <DialogActions className="tw-gap-[18px]">
                <Button
                  className="tw-mr-[20px] tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
                  variant="text"
                  onClick={() => dialog.close()}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                  disabled={submitting}
                  type="submit"
                >
                  {btnProp}
                </Button>
              </DialogActions>
            </div>
            {submitError && createErrorNotification(submitError)}
          </form>
        )}
      ></Form>
    </Dialog>
  )
}