import { Dialog, DialogActions } from '@mui/material';
import { useDialog } from '../../../../../providers/dialog.provider';
import Button from '../../../../../components/button';
import { Form } from 'react-final-form';
import Input from '../../../../../components/input';
import { useCallback } from 'react';
import { adminAPIs } from '../../../../../services/adminAPIs';
import { createErrorNotification, createNotification } from '../../../../../utils/notifications';

export const HardReloadModal = () => {
  const dialog = useDialog();

  const handleSubmit = useCallback(
    async (id) => {
      await adminAPIs
        .adminProjectReload(id)
        .then(() => {
          createNotification('Перезапуск запущен');
          dialog.close();
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при попытке перезапуска проекта');
        });
    },
    [dialog]
  );

  return (
    <Dialog open={dialog.visibility}>
      <Form
        initialValues={{
          id: '',
        }}
        onSubmit={({ id }) => {
          handleSubmit(id);
        }}
        render={({ handleSubmit, submitting, invalid, pristine }) => (
          <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-min-w-[320px] md:tw-min-w-[440px]">
            <p className="tw-text-[#191919] tw-font-semibold tw-mb-6">Полная перезагрузка проекта</p>
            <Input name="id" type="text" required placeholder={'ID проекта'} className="tw-mb-10" />
            <DialogActions>
              <div className="tw-flex tw-flex-row tw-w-full tw-justify-end tw-gap-4">
                <Button
                  variant="text"
                  onClick={() => dialog.close()}
                  className="tw-w-fit hover:tw-bg-transparent tw-px-0 tw-text-[#666] tw-text-sm"
                >
                  Отмена
                </Button>
                <Button
                  className="tw-w-fit tw-rounded-lg tw-font-semibold tw-text-sm"
                  onClick={handleSubmit}
                  disabled={submitting || invalid || pristine}
                >
                  Перезагрузить
                </Button>
              </div>
            </DialogActions>
          </div>
        )}
      />
    </Dialog>
  );
};
