import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GeneralLayout from '../../components/general-layout';
import Button from '../../components/button';
import Loader from '../../components/loader';
import classes from './saved.prices.module.sass';
import Input from '../../components/input';
import { Form } from 'react-final-form';
import { getArchivedReportsFile, getSavedSources } from '../../store/actions/organizations';
import ProductStatisticCard from '../../components/product-statistics-card';
import { ConfirmModal } from '../../components/confirm-modal';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const monthButtons = [
  { label: '1 месяц', value: 1 },
  { label: '3 месяца', value: 3 },
  { label: '6 месяцев', value: 6 },
];

export default function SavedPricesPage() {
  const savedSources = useSelector((state) => state.organizations.savedSources);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({
    query: '',
    periodMonth: 1,
    page_num: 1,
    page_size: 100,
  });

  const { id } = useParams();
  useEffect(() => {
    getSavedSources(id, filters.query, filters.periodMonth, { page_num: filters.page_num, page_size: filters.page_size })
  }, [])

  const handleGetArchivedPrices = async (query, periodMonth) => {
    try {
      setLoader(true);
      setFilters({ ...filters, query, periodMonth });
      await getSavedSources(id, query, periodMonth, { page_num: filters.page_num, page_size: filters.page_size });
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };

  const fetchReportFile = async () => {
    await getArchivedReportsFile(id).then((res) => {
      if (res === false) {
        return;
      }
    });
  };

  const handleSubmit = (values) => {
    const query = values.query ?? '';
    const periodMonth = filters.periodMonth ?? 1;
    handleGetArchivedPrices(query, periodMonth);
  };

  const handleReloadSavedSource = () => {
    handleGetArchivedPrices(filters.query, filters.periodMonth);
  };

  return (
    <GeneralLayout>
      <Container className={classes.container}>
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Input className={classes.calendarInput} name="query" placeholder="Введите наименование товара" />
              <Button className={classes.searchBtn} type="submit">
                Поиск
              </Button>
              <Button className={classes.exportBtn} onClick={fetchReportFile}>
                Экспорт в отчет
              </Button>
            </form>
          )}
        </Form>

        <div className={classes.selectMonth}>
          <div className={classes.title}>Вывести статистику за:</div>
          {monthButtons.map((button) => (
            <Button
              onClick={() => handleGetArchivedPrices(filters.query, button.value)}
              secondary
              size="small"
              key={button.value}
              className={classes.button}
              disabled={filters.periodMonth === button.value}
            >
              {button.label}
            </Button>
          ))}
        </div>
        {loader && <Loader />}
        {savedSources &&
          savedSources.map((product, i) => (
            <div className={classes.savedSources} key={i}>
              <ProductStatisticCard product={product} onReloadSavedSources={handleReloadSavedSource} />
            </div>
          ))}
        <ConfirmModal title="Вы уверены, что хотите удалить этот сохраненный источник?" />
      </Container>
    </GeneralLayout>
  );
}
