import moment from 'moment';
import { useCallback, useState } from 'react';
import { downloadFile } from '../../../utils/request';
import { createErrorNotification } from '../../../utils/notifications';
import RangePicker from '../../../components/range-picker';
import Button from '../../../components/button';
import Loader from '../../../components/loader';
import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon.svg';

export const YandexGPT = () => {
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month').startOf('hour').unix() * 1000,
    till: moment().startOf('hour').unix() * 1000,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    setIsLoading(true);
    await downloadFile(
      `/api/admin/users/export/searcher_requests?since=${moment(queryParams.since).format('DD-MM-YYYY')}&till=${moment(
        queryParams.till
      ).format('DD-MM-YYYY')}`,
      'UsedRequests.xlsx'
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при попытке скачивания файла');
        console.error(err);
        setIsLoading(false);
      });
  }, [queryParams]);

  return (
    <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-py-5">
      <div className="tw-mb-4">
        <RangePicker queryParams={queryParams} setQueryParams={setQueryParams} />
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
        <p className="tw-text-[#191919] tw-font-medium tw-text-lg tw-leading-[22px]">Использованные запросы</p>
        <Button variant="text" className="tw-w-fit tw-p-0 hover:tw-bg-transparent" onClick={() => handleDownload()}>
          {isLoading ? <Loader /> : <DownloadIcon />}
        </Button>
      </div>
    </div>
  );
};
