import { useCallback, useEffect } from 'react';
import { useDialog } from '../../providers/dialog.provider';
import { userAPIs } from '../../services';
import { GuideModal } from './GuideModal';

export const UserGuide = () => {
  const dialog = useDialog();

  const openUserGuide = useCallback(() => {
    dialog.open(<GuideModal />);
  }, [dialog]);

  const hasSeenModal = useCallback(() => {
    const status = sessionStorage.getItem('userGuideModalSeen')
    if (status === null) {
        sessionStorage.setItem('userGuideModalSeen', false)
    }
    return sessionStorage.getItem('userGuideModalSeen') === 'true'
  }, [])

  useEffect(() => {
    const checkUserReadStatus = async () => {
      await userAPIs.getUserReadGuideStatus().then((res) => {
        const hasSeen = hasSeenModal()
        
        if (Object.keys(res).length === 0) {
            !hasSeen && openUserGuide()
        }
      });
    };
    checkUserReadStatus()
  }, [hasSeenModal, openUserGuide]);
  return <></>;
};
