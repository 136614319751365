import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { organizationsAPIs } from '../../../services';
import { ReactComponent as SearchIcon } from '../../../icons/searchIcon.svg';
import { SearchInput } from '../../input/search';
import { Form } from 'react-final-form';
import classes from '../top-header.module.sass';
import { createErrorNotification } from '../../../utils/notifications';
import { useEffect } from 'react';
import { Collapse } from '@mui/material';
import { useCallback } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { SearchResults } from './SearchResults';
import { useOutsideClickListener } from '../../../hooks/useOutsideClickListener';

function Search() {
  const organization = useSelector((state) => state.organizations.selected);
  const [searchText, setSearchText] = useState('');
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [data, setData] = useState(null);
  const searchInputRef = useRef(null)

  const dialog = useDialog();

  const handleOutsideClick = () => {
    setSearchBarVisible(false);
  };
  const ref = useOutsideClickListener(handleOutsideClick);

  const fetchResults = useCallback(
    async (searchText) => {
      await organizationsAPIs
        .searchInOrganization(organization?.id, searchText)
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          console.error(e);
          createErrorNotification('Что-то пошло не так');
        });
    },
    [organization]
  );

  useEffect(() => {
    if (searchText) {
      fetchResults(searchText);
    }
  }, [searchText, fetchResults]);

  const handleSearchTextChange = (value) => {
    setSearchText(value);
  };

  const [scrollTop, setScrollTop] = useState(0);
  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showResultsModal = useCallback(() => {
    dialog.open(
      <SearchResults
        data={data}
        searchText={searchText}
        onChangeSearchQuery={fetchResults}
        organizationId={organization?.id}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, data, searchText]);

  useEffect(() => {
    if (data) {
      showResultsModal();
    }
    return () => {
      setData(null);
    };
  }, [data, showResultsModal]);
  useEffect(() => {
    if (searchBarVisible) {
      searchInputRef?.current?.focus()
    }
  }, [searchBarVisible])

  return (
    <>
      <div
        ref={ref}
        className="tw-bg-[#F4F7FE] tw-px-4 tw-py-2 tw-rounded-full tw-cursor-text tw-relative tw-z-10 "
        onClick={(e) => {
          e.stopPropagation();
          setSearchBarVisible(true);
        }}
      >
        <SearchIcon className={`tw-w-[18px] tw-h-[18px] ${searchBarVisible && 'tw-hidden'}`} />

        <Collapse orientation="horizontal" in={searchBarVisible} className={`${!searchBarVisible && 'tw-h-0'}`}>
          <Form onSubmit={() => {}}>
            {() => (
              <SearchInput
                inputRef={searchInputRef}
                className={`${classes.searchBar}`}
                onSearchChange={handleSearchTextChange}
                name="header-search"
                placeholder="Поиск"
                type="text"
                sx={{
                  '.MuiInputBase-root': {
                    transition: 'background-color 0.5s ease',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  },
                  'fieldset': {
                    border: 'none !important'
                  }
                }}
              />
            )}
          </Form>
        </Collapse>
      </div>
    </>
  );
}

export default Search;
