import GeneralLayout from '../../../components/general-layout';
import React from 'react';
import { MenuItem } from '@mui/material';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as CopyIcon } from '../../../icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg';
import {
  useCreateNotificationsMutation,
  useEditNotificationMutation,
  useGetDetailNotificationQuery,
  useRemoveNotificationMutation
} from '../../../services/adminAPIs';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/loader';
import { ModalNotification } from '../ModalNotification';
import { useDialog } from '../../../providers/dialog.provider';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';

export const NotificationDetail = () => {
  const dialog = useDialog();
  const { id } = useParams();
  const [editNotificationMutation] = useEditNotificationMutation();
  const history = useHistory();

  const { data, isLoading } = useGetDetailNotificationQuery(id);
  const [removeNotificationMutation] = useRemoveNotificationMutation();
  const [createNotificationMutation] = useCreateNotificationsMutation();
  const handleEditNotification = async (message_id, formData) => {
    const body = {
      end_date: formData.end_date,
      end_time: formData.end_time,
      start_date: formData.start_date,
      start_time: formData.start_time,
      text: formData.text,
    };

    try {
      await editNotificationMutation({ message_id, body }).unwrap();
      createNotification('Оповещение отредактировано');
      history.push('/notifications')
      dialog.close();
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };

  const handleCreateNotification = async (formData) => {
    const body = {
      end_date: formData.end_date,
      end_time: formData.end_time,
      start_date: formData.start_date,
      start_time: formData.start_time,
      text: formData.text,
    };

    try {
      await createNotificationMutation(body).unwrap();
      createNotification('Оповещение создано');
      dialog.close();
      history.push('/notifications')
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };

  const openCopyNotification = (entity) => {
    dialog.open(
      <ModalNotification
        initialValues={entity}
        btnSubmit="Создать"
        title="Копирование оповещения"
        handleSubmit={handleCreateNotification}
      />
    )
  }

  const handleDeleteNotification = async (message_id) => {
    try {
      await removeNotificationMutation(message_id).unwrap();
      createNotification('Оповещение удалено');
      history.push('/notifications');
    } catch (error) {
      createErrorNotification(`Ошибка при создании уведомления: ${error?.data?.message}`);
    }
  };

  const deleteNotification = (message_id) => {
    dialog.open(
      <ConfirmModal
        title="Вы действительно хотите удалить оповещение?"
        onAgree={() => handleDeleteNotification(message_id)}
      />
    )
  }

  const openEditNotification = (entity) => {
    dialog.open(
      <ModalNotification
        initialValues={entity}
        btnSubmit="Редактировать"
        title="Редактирование оповещения"
        handleSubmit={(formData) => handleEditNotification(entity?.id, formData)}
      />
    )
  }


  return(
    <GeneralLayout>
      {isLoading ? (
        <div>
          <Loader/>
        </div>) : (
        <>
          <h2
            className="tw-text-[#191919] tw-text-[24px] sm:tw-text-[32px] tw-leading-[28px] sm:tw-leading-[38px] tw-mb-6 md:tw-mb-8">
            Оповещения
          </h2>
          <div className="tw-p-6 tw-bg-[#fff] tw-rounded-lg">
            <div className="tw-flex tw-flex-col tw-mb-[20px]">
              <p className="tw-text-[#666666] tw-text-sm tw-font-normal">Создано</p>
              <p className="tw-text-[#191919] tw-font-medium tw-text-sm">
                {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-mb-[20px]">
              <p className="tw-text-[#666666] tw-text-sm tw-font-normal">Текст</p>
              <p className="tw-text-[#191919] tw-font-medium tw-text-sm">
                {data?.text}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-mb-[20px]">
              <p className="tw-text-[#666666] tw-text-sm tw-font-normal">Начало</p>
              <p className="tw-text-[#191919] tw-font-medium tw-text-sm">
                {data?.start_date && data?.start_time
                  ? moment(`${data.start_date} ${data.start_time}`).format('DD.MM.YYYY HH:mm')
                  : 'Не указано'}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-mb-[20px]">
              <p className="tw-text-[#666666] tw-text-sm tw-font-normal">Завершение</p>
              <p className="tw-text-[#191919] tw-font-medium tw-text-sm">
                {moment(data?.end_date).format('DD.MM.YYYY')} {moment(data?.end_time, 'HH:mm:ss').format('HH:mm')}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-mb-10">
              <p className="tw-text-[#666666] tw-text-sm tw-font-normal">Автор</p>
              <p className="tw-text-[#191919] tw-font-medium tw-text-sm">
                {data?.author}
              </p>
            </div>
            <div>
              <MenuItem
                className="hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-text-[#134B98] tw-p-0 tw-mb-[20px]"
                onClick={() => openEditNotification(data)}
                disableRipple
              >
                <EditIcon width="20px" height="20px" className="tw-mr-2" />
                Редактировать оповещение
              </MenuItem>
              <MenuItem
                className="hover:tw-bg-transparent hover:tw-text-[#666666] tw-text-[#666666] tw-p-0 tw-mb-[20px]"
                onClick={() => openCopyNotification(data)}
                disableRipple
              >
                <CopyIcon width="20px" height="20px" className="tw-mr-2" />
                Копировать оповещение
              </MenuItem>
              <MenuItem
                className="hover:tw-bg-transparent hover:tw-text-[#C12026] tw-text-[#C12026] tw-p-0 tw-mb-[20px]"
                onClick={() => deleteNotification(id)}
                disableRipple
              >
                <DeleteIcon width="20px" height="20px" className="tw-mr-2" />
                Удалить оповещение
              </MenuItem>

            </div>
          </div>
        </>
      )}
    </GeneralLayout>
  )
}