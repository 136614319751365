import { Radio, FormControlLabel } from '@mui/material';
import { Field } from 'react-final-form';

export const RadioGroup = ({ options, name, ...props }) => {
  return Object.entries(options).map(([key, label]) => (
    <div key={key}>
      <FormControlLabel
        label={label}
        key={key}
        control={
          <Field
            name={name}
            value={key}
            type='radio'
            {...props}
            render={({input}) => <Radio {...input} />}
          />
        }
      />
    </div>
  ));
};


