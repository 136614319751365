export const EVENTS = {
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  SHOW_ARCHIVE_SOURCE: 'SHOW_ARCHIVE_SOURCE',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  OPEN_TECHNICAL_SUPPORT: 'OPEN_TECHNICAL_SUPPORT',
  OPEN_DIALOG: 'OPEN_DIALOG',
};

export const ORGANIZATION_TYPE_OPTIONS = Object.freeze({
  1: 'Государственный заказчик',
  2: 'Муниципальный заказчик',
  3: 'Автономное учреждение',
  4: 'Иные',
});

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';

export const SOURCE_TYPES_VALUE = {
  YANDEX_SEARCH: 'yandex_search',
  ENGINE_SEARCH: 'engine_search',
  ZAKUPKI: 'zakupki',
  UNKNOWN_PRICE: 'unknown_price',
};

export const SOURCE_TYPES = {
  open_sources: 'Открытые источники',
  zakupki: 'Государственные контракты',
  suppliers: 'Поставщики',
};

export const FREQUENCY_OPTIONS_LABEL = {
  mondays: 'По понедельникам',
  tuesdays: 'По вторникам',
  wednesdays: 'По средам',
  thursdays: 'По четвергам',
  fridays: 'По пятницам',
  saturdays: 'По субботам',
  sundays: 'По воскресеньям',
  everyday: 'Каждый день',
};

export const USER_ROLE_OPTIONS = [
  { label: 'Юзер', value: 'user' },
  { label: 'Админ', value: 'admin' },
];

export const IMAGES_CATEGORIES = [
  'simple-cart',
  'Авто',
  'Аптека',
  'Бытовая техника',
  'Бытовая химия',
  'Дача и сад',
  'Детские товары',
  'Для школы и офиса',
  'Дом',
  'Здравоохранение',
  'Зоотовары',
  'Канцелярские товары',
  'Компьютеры',
  'Красота и гигиена',
  'Мебель',
  'Оборудование',
  'Одежда и обувь',
  'Оптика',
  'Продукты питания',
  'Спорт и отдых',
  'Строительство и ремонт',
  'Товары для взрослых',
  'Транспорт',
  'Хобби и творчество',
  'Хозяйственный инвентарь',
  'Цветы',
  'Цифровые товары',
  'Электроника',
  'Ювелирные украшения',
];

export const UNIT_OPTIONS = Object.freeze([
  { value: 'шт', label: 'шт' },
  { value: 'кг', label: 'кг' },
  { value: 'л', label: 'л' },
]);

export const REPORT_STATUS = Object.freeze({
  INITIAL: 'initial',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  FAILED: 'failed',
  STOPPED: 'stopped',
  ALL_STOPPED: 'all_stopped',
  OPTIMIZED: 'optimized',
  SCREENSHOOTING: 'screenshotting'
});

export const PRODUCT_STATUS = Object.freeze({
  INITIAL: 'initial',
  SEARCHING: 'searching',
  PARSING: 'parsing',
  FAILED: 'failed',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  FAILED_SEARCHER: 'failed_searcher',
});

export const DELIVERY_SERVICES = Object.freeze({
  1: 'СДЕК',
  2: 'Почта России',
  3: 'DPD',
  4: 'ПЭК',
  5: 'ЖелДорЭкспедиция',
  6: 'ГК «Деловые линии»',
  7: 'CберЛогистика',
});

export const CALCULATED_DIMENSHIONS_FROM = Object.freeze({
  source: 'из источника',
  default: 'по умолчанию',
  user_data: 'введенными вами данными',
  catalogue: 'из справочника габаритов',
});

export const PRODUCT_LOADING_STATES = Object.freeze([
  PRODUCT_STATUS.PARSING,
  PRODUCT_STATUS.INITIAL,
  PRODUCT_STATUS.SEARCHING,
]);

export const RESTARTABLE_REPORT_STATES = Object.freeze([
  REPORT_STATUS.FINISHED,
  REPORT_STATUS.STOPPED,
  REPORT_STATUS.ALL_STOPPED,
]);

export const EXCEL_MIME_TYPES = Object.freeze([
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]);

export const FILTER_PRESET_LABELS = {
  NAME_DESC: 'Название (по убыванию)',
  NAME_ASC: 'Название (по возрастанию)',
  CREATED_DESC: 'Порядок добавления (по убыванию)',
  CREATED_ASC: 'Порядок добавления (по возрастанию)',
  PRICE_DESC: 'Цена (по убыванию)',
  PRICE_ASC: 'Цена (по возрастанию)',
};

export const FILTER_PRESETS = Object.freeze({
  [FILTER_PRESET_LABELS.NAME_DESC]: {
    sort: {
      order: 'desc',
      field: 'name',
    },
  },
  [FILTER_PRESET_LABELS.NAME_ASC]: {
    sort: {
      order: 'asc',
      field: 'name',
    },
  },
  [FILTER_PRESET_LABELS.CREATED_DESC]: {
    sort: {
      order: 'desc',
      field: 'created_at',
    },
  },
  [FILTER_PRESET_LABELS.CREATED_ASC]: {
    sort: {
      order: 'asc',
      field: 'created_at',
    },
  },
  [FILTER_PRESET_LABELS.PRICE_DESC]: {
    sort: {
      order: 'desc',
      field: 'avg_price',
    },
  },
  [FILTER_PRESET_LABELS.PRICE_ASC]: {
    sort: {
      order: 'asc',
      field: 'avg_price',
    },
  },
});

export const BACKGROUNDCOLOR_BY_STATUS = Object.freeze({
  [REPORT_STATUS.STOPPED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.ALL_STOPPED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.FAILED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.OPTIMIZED]: 'tw-bg-[#FFFBF5]',
  [REPORT_STATUS.PROCESSING]: 'tw-bg-[#FFFBF5]',
  [REPORT_STATUS.INITIAL]: 'tw-bg-[#FFFBF5]',
});

export const IMAGE_MIME_TYPES = Object.freeze(['image/png', 'image/jpeg']);

export const REPORT_SCREENSHOTS_STATUS = Object.freeze({
  INITIAL: 'initial',
  PROCESSING: 'processing',
  UPDATING: 'updating',
  FINISHED: 'finished',
});


export const STEPS_SECTIONS = Object.freeze({
  deskTour: [
    {
      target: 'body',
      placement: 'center',
      roles: ['admin', 'user'],
      showSkipButton: true,
      hideCloseButton: true,
      locale: {
        next: 'Продолжить',
        skip: 'Пропустить',
      },
      styles: {
        tooltip: {
          width: '100%',
          maxWidth: '436px',
          padding: "4px 24px 24px 24px",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        },
        buttonNext: {
          backgroundColor: '#134B98',
          color: '#fff',
          borderRadius: '8px',
          padding: '12px 16px',
          outline: 'none',
        },
        buttonSkip: {
          backgroundColor: 'rgba(240,240,240,0)', // Серый цвет для кнопки "Пропустить"
          color: '#666666',
          padding: '12px 16px',
          marginRight: '10px',
        },
        content: {
          padding: 0
        }
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <h2 className='fw-bold fs-6 mb-3'>Добро пожаловать на Сервис предиктивной аналитики!</h2>
          <p>Для того, чтобы приступить к работе предлагаем вам познакомиться с нашей системой</p>
        </div>
      ),
    },
    {
      target: '[data-tour="projectMenuStep"]',
      placement: 'right',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <div className='fw-bolder mb-2'>Новый проект</div>
          <div style={{ color: '#4C4C4C' }}>Для того, чтобы начать работу, перейдите в раздел «Проекты»</div>
        </div>
      ),
    },
    {
      target: '[data-tour="suppliersTooltip"]',
      placement: 'right',
      roles: ['admin'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <div className='fw-bolder mb-2'>Поставщики</div>
          <div style={{ color: '#4C4C4C' }}>
            Используйте функционал чат-бота для общения с поставщиками. Зайдите в раздел «Поставщики»
          </div>
        </div>
      )
    },
    {
      target: '[data-tour="supportTooltip"]',
      placement: 'right',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <div className="fw-bolder mb-2">Тех. поддержка</div>
          <div style={{ color: '#4C4C4C' }}>
            Воспользуйтесь разделом «Тех. поддержка» при возникновении проблем при работе с сервисом
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="answersSupportTooltip"]',
      placement: 'right',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <div className="fw-bolder mb-2">Ответы тех. поддержки</div>
          <div style={{ color: '#4C4C4C' }}>
            Получайте ответы на ваши вопросы в разделе «Ответы тех. поддержки»
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="FAQTooltip"]',
      placement: 'right',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2"> Часто задаваемые вопросы (FAQ)</div>
          <div style={{color: '#4C4C4C'}}>Ответы на самые популярные вопросы, которые могут возникнуть у вас при использовании нашего сервиса</div>
        </div>
      ),
    },
    {
      target: '[data-tour="managementTooltipTarget"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      hideCloseButton: false,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none'
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Руководство </div>
          <div style={{color: '#4C4C4C'}}>Если у вас еще остались вопросы воспользуйтесь «Руководством по работе с сервисом»
            на рабочем столе и «Руководством по логистике»</div>
        </div>
      ),
    },
  ],
  projectsTour: [
    {
      target: '[data-tour="exampleExcelStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Шаблон отчета</div>
          <div style={{color: '#4C4C4C'}}>Если у вас несколько позиций товара воспользуйтесь шаблоном</div>
        </div>
      ),
    },
    {
      target: '[data-tour="newProjectStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      hideCloseButton: false,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none'
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Создание проекта</div>
          <div style={{color: '#4C4C4C'}}>Для создания проекта нажмите на «Новый проект» </div>
        </div>
      ),
    },
  ],
  emptyProjectTour: [
    {
      target: '[data-tour="uploadDoc"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      hideCloseButton: false,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Загрузка отчета</div>
          <div style={{color: '#4C4C4C'}}>
            Выберите файл и загрузите его в систему для получения готового отчета по своему проекту
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="regionStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      hideCloseButton: false,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Выбор региона</div>
          <div style={{color: '#4C4C4C'}}>
            Выберите нужный вам регион для поиска
          </div>
        </div>
      ),
    },
  ],
  detailProjectTour: [
    {
      target: '[data-tour="reloadStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Перезапустить поиск</div>
          <div style={{color: '#4C4C4C'}}>
            Если нашлись не все товары из списка, нажмите
            «Перезапустить поиск». Поиск будет перезапущен
            только в позициях, которые не найдены.
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="sourceStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Источники</div>
          <div style={{color: '#4C4C4C'}}>
            Ознакомьтесь с найденными источниками
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="detailInfoStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Информация о логистике</div>
          <div style={{color: '#4C4C4C'}}>
            Для получении более детальной информации о логистике нажмите на кнопку «Детально»
          </div>
        </div>
      ),
    },
  ],
  sourcesInsideTour: [
    {
      target: '[data-tour="logisticStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Доставка и характеристики товара </div>
          <div style={{color: '#4C4C4C'}}>
            Чтобы ознакомиться с вариантами доставки
            или цена в логистике некорректная, нажмите
            на кнопку «Габаритные размеры».
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="wrongPriceStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Некорректная цена </div>
          <div style={{color: '#4C4C4C'}}>
            Если вы заметили, что цена указанная в системе отличается от цены в источнике, нажмите на кнопку «Некорректная цена»
          </div>
        </div>
      ),
    },
  ],
  suppliersDetailTour: [
    {
      target: '[data-tour="regionSupplierStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Регион</div>
          <div style={{color: '#4C4C4C'}}>
            Выберите регион для поиска
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="categorySupplierStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      locale: {
        next: 'Далее',
      },
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          backgroundColor: "transparent",
          color: '#134B98',
          padding: '12px 16px',
          outline: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Категории</div>
          <div style={{color: '#4C4C4C'}}>
            Выберите категорию для более точного поиска
          </div>
        </div>
      ),
    },
    {
      target: '[data-tour="niceSuppliersStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Добросовестный поставщик</div>
          <div style={{color: '#4C4C4C'}}>
            У нас имеется список гарантированных поставщиков, если хотите работать с ними, выберите «Гарантированный поставщик»
          </div>
        </div>
      ),
    },
  ],
  techSupportTour: [
    {
      target: '[data-tour="techSupportStep"]',
      placement: 'bottom',
      roles: ['admin', 'user'],
      hideBackButton: true,
      styles: {
        tooltip: {
          padding: 0,
          width: '100%',
          maxWidth: '540px',
          borderBottom: '4px solid #0BD90B'
        },
        buttonNext: {
          display: 'none',
        },
      },
      content: (
        <div style={{textAlign: 'left'}}>
          <div className="fw-bolder mb-2">Тех. поддержка</div>
          <div style={{color: '#4C4C4C'}}>
            Заполните форму и отправьте ее, наш менеджер ответит вам в ближайшее время и поможет решить проблему
          </div>
        </div>
      ),
    },
  ]
});

export const BLOCK_REASONS = Object.freeze([
  'Источник с отсутствующей ценой (цена по запросу)',
  'Источник товаров, снятых с производства',
  'Источник товаров, которые отсутствуют в наличии',
  'Источники на основании коэффициента отклонения цены товара из источника от исходной цены (значение коэффициента отклонения может меняться)',
  'Источники с истекшим SSL-сертификатом',
  'Источники, использующие иностранную валюту',
]);
