import { useSelector } from 'react-redux';
import Search from './components/Search';
import UserZone from './components/UserZone';
import { Badge } from '@mui/material';
import classes from './top-header.module.sass'
import NotificationDropdown from './components/NotificationDropdown';
import classNames from 'classnames';
import { useGetNotificationsForUsersQuery } from '../../services/adminAPIs';

function TopHeader({isServerError}) {
  const user = useSelector((state) => state.auth.user);
  const organization = useSelector((state) => state.organizations.selected);
  const { data } = useGetNotificationsForUsersQuery({});
  return (
    <>
      <header className={classNames(
        'tw-absolute tw-z-60',
        'tw-right-4 sm:tw-right-8',
        'tw-shadow-[0_2px_12px_0_rgba(178, 178, 178, 0.2)] tw-bg-white tw-border tw-border-[#EEE] tw-rounded-full',
        'tw-flex tw-flex-row tw-gap-4 tw-items-center tw-py-[6px] tw-pl-2 tw-pr-1',
        {
          'tw-top-[10px] sm:tw-top-6': !isServerError,
          [classes.headerWithServerError]: isServerError,
        }
      )}>
        <Search />
          <Badge
            badgeContent={data?.countUnread || 0}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#C12026",
                color: "#fff",
                zIndex: 10,
              },
            }}
            className='tw-relative tw-z-10'>
            <NotificationDropdown />
          </Badge>
        <UserZone user={user} organization={organization} />
      </header>
    </>
  );
}

export default TopHeader;
