import { Dialog, Radio, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { useCallback, useState } from 'react';
import { ReactComponent as AttachmentIcon } from '../../../../icons/attachmentIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import Button from '../../../../components/button';
import { organizationsAPIs } from '../../../../services';

export const UploadOrganizationFiles = () => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');
  const [selectedVariant, setSelectedVariant] = useState('inn');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (e) => {
    setSelectedVariant(e.target.value);
  };
  const handleAddFile = (file) => {
    if (!file?.name?.endsWith('.xlsx')) {
      createErrorNotification('Файл должен быть формата .xlsx');
      return;
    }
    setSelectedFile(file);
  };

  const handleClose = () => {
    setSelectedFile(null);
    dialog.close();
  };

  const handleUploadFile = useCallback(async () => {
    setIsSubmitting(true);
    const body = new FormData();
    body.append('file', selectedFile);

    let updateFunction;
    if (selectedVariant === 'inn') {
      updateFunction = organizationsAPIs.updateOrganizationsInn;
    } else {
      updateFunction = organizationsAPIs.updateOrganizationsLimits;
    }

    await updateFunction(body)
      .then(() => {
        createNotification('Файл успешно загружен');
        setIsSubmitting(false);
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при загрузке файла');
        setIsSubmitting(false);
      });
  }, [dialog, selectedFile, selectedVariant]);
  return (
    <>
      <Dialog
        open={dialog.visibility}
        className="tw-rounded-lg"
        sx={{
          '& .MuiPaper-root': {
            width: mobile ? '90%' : '440px',
            margin: mobile ? '0px' : '32px',
          },
        }}
      >
        <div className="tw-rounded-lg tw-bg-white tw-flex tw-flex-col tw-gap-6 tw-p-6">
          <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-2">Обновление организаций</p>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <Radio
                disableRipple
                className="tw-p-0"
                checked={selectedVariant === 'inn'}
                onChange={handleChange}
                value="inn"
                name="variants"
              />
              <p className="">Обновление ИНН организаций</p>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <Radio
                disableRipple
                className="tw-p-0"
                checked={selectedVariant === 'limits'}
                onChange={handleChange}
                value="limits"
                name="variants"
              />
              <p className="">Обновление лимитов организаций</p>
            </div>
          </div>
          <div>
            {!selectedFile ? (
              <>
                <input
                  type="file"
                  name="files"
                  id="uploadBtn"
                  hidden
                  accept=".xlsx"
                  onChange={(e) => {
                    handleAddFile(e.target.files[0]);
                  }}
                />
                <label for="uploadBtn" className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer">
                  <AttachmentIcon className="tw-mr-1" /> Загрузить Excel файл
                </label>
              </>
            ) : (
              <>
                <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                  <p className="tw-text-sm tw-text-[#191919]">{selectedFile.name}</p>{' '}
                  <CloseIcon className="tw-w-4 tw-h-4 tw-cursor-pointer" onClick={() => setSelectedFile(null)} />
                </div>
              </>
            )}
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-6 tw-mt-4">
            <Button
              variant="text"
              className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm hover:tw-bg-transparent tw-leading-[18px] hover:tw-text-[#7f7f7f]"
              disabled={isSubmitting}
              onClick={() => handleClose()}
            >
              Отменить
            </Button>
            <Button
              className="tw-w-fit tw-px-4 tw-py-3 tw-text-white tw-text-sm tw-leading-[18px]"
              disabled={!selectedFile || isSubmitting}
              type="submit"
              onClick={() => handleUploadFile()}
            >
              Отправить
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
